<div mat-dialog-title>
    Завершить заказ ?
  </div>
  <mat-dialog-content [formGroup]="form" align="center">
  

    <mat-form-field class="full-width">
        <mat-label>Остаток (л.)</mat-label>
        <input matInput id="value" formControlName="value">
      </mat-form-field>
  
</mat-dialog-content>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="onNoClick()">Нет</button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      
      (click)="onClick()"
    >
      Да
    </button>
  
  </div>
  <ng-template #loading>
    <p class="text-center">загрузка ...</p>
  </ng-template>
  