import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { NotifierService } from 'angular-notifier';
import ymaps from 'ymaps';
import { IOrder } from '../../shared/interfaces';

@Component({
  selector: 'app-change-address-dialog',
  templateUrl: './change-address-dialog.component.html',
  styleUrls: ['./change-address-dialog.component.scss']
})
export class ChangeAddressDialogComponent implements OnInit {
  private readonly notifier: NotifierService;
  form: FormGroup;
  description: string;
  addressSuggestView: any;
  order: IOrder;
  maps: ymaps;

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private ordersService: OrdersService,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<ChangeAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.notifier = notifierService;
    this.order = data.order;
    this.description = 'Изменение адреса';
  }

  ngOnInit() {
    if (this.order)
      localStorage.removeItem('adres_' + this.order.id);
    this.form = this.fb.group({
      address: [this.order.address, '']
    });
    ymaps
      .load(this.settingsService.getMapInitUrl())
      .then(maps => {
        this.maps = maps;

        const city = 'город' + this.settingsService.getCurrentCity().name;
        this.addressSuggestView = new maps.SuggestView('suggest_address', {
            provider: {
              suggest: (function(request, options) {
                return maps.suggest(city + ', ' + request);
              })
            }
          }
        );
      });
  }

  save() {
    const address = this.addressSuggestView.state._data.request;
    this.order.address = address;

    const geocoder = new this.maps.geocode(address).then(res => {
        if (res.metaData.geocoder.found > 0) {
          const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
          this.order.lat = coordinates[0];
          this.order.lon = coordinates[1];

        } else {
          const city = this.settingsService.getCurrentCity();
          if (city) {
            this.order.lat = city.default_location.longitude;
            this.order.lon = city.default_location.latitude;
          }
        }
        console.log(this.order);
        this.ordersService.changeOrderAddress(this.order).subscribe(response => {
            if (response.code < 0) {
              this.notifier.notify('error', response.message);
            } else {
              this.notifier.notify('success', response.message);

              this.dialogRef.close(this.form.value);
            }
          }, error => {
            this.notifier.notify('error', 'Ошибка (см. логи для подробностей');
          }
        );
      },
      function(err) {
        this.notifier.notify('error', 'Ошибка выполнения операции ' + err);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}



