import { Component, OnInit } from '@angular/core';

import * as moment from 'moment';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { IOrders, IAssignOrder, IChangeStatus } from 'src/app/shared/interfaces';
import { SettingsService } from 'src/app/services/settings.service';
import { OrdersService } from 'src/app/services/orders.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BoostersService } from 'src/app/services/boosters.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { AssignDriverDialogComponent } from 'src/app/dialogs/assign-driver-dialog/assign-driver-dialog.component';
import { WtDialogComponent } from 'src/app/wt-dialog/wt-dialog.component';
import { CompleteOrderDialogComponent } from 'src/app/dialogs/complete-order-dialog/complete-order-dialog.component';

@Component({
  selector: 'app-order-base',
  templateUrl: './order-base.component.html',
  styleUrls: ['./order-base.component.scss']
})
export class OrderBaseComponent implements OnInit {

  dataAll: IOrders[];
  dataSub: Subscription;

  constructor(
    protected settings: SettingsService,
    protected auth: AuthService,
    private orders: OrdersService,
    private boosters: BoostersService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.refreshAllOrders();
  }

  openAssignDialog(order: IOrders): void {

    const orderIsNight = order.night_time === 1;
    const hours = moment().hours();
    if (hours < 20 && orderIsNight) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Внимание !!! ', text: 'Заказ на ночную заправку. Вы уверены что хотите назначить водителя на заказ сейчас ?' },
        width: '500px'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          this.assignDriver(order.id);
        }
      });
    } else {
      this.assignDriver(order.id);
    }
  }

  assignDriver(id: number): void {
    const order = this.dataAll.find(o => o.id === id);
    const dialogRef = this.dialog.open(AssignDriverDialogComponent, {
      data: { id, order },
      width: '800px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // @ts-ignore
          const dialogRefT = this.dialog.open(WtDialogComponent, {
            data: { time: '00:45', date: '', manager_comment: '' },
            width: '800px'
          });
          dialogRefT.disableClose = true;
          dialogRefT.afterClosed().subscribe(resultD => {
            let d: IAssignOrder;
            d = {
              order_id: id,
              driver_id: result,
              date_delivery_plan: resultD.date,
              waiting_time: resultD.time,
              manager_comment: resultD.manager_comment
            };
            this.orders.assignDriver(d).subscribe(response => {
              this.refreshAllOrders();
            });
          });

        }
      }
    );

  }

  complete(id: number) {
    const dialogRef = this.dialog.open(CompleteOrderDialogComponent, {
      data: { title: 'Завершение заказа', text: 'Завершить заказ?', value: 0 },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const d: IChangeStatus = {
          order_id: id,
          order_status_id: 6,
          fuel_rest: result.value


        };
        this.orders.setOrderStatusWithData(d).subscribe(response => {
          this.refreshAllOrders();
        });
      }
    });
  }

  canceled(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Отмена заказа', text: 'Отменить заказ?', value: 0 },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        const d: IChangeStatus = {
          order_id: id,
          order_status_id: -2,
          fuel_rest: 0
        };
        this.orders.setOrderStatusWithData(d).subscribe(response => {
          this.refreshAllOrders();
        });

      }
    });
  }

  refreshAllOrders() {
    this.dataAll = this.orders.getOrders();
  }
}
