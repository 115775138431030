import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getAll(apiUrl: string, param?: string) {
    const params = new HttpParams({fromString: param});
    const options = { params };
    return this.http.get(`${environment.apiUrl}/api/v3/${apiUrl}/`, options)
      .pipe(
        map((response: {[key: string]: any}) => {
          return Object.keys(response)
            .map(key => ({
              ...response[key]
            }));
        }));
  }
  create(apiUrl: string, data: any):
    Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v3/${apiUrl}/`, data);
  }
  remove(apiUrl: string, id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/v3/${apiUrl}/${id}/`);
  }
  getById(apiUrl: string, id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v3/${apiUrl}`)
      .pipe(map((data: any) => {
        return {
          ...data, id
        };
      }));
  }
}
