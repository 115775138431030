import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { IAppSettings, ICity, IOrder } from '../shared/interfaces';
import { Observable } from 'rxjs';
import { CityService } from './city.service';


export enum AutoAssignmentStatus {
  Off = 0,
  Advice = 1,
  Auto = 2,

}


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private appSettings;

  constructor(private api: ApiV5Service) {
  }

  getSystemLoad() {
    return this.api.getAllAsObject('application/manager/load');
  }

  loadAppSettings() {
    return this.loadSettings().toPromise().then(data => {
      this.appSettings = data.settings;
    });
  }

  loadSettings(): Observable<any> {
    return this.api.getAllAsObject('application/settings');
  }

  getSettings(): IAppSettings {
    return this.appSettings;
  }

  saveSettings(settings: IAppSettings) {
    return this.api.save('application/settings', settings);
  }

  journalPageSize(page: string): number {
    return 10;
  }

  getCities(citiesId: number[]): ICity[] {

    if (this.getSettings() && this.getSettings().cities.length > 0) {
      return this.getSettings().cities.filter(item => citiesId.includes(item.id));

    }
  }

  getMapInitUrl(loadOptions = '') {
    const apiSettings = this.getSettings().apiSettings['api'].ymap;
    // console.log( apiSettings[0])
    const apiURL = 'https://api-maps.yandex.ru/2.1/?apikey=';
    const lang = 'ru_RU';

    let key = '';
    if (apiSettings) {
      console.log(apiSettings[0]);
      key = apiSettings[0];
    }
    if (loadOptions.length > 0) {
      loadOptions = '&load=' + loadOptions;
    } else {
      loadOptions = '';
    }
    // console.log(apiURL+key+'&lang='+lang+loadOptions)
    return apiURL + key + '&lang=' + lang + loadOptions;
  }

  getCurrentCity(): ICity {
    let city = sessionStorage.getItem('city');
    var res = { default_location: { lat: 0, lon: 0 }, name: null, is_active: null, created_at: null, updated_at: null } as unknown as ICity;
    var id = null;

    if (city != 'undefined') {
      id = JSON.parse(city);
    }
    if (id) {
      const ids = [];
      ids.push(id);
      res = this.getCities(ids)[0];

    }
    return res;
  }

  getColorByOrderStatus(order: IOrder) {
    const colors = this.appSettings.admSettings.orders.colors;

    if (order.order_status === 1) {
      return colors.find(c => c.key === 'row-payment-order').value;
    }
    if (order.order_active === 1 && order.night_time === 1) {
      return colors.find(c => c.key === 'row-night-order').value;
    }
    if (order.order_status === 2) {
      return colors.find(c => c.key === 'row-new-order').value;
    }
    if (order.order_status === 3) {
      return colors.find(c => c.key === 'row-assign-order').value;
    }
    if (order.order_status === -2) {
      return colors.find(c => c.key === 'row-canceled-order').value;
    }
    if (order.order_status === 6) {
      return colors.find(c => c.key === 'row-ok-order').value;
    }
    return '';
  }

  getTitleByOrderStatusKey(key: string) {
    switch (key) {
      case 'row-payment-order':
        return 'Ожидает оплаты';
      case 'row-night-order':
        return 'Ночной';
      case 'row-new-order':
        return 'Новый';
      case 'row-assign-order':
        return 'Назначен';
      case 'row-canceled-order':
        return 'Отменен сервисом';
      case 'row-ok-order':
        return 'Завершен';
      default:
        return '';
    }
  }
}
