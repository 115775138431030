import { IClientInfo } from '../shared/interfaces';

export class ChatUser {
  id?: number;
  name: string;
  lastMessage?: Date;
  newMessagesCnt?: number;
  info?: string;
  data?: IClientInfo;
}
