import { Injectable } from '@angular/core';
import { ApiV5Service as ApiService } from '../shared/services/apiV5.service';
import { IMessageParams, IMessages } from '../shared/interfaces';
import { Subscription, BehaviorSubject } from 'rxjs';
import { ChatUser } from '../models/chat-user.model';

const getMessagesUrl = 'manager/chat/messages';
const getConversationUrl = 'manager/chat/conversation';
const getUsersUrl = 'manager/chat/users';
const getClientHistoryUrl = 'manager/client/history';
const sendMessageUrl = 'manager/chat/message';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private apiService: ApiService) {
  }

  selectedUser: ChatUser;
  private userSub = new BehaviorSubject<ChatUser>(this.selectedUser);
  currentUser = this.userSub.asObservable();

  usersListHistoryDeep: number;
  private usersListHistoryDeepSub = new BehaviorSubject<number>(this.usersListHistoryDeep);
  currentUsersListHistoryDeep = this.usersListHistoryDeepSub.asObservable();

  data: IMessages[] = [];
  crSub: Subscription;
  dataSub: Subscription;

  markMessagesAsRead(unreaded: IMessages[]) {
    this.apiService.create(getMessagesUrl, { messages: unreaded.map(m => m.id) }).subscribe(() => {
    });
  }

  getCurrentUserFeed() {
    const user = this.userSub.value;
    const params = {
      startFrom: '01.01.2020 01:53:01',
      clientId: user == null ? -1 : user.id
    };
    return this.apiService.getAllWParams(getConversationUrl, params);
  }

  getUsers() {
    const params = {
      lastDays: this.usersListHistoryDeepSub.value
    };
    return this.apiService.getAllWParams(getUsersUrl, params);
  }

  getCurrentUserOrders() {
    const user = this.userSub.value;

    const params = {
      startFrom: '01.01.2020 01:53:01',
      clientId: user == null ? -1 : user.id
    };
    return this.apiService.getAllWParams(getClientHistoryUrl, params);
  }

  sendMessage(msg: string) {
    const user = this.userSub.value;
    const message: IMessageParams = {
      message: msg,
      clientId: user == null ? -1 : user.id
    };

    this.crSub = this.apiService.create(sendMessageUrl, message)
      .subscribe(() => {
      }, () => {
      });
  }

  getCurrentUser(): ChatUser {
    return this.userSub.value;
  }

  setSelectedUser(user: ChatUser) {
    this.userSub.next(user);
  }

  setUsersListDeep(value: number) {
    this.usersListHistoryDeepSub.next(value);
  }
}
