import { Component, OnInit } from '@angular/core';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatTableDataSource, MatDialog } from '@angular/material';

import { ClientsService } from 'src/app/services/clients.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { IBuyer } from 'src/app/shared/interfaces';
import { MessageService } from 'ui-message-angular';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';


@Component({
  selector: 'app-buyers-page',
  templateUrl: './buyers-page.component.html',
  styleUrls: ['./buyers-page.component.scss']
})
export class BuyersPageComponent extends BaseListPageComponent<IBuyer> implements OnInit {
  displayedColumns = ['id','phone_number', 'name','email','gender','birth_date','balance','allow_cash','orders_cnt','last_order_date','actions'];
 
  DetailRoute: string = 'buyerDetail';
  
  constructor(
    protected dialog: MatDialog,
     protected settings:SettingsService,
     protected auth: AuthService,
     private service:  ClientsService,
     protected router: Router,
     protected messageService: MessageService,
     
  ) {
    super(
      dialog,
      settings,      
      auth,
      router,
      messageService,
      
    );
    this.Title = "Физ. лица";
    this.allowAdd = true;
    this.clientFilter = false;
  }
  
  protected getData(filter='') {
    return this.service.getClients(filter);
  }


}
