import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {
	transform(items: any[], value: any, prop: string): any[] {
		if (!items) return [];
		if (!value) return items;
		var items =  items.filter(singleItem =>singleItem[prop]===value );
    return items;
	}
}
