<div class='container'>
  <div class='header'>
    <h1>{{Title}}</h1>
    <span class='spacer'></span>
    <a mat-mini-fab color='accent' (click)='newItem()'>
      <mat-icon aria-label='add'>add</mat-icon>
    </a>
  </div>

  <div class='table-container mat-elevation-z2'>
    <div class='table-header'>
      <mat-form-field>
        <input matInput (keyup)='applyFilter($event.target.value)' placeholder='Поиск'>
      </mat-form-field>
    </div>
    <mat-table mat-table [dataSource]='dataSource' matSort class='mat-elevation-z8'>
      <ng-container matColumnDef='id'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Код</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.id }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='is_active'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Акт.</mat-header-cell>

        <mat-cell *matCellDef='let element'>
          <mat-checkbox [checked]='element.is_active' [disabled]='true'>
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef='name'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Наименование</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.name | maxChar:35 }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='measure'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ед.изм.</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.measure  }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='is_optional'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Доп.</mat-header-cell>
        <mat-cell *matCellDef='let element'>
          <mat-checkbox [checked]='element.is_optional' [disabled]='true'>
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef='index'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Индекс</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.index  }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef='price'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Цена</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.price  }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='night_price'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ночная цена</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.night_price  }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='min_amount'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Min</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.min_amount  }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='max_amount'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Max</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.max_amount  }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='mark_color'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Цвет</mat-header-cell>
        <mat-cell *matCellDef='let element'>
            <input
              style='width: 50px'
              disabled
              [style.background]="element.value"
            />
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef='description'>
        <mat-header-cell *matHeaderCellDef mat-sort-header> Описание</mat-header-cell>
        <mat-cell *matCellDef='let element'> {{ element.description | maxChar:15 }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef='actions'>
        <mat-header-cell *matHeaderCellDef> Действия</mat-header-cell>
        <mat-cell *matCellDef='let element'>
          <a mat-icon-button title='Просмотр' (click)='displayItem(element.id)'>
            <mat-icon color='primary'>preview</mat-icon>
          </a>

          <a mat-icon-button title='Редактировать' (click)='editItem(element.id)'>
            <mat-icon color='primary'>create</mat-icon>
          </a>
          <!--<a mat-icon-button title="Удалить" (click)="deleteItem(element.id)" >
            <mat-icon color="primary">delete</mat-icon>
          </a>-->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef='displayedColumns'></mat-header-row>
      <mat-row *matRowDef='let row; columns: displayedColumns;'></mat-row>
    </mat-table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]='[3, 5, 10]'></mat-paginator>
  </div>
<app-spinner></app-spinner>