import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-wt-dialog',
  templateUrl: './wt-dialog.component.html',
  styleUrls: ['./wt-dialog.component.scss']
})
export class WtDialogComponent implements OnInit {
  // data = '00:45:00';
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<WtDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { time: string, date: string, manager_comment: string }
  ) {
  }

  ngOnInit() {
    this.data.time = '00:00';
    this.changeDate();
  }

  changeDate() {
    console.log(this.data);
    const orderDate = moment(this.data.time, 'HH:mm');
    const curDate = moment();
    this.data.date = curDate.add(orderDate.hours(), 'hours').add(orderDate.minutes(), 'minutes').format('DD.MM.YY HH:mm');
  }

  onNoClick() {
    this.dialogRef.close();
  }

}
