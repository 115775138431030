import { Component, OnInit } from '@angular/core';
import { DialogBaseComponent } from '../dialog-base/dialog-base.component';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
//import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { values } from 'lodash';
import { Message, MessageService } from "ui-message-angular";
import { DialogService } from 'src/app/services/dialog.service';
import { Observable, observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-entity-base',
  templateUrl: './dialog-entity-base.component.html',
  styleUrls: ['./dialog-entity-base.component.scss']
})
export class DialogEntityBaseComponent<T> extends DialogBaseComponent implements OnInit {
  Id: number;
  Entity: T;
  readonly = true;
  isNewMode = false;
  action: string;
  routeUrl: string;
  entityForm: FormGroup;
  dirty: boolean = false;
  originalEntityValue: T;
  get id(): String {
    if (this.entityForm)
      return this.entityForm.get('id').value;
  }
  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService

  ) {
    super(
      dialog,
      router,
      route,
    );
  }


  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.action = params.get('action');
        this.routeUrl = params.get('route');
        if (this.action === 'new') {
          this.isNewMode = true;
          return this._createNewEntity();
        } else {
          this.isNewMode = false;
          return this.getData(Number(params.get('id')));
        }

      })
    ).subscribe(data => {
      if ('id' in <T>data) {
        this._generateUserForm(<T>data);
        if (this.isNewMode || this.action === 'change') {
          this._switch2EditMode();
        } else {
          this._switch2DisplayMode();
        }
        //this._setOriginalEntity(<T>data);

      } else {
        const errorMessages = <Message[]>data[1];
        errorMessages.forEach(msg => this.messageService.add(msg));
      }
    });

  }
  public onSubmit(value) {
    this.save(value);
    return this.router.navigateByUrl(this.getSubmitRoute());

  }
  public onSave(value) {
    this.save(value);


  }

  public onCancel() {
    return this.router.navigateByUrl(this.getCancelRoute());


  }
  protected _generateUserForm(data: T): void {

    this._setOriginalEntity(this.entityForm.getRawValue());

  }
  protected getData(id: number) {
    return null;
  }
  protected saveData(Entity: T) {
    return null;
  }

  getEntity(id: number) {
    let service = this.getData(id);
    if (service) {
      service.subscribe((d: T) => {
        this.Entity = d;
      }
      );
    }

  }
  protected _dataFromResponse(data) {
    return data;
  }
  protected recreateForm(data: T) {

  }
  protected _entitySaveOk(entityData) {
    return entityData;
  }
  save(newValue: T) {
    this.messageService.clearMessages();
    if (this._composeChanges()) {
      this.saveData(this.Entity).subscribe(data => {
        this.Entity = <T>{};
        let newData = this._dataFromResponse(data);
        if (newData) {
          const id = newData.id;
          this.isNewMode = false;
          this.getData(id).subscribe(entityData => {
            if ('id' in entityData) {
              
              this._switch2DisplayMode();
              this._setOriginalEntity(entityData);
              this.recreateForm(entityData);
            } else {
              const errorMessages = <Message[]>entityData;
              errorMessages.forEach(msg => this.messageService.add(msg));
            }
          });
          this.messageService.reportMessage('Сохранение', 'Успешно сохранен', 'S');
        } else {
          const errorMessages = <Message[]>data;
          errorMessages.forEach(msg => this.messageService.add(msg));
        }

      });
    }

  };
  protected _composeChanges(): boolean {
    if (this.entityForm.invalid) {
      this.messageService.reportMessage('USER', 'INVALID', 'E');
      return false;
    }
    /*
        if (this.entityForm.dirty === false) {
          this.messageService.reportMessage('GENERAL', 'NO_CHANGE', 'W');
          return false;
        }*/
    return true;
  }
  switchEditDisplay(): void {
    if (this.readonly) {
      this._switch2EditMode();
    } else {
      //if (this.entityForm.dirty) {
      if (this.dirty) {
        this.dialogService.confirm('Отменить изменения?').subscribe(confirm => {
          if (confirm) {
            //this.entityForm.reset(this.originalEntityValue);
            this._switch2DisplayMode();
          }
        });
      } else {
        this._switch2DisplayMode();
      }
    }
    this.messageService.clearMessages();
  }

  _switch2DisplayMode(): void {
    this.readonly = true;
    this._setCheckBoxState();
    const idCtrl = this.entityForm.get('id') as FormControl;
    this.entityForm.markAsPristine();
    window.history.replaceState({}, '', `/${this.routeUrl}/${idCtrl.value};action=display`);
  }

  _switch2EditMode(): void {
    this.readonly = false;
    this._setCheckBoxState();
    const idCtrl = this.entityForm.get('id') as FormControl;

    if (this.action === 'display') { this.action = 'change'; }
    window.history.replaceState({}, '', `/${this.routeUrl}/${idCtrl.value};action=` + this.action);

  }
  protected _setCheckBoxState() {
  }
  protected newEntity() { return { "id": null } as Object; }
  protected _createNewEntity() {
    var item = this.newEntity();
    return new Observable((observer => {
      //setInterval(() => observer.next(item),1000);
      observer.next(item);
      observer.complete();
      //() => observer.next(item);
    }));
  }
  protected _setOriginalEntity(data: T) {
    this.originalEntityValue = data;
    this._resetEntity();
  }
  protected _resetEntity() {

    this.Entity = this.originalEntityValue;
    this.entityForm.reset(this.originalEntityValue);
    this.dirty = false;

  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.isNewMode || (this.entityForm && this.entityForm.dirty)) {
      return this.dialogService.confirm('Отменить изменения?');
    } else {
      return true;
    }
  }
  protected getSubmitRoute() {
    return super.getSubmitRoute();
  }
  protected getCancelRoute() {
    return super.getCancelRoute();
  }
}
