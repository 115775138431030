import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotifierService} from 'angular-notifier';

@Injectable()

export class AuthIntercepter implements HttpInterceptor {
  private readonly notifier: NotifierService;
  constructor(private auth: AuthService,
              private router: Router,
              private notifierService: NotifierService
  ) {
    this.notifier = notifierService;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('AUTH - ', this.auth.isAuthenticated());
    // req = req.clone({
    //   headers: req.headers.set('Authorization', '44gbrtbrb56654')
    // });
    if (this.auth.isAuthenticated()) {

      // const headers = req.headers.append( 'Authorization', sessionStorage.getItem('token'));
      // req = req.clone({ headers });

      // req = req.clone({
      //   headers: new HttpHeaders({
      //     Authorization: sessionStorage.getItem('token')
      //   })
      // });
      // req = req.clone({
      //   headers: req.headers.set('Authorization', sessionStorage.getItem('token'))
      // });
      // headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
      // headers.append('Access-Control-Allow-Credentials', 'true');
      req = req.clone({
        setHeaders: {
          'Content-type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
          // Cookie: this.cookieService.get('user')
        },
        withCredentials: true,
      });
    }
    // console.log(req);
    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
            console.log('[Interceptor error]: ', error);
            console.log('[Interceptor error]: ', req);
            let errorText = '';
            if (error.error.detail) {
              errorText = error.error.detail;
            } else if (error.error.message) {
                errorText = error.error.message;
              } else if (error.error.non_field_errors) {
              errorText = error.error.non_field_errors[0];
            } else {
              errorText = JSON.stringify(error);
            }
            console.log(errorText);
            this.notifier.notify('error', errorText);
            if (error.status === 401) {
              this.auth.logout();
              this.router.navigate(['/login'], {
                queryParams: {
                  authFaild: true
                }
              });
            }
            return throwError(error);
          }
        )
      );
  }

}
