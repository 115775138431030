import { Component, OnInit } from '@angular/core';
import { IReport } from 'src/app/shared/interfaces';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';
import { ReportsService ,ReportType} from 'src/app/services/report.service';

@Component({
  selector: 'app-report-dialog',
  templateUrl: './report-dialog.component.html',
  styleUrls: ['./report-dialog.component.scss']
})
export class ReportDialogComponent extends DialogEntityBaseComponent<IReport> implements OnInit {

  

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: ReportsService

  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }
  public getSubmitRoute(): string {
    return '/reports';
  }
  public getCancelRoute(): string {
    return '/reports';
  }
  protected getData(id: number) {
    return this.service.getReport(id)
  }

  protected saveData(Entity: IReport) {

    return this.service.saveReport(Entity)
  }
  protected _dataFromResponse(data) {
    return data;
  }
  protected newEntity() {
    var item = <IReport>super.newEntity();
    item.name = '';
    item.category = '';
    item.type = ReportType.Query;
    item.is_active = true;
    item.version = '';
    item.author = null;
    item.settings = '';
    
    return item;
  }
  protected _generateUserForm(data: IReport): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      is_active: [data.is_active],
      category: [data.category],
      type: [data.type],
      version: [data.version],
      author: [data.author],
      settings: [data.settings],
      
    });
    super._generateUserForm(data);
    
  }
  protected _composeChanges(){
    
     let result = super._composeChanges();
     if (result){
       this.Entity = this.entityForm.value;
     }
     return result;
  }
  protected _setCheckBoxState() {

    if (this.readonly) {
      
      this.entityForm.get('name').disable();
      this.entityForm.get('is_active').disable();
      this.entityForm.get('category').disable();
      this.entityForm.get('type').disable();
      this.entityForm.get('version').disable();
      this.entityForm.get('author').disable();
      this.entityForm.get('settings').disable();
     
    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('is_active').enable();
      this.entityForm.get('category').enable();
      this.entityForm.get('type').enable();
      this.entityForm.get('version').enable();
      this.entityForm.get('author').enable();
      this.entityForm.get('settings').enable();
     
    }

  }

}
