import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';

import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';
import { IModel } from 'src/app/shared/interfaces';
import { ModelsService } from '../../services/models.service';

@Component({
  selector: 'app-model-dialog',
  templateUrl: './model-dialog.component.html',
  styleUrls: ['./model-dialog.component.scss']
})
export class ModelDialogComponent extends DialogEntityBaseComponent<IModel> implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: ModelsService

  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }
  public getSubmitRoute(): string {
    return '/models';
  }
  public getCancelRoute(): string {
    return '/models';
  }
  protected getData(id: number) {
    return this.service.getModel(id);
  }

  protected saveData(Entity: IModel) {
    return this.service.saveModel(Entity);
  }

  protected newEntity() {
    const item = super.newEntity() as IModel;

    item.vendor = '';
    item.model = '';
    item.is_active = false;

    return item;
  }
  protected _generateUserForm(data: IModel): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      vendor: [data.vendor, [Validators.required]],
      model: [data.model, [Validators.required]],
      is_active: [data.is_active],
    });
    super._generateUserForm(data);

  }
  protected _composeChanges() {

     const result = super._composeChanges();
     if (result) {
       this.Entity = this.entityForm.value;
     }
     return result;
  }
  protected _setCheckBoxState() {

    if (this.readonly) {

      this.entityForm.get('vendor').disable();
      this.entityForm.get('model').disable();
      this.entityForm.get('is_active').disable();

    } else {
      this.entityForm.get('vendor').enable();
      this.entityForm.get('model').enable();
      this.entityForm.get('is_active').enable();
    }

  }

}
