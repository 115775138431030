import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment';

import { ChatUserListComponent as ChatUsersList } from '../../chat/chat-user-list/chat-user-list.component';
import { IMessages } from 'src/app/shared/interfaces';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-chat-feed',
  templateUrl: './chat-feed.component.html',
  styleUrls: ['./chat-feed.component.scss']
})
export class ChatFeedComponent implements OnInit, OnChanges, OnDestroy {
  feed: IMessages[]
  dataSub: Subscription;
  timeSub;
  
  constructor(private chat: ChatService, public auth: AuthService) { }

  ngOnInit() {

    this.dataRefresh();

    this.timeSub = setInterval(() => {
      this.dataRefresh();

    }, 3 * 1000);

    this.chat.currentUser.subscribe(client => {
      if (client)
        this.dataRefresh();
    })

  }
  dataRefresh() {
    //var res =  this.chat.getCurrentUserFeed();
    var res = this.chat.getCurrentUserFeed();
    if (res != null) res.subscribe(data => {
      this.feed = data;
      this.feed = this.feed.sort((val1, val2) => { return (moment(val1.created, 'DD.MM.YYYY HH:mm:ss').diff(moment(val2.created, 'DD.MM.YYYY HH:mm:ss'))) });
      
      //.filter(dataK => dataK. ===1);
      var unreaded = this.feed.filter(i=>(i.readStatus & 2) == 0);
      if (!this.auth.isRole(['SuperAdmin','Operator']))
      {
        unreaded.forEach(element => {
          element.readStatus = element.readStatus | 2;
        });
        this.chat.markMessagesAsRead(unreaded);
      }
    });


  }
  ngOnChanges() {
    //this.feed = this.chat.getMessages()
    this.dataRefresh();

  }



  ngOnDestroy(): void {

    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    clearInterval(this.timeSub);
  }

}
