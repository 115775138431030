<div class='container'>
  <div class='header'>
    <h1>{{Title}}</h1>
    <span class='spacer'></span>
    <a *ngIf='settings.settings ? settings.settings.export : true' mat-mini-fab color='accent'
       (click)='save_as_excel()'>
      <mat-icon aria-label='save'>save_alt</mat-icon>
    </a>
  </div>

  <mat-form-field appearance='fill'>
    <mat-label>Отчеты</mat-label>
    <mat-select (selectionChange)='selectedValue($event)' [(ngModel)]='selectedReport' [disabled]='reports.length < 2'>
      <mat-option *ngFor='let report of reports' [value]='report'>
        {{report.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf='selectedReport.type === 1' class='table-container mat-elevation-z2'>
    <!--    <div class='table-header'>-->
    <!--    </div>-->
    <!--    Параметры для Lazy Load (в p-table): -->
    <!--    [lazy]='true'-->
    <!--    (onLazyLoad)='loadDataLazy($event)'-->
    <!--    [totalRecords]='totalDataLength'-->
    <!--    [(first)]="page"-->
    <!--    [customSort]="true"-->
    <p-table
      #dt
      [columns]='selectedColumns'
      [value]='dataSource'
      [reorderableColumns]='true'
      [resizableColumns]='true'
      sortMode='multiple'
      [showCurrentPageReport]='true'
      [rowsPerPageOptions]='[10,25,50]'
      [rows]='10'
      currentPageReportTemplate='Showing {first} to {last} of {totalRecords} entries'
      [filterDelay]='0'
      [globalFilterFields]="['name']"
      [paginator]='settings.settings ? settings.settings.pagination : true'
    >
      <ng-template pTemplate='caption'>
        <div style='display: flex; flex-wrap: wrap;'>
          <div style='text-align: left;'>
            <p-multiSelect [options]='columns' [(ngModel)]='selectedColumns' optionLabel='header'
                           selectedItemsLabel='{0} колонок выбрано' [style]="{minWidth: '200px', margin: '5px'}"
                           defaultLabel='Выберите колонку' [ngModelOptions]='{standalone: true}'
                           (ngModelChange)='selectedColumnsChange($event)'>
            </p-multiSelect>
          </div>
          <div *ngIf='this.settings.params.includes("period")' style='margin-left: 5px'>
            <div style='margin: -5px 0'>
              <input [matDatepicker]='startPeriod' [(ngModel)]='startDate'>
              <mat-datepicker-toggle [for]='startPeriod'>
              </mat-datepicker-toggle>
              <mat-datepicker #startPeriod></mat-datepicker>
            </div>
            <div style='margin: -15px 0'>
              <input [matDatepicker]='stopPeriod' [(ngModel)]='stopDate'>
              <mat-datepicker-toggle [for]='stopPeriod'>
              </mat-datepicker-toggle>
              <mat-datepicker #stopPeriod></mat-datepicker>
            </div>
          </div>
          <p-multiSelect *ngIf='this.settings.params.includes("boosters")' [options]='boosters'
                         [(ngModel)]='selectedBoosters' optionLabel='name'
                         selectedItemsLabel='{0} бустеров выбрано' [style]="{minWidth: '200px', margin: '5px'}"
                         defaultLabel='Выберите бустер' [ngModelOptions]='{standalone: true}'>
          </p-multiSelect>
          <p-multiSelect *ngIf='this.settings.params.includes("products")' [options]='products'
                         [(ngModel)]='selectedProducts' optionLabel='name'
                         selectedItemsLabel='{0} продуктов выбрано' [style]="{minWidth: '200px', margin: '5px'}"
                         defaultLabel='Выберите продукт' [ngModelOptions]='{standalone: true}'>
          </p-multiSelect>
          <p-multiSelect *ngIf='this.settings.params.includes("drivers")' [options]='drivers'
                         [(ngModel)]='selectedDrivers' optionLabel='name'
                         selectedItemsLabel='{0} водителей выбрано' [style]="{minWidth: '200px', margin: '5px'}"
                         defaultLabel='Выберите водителя' [ngModelOptions]='{standalone: true}'>
          </p-multiSelect>
          <button mat-flat-button class='refreshDataButton' matTooltip='Обновить' (click)='handleRefreshData()'>
            <i class='material-icons'>
              refresh
            </i>
          </button>
        </div>
      </ng-template>
      <ng-template pTemplate='header'>
        <tr>
          <th *ngFor='let col of selectedColumns' pReordableColumn pResizableColumn
              [pSortableColumn]='(col.sort === undefined ? true : col.sort) && col.field'
              [ngStyle]="{'width': col.width}" class='report-universal-cell'>
            {{col.header}}
            <p-sortIcon *ngIf='col.sort === undefined ? true : col.sort' [field]='col.field'></p-sortIcon>
          </th>
        </tr>
        <tr *ngIf='selectedColumnsSearchable(selectedColumns)'>
          <th *ngFor='let col of selectedColumns' [ngSwitch]='col.field'>
            <mat-checkbox *ngIf='(col.search === undefined ? true : col.search) && col.type === "boolean"'
                          class='example-margin'
                          (change)='dt.filter((+$event.checked).toString(), col.field, col.filterMatchMode)'
            ></mat-checkbox>
            <input *ngIf='(col.search === undefined ? true : col.search) && !col.type' pInputText type='text'
                   style='width: 100%;'
                   (input)='dt.filter($event.target.value, col.field, col.filterMatchMode)'>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate='body' let-rowData let-columns='columns' let-index='rowIndex'>
        <tr>
          <td *ngFor='let col of columns' class='ui-resizable-column report-universal-cell'
              [ngStyle]="{'width': col.width}">
            <mat-checkbox *ngIf='col.type === "boolean"' class='example-margin'
                          [checked]='!!rowData[col.field]'></mat-checkbox>
            <span *ngIf='!col.type'>{{rowData[col.field]}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <iframe *ngIf='selectedReport.type === 2' style='width: 100%; height: 500px;'
          [src]='this.sanitizationService.bypassSecurityTrustResourceUrl(this.settings.url)'
          frameborder='0' allowFullScreen='true'></iframe>
</div>
<app-spinner></app-spinner>
