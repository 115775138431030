<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon" color="warn">close</mat-icon>
</button>
<div mat-dialog-title>
    Информация о заказе № {{data.id}}
</div>
<div mat-dialog-content>
    <div class = "orderInfoTopPanel">
        <div class="orderInfo">
            <div class="info">
                <b>Водитель:</b> {{data.client_name}} <b>телефон</b> {{data.phone_number}}
                <br><b>Автомобиль:</b> {{data.car_model}} <b>гос номер:</b> {{data.car_no}}
                <br><b>Вид ГСМ:</b> {{data.fuel_name}} - {{data.liters}} л.
                <br><b>Дата доставки</b> {{data.date_delivery}}
            </div>
            <div class="comment">
                <b>Комментарий:</b>
                {{data.comment}}
            </div>
        </div>
        <div class ='orderActions'>
            <button *ngIf="auth.isVisible('order','assign')" class="order-action main-btn" mat-flat-button [disabled]="data.order_active === 0"
            (click)="openAssignDialog(data)">
            <i *ngIf="data.order_status===2" class="material-icons">
              time_to_leave
            </i>
            <span *ngIf="data.order_status===2; else reassign">Назначить</span>
          </button>
          <button *ngIf="auth.isVisible('order','complete')"class="order-action" mat-flat-button [disabled]="data.order_active ===0"
            (click)="complete(data.id)">
            <i class="material-icons">
              beenhere
            </i>
            Завершить
          </button>
          <button *ngIf="auth.isVisible('order','cancel')" class="order-action" mat-flat-button
            [disabled]="data.order_active === 0 && data.order_status!=1" (click)="canceled(data.id)">
            <i class="material-icons">
              format_color_reset
            </i>
            Отменить
          </button>

        </div>
    </div>
    <div class="mainContent ">
        <div class="feedContainer">
            <div #scroller class="feedWrapper fixedH" (scroll)="scrolled($event)">
                <app-chat-feed></app-chat-feed>
            </div>

        </div>
    </div>
    <div class="chatFormWrapper">
        <app-chat-form></app-chat-form>
    </div>
</div>

<ng-template #loading>
    <p class="text-center">загрузка ...</p>
</ng-template>
<ng-template #reassign>
    <span>Переназначить</span>
  </ng-template>