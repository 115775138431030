import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';

//API 
const reestrUpdate='service/bank/reestr/update';

@Injectable({
  providedIn: 'root'
})
export class BankingService {

  constructor(private apiService:ApiV5Service) { }
  
  public refreshReestr()
  {
    return this.apiService.getAllAsObject(reestrUpdate);

  }

}
