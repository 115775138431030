<h2 mat-dialog-title>{{description}}</h2>
<p>Текущий адрес: {{order.address}}</p>
<mat-dialog-content [formGroup]='form'>
  <div class='container'>
    <input class='form-control' placeholder='Адрес' ng-model='addressSuggestView'
           id='suggest_address' yandex-suggest-view />
  </div>
</mat-dialog-content>

<mat-dialog-actions class='bottom-panel'>
  <div class='actions-panel'>
    <button class='mat-raised-button custom-button' (click)='close()'>Закрыть</button>
    <button class='mat-raised-button mat-primary custom-button' (click)='save()'>Сохранить</button>
  </div>
</mat-dialog-actions>
