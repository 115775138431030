<div *ngIf="entityForm" [formGroup]="entityForm" (ngSubmit)="onSave(null)" autocomplete="off">

    <div class="container">
        <mat-card>
            <div class="top-bar">
                <mat-card-title>
                    <div class="top-bar-title">
                        {{isNewMode? 'Новый контрагент' : readonly? 'Карточка контрагента ' : 'Редактирование контрагента '}}

                        <button class="btn btn-lg btn-outline-primary mr-2" type="button" title="Edit/Display"
                            id="editDisplay" (click)="switchEditDisplay()">
                            <span *ngIf="readonly" class="fas fa-edit"> Редактирование</span>
                            <span *ngIf="!readonly" class="fas fa-glasses"> Просмотр</span>
                        </button>
                    </div>
                </mat-card-title>
            </div>

            <div class="form-content" fxLayout="column">
                <div  fxLayoutWrap fxLayoutGap="0.5%" fxFlex="75%">

                    <mat-form-field fxFlex="100%">
                        <mat-label>Наименование</mat-label>
                        <input matInput id="name" formControlName="name" placeholder="Наименование">
                    </mat-form-field>

                </div>
                <div class="additional-panel" fxLayout="column" fxLayoutGap="10px">
                    <div>
                        <mat-form-field fxFlex="50%">
                            <mat-label>Телефон</mat-label>
                            <input matInput id="phone_number" formControlName="phone_number" placeholder="Телефон">
                        </mat-form-field>
                        <mat-form-field fxFlex="50%">
                            <mat-label>Email</mat-label>
                            <input matInput id="email" formControlName="email" placeholder="Email">
                        </mat-form-field>

                    </div>
                </div>
            </div>


           
            <mat-tab-group>
                <mat-tab label="Прайс-лист">
                    <app-client-price-list [client]="Entity"></app-client-price-list>
                </mat-tab>
                <mat-tab label="Транспорт">
                    <ng-template mat-tab-label>
                        <mat-icon class="example-tab-icon">directions_car</mat-icon>
                        Транспорт
                    </ng-template>
                    <app-client-vehicle-list></app-client-vehicle-list>
                </mat-tab>
            </mat-tab-group>


            <div class="row-buttons">


                <span *ngIf="!isNewMode" class="id-field">Код: {{id}}</span>


                <span class="spacer"></span>
                <a [routerLink]="getSubmitRoute()" class="form-btn" mat-button>Назад</a>
                <button type="submit" class="form-btn" mat-raised-button color="accent" (click)="onSave(null)"
                    [disabled]="readonly">Сохранить</button>
            </div>

        </mat-card>
    </div>
</div>


























