import { Component, OnInit } from '@angular/core';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { IBooster } from 'src/app/shared/interfaces';
import { MessageService } from 'ui-message-angular';
import { BoostersService } from 'src/app/services/boosters.service';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';


@Component({
  selector: 'app-boosters-page',
  templateUrl: './boosters-page.component.html',
  styleUrls: ['./boosters-page.component.scss']
})
export class BoostersPageComponent extends BaseListPageComponent<IBooster> implements OnInit {
  displayedColumns = ['id', 'is_active', 'name', 'actions'];

  DetailRoute: string = 'boosterDetail';

  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private service: BoostersService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
    
    );
    this.Title = 'Машины';
  }

  protected getData() {
    return this.service.getAllBoosters();
  }
}
