import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { SettingsService } from './settings.service';
import { ILocation, IClientInfo, IApiListResponse, IFirm, IBuyer } from '../shared/interfaces';
import { Observable } from 'rxjs';
export enum ClientType {
  Physic = 0,
  Firm = 1,
}
//API 
const requestFirmsApiUrl = 'firms';
const requestClientCarsApiUrl = 'manager/client/cars';
const requestClientProductsApiUrl = 'manager/client/products';
const requestFirmApiUrl :string = 'firm/{id}/info';
const requestFirmCreateApiUrl :string = 'firm';
//change entity for Firms to universal for all client type
const requestClientCreateApiUrl :string = 'client';
const requestClientApiUrl :string = 'client/{id}/info';
const requestClientsApiUrl :string = 'clients';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  defaultLocation: ILocation;
  constructor(private apiService: ApiV5Service, private settings: SettingsService) {
    this.defaultLocation = this.settings.getCurrentCity().default_location;
  }
  getClients (filter: string):Observable<IApiListResponse<IBuyer>> {
  
    return this.apiService.getAllAsObject(requestClientsApiUrl, 'filter='+filter);
  }

  getFirms(): Observable<IApiListResponse<any>> {
    return this.apiService.getAllAsObject(requestFirmsApiUrl);
  }

  getFirm(id: number): Observable<IFirm> {
    return this.apiService.getEntity( requestFirmApiUrl.replace("{id}",String( id)));
  }
  saveFirm(entity: IFirm): Observable<IFirm> {
    
    return this.apiService.create(requestFirmCreateApiUrl, entity)
  }
  getClient(id: number): Observable<IBuyer> {
    return this.apiService.getEntity( requestFirmApiUrl.replace("{id}",String( id)));
  }
  saveClient(entity: IBuyer): Observable<IBuyer> {
    
    return this.apiService.create(requestClientCreateApiUrl, entity)
  }

  getCars(client_id:number): Observable<IApiListResponse<any>> {
    let params = {
      id: client_id
    }
    return this.apiService.getAllWParamsAsObject(requestClientCarsApiUrl, params);
  }
  getProducts(client_id:number): Observable<IApiListResponse<any>> {
    let params = {
      id: client_id
    }
    console.log("get prods", params);
    return this.apiService.getAllWParamsAsObject(requestClientProductsApiUrl, params);
  }
}
