import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppSettings } from '../../shared/interfaces';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { AutoAssignmentStatus, SettingsService } from 'src/app/services/settings.service';

@Component({

  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss']
})
export class SettingPageComponent implements OnInit, OnDestroy {

  public data: IAppSettings;
  public dataSub: Subscription;
  autoAssignData = AutoAssignmentStatus;
  form: FormGroup;
  resultSD: boolean;
  orderColors: any[];

  private readonly notifier: NotifierService;

  constructor(public service: SettingsService,
              private notifierService: NotifierService,
              private fb: FormBuilder) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    // this.dataSub = this.service.loadSettings().subscribe(data => {
    // console.log(data);
    // this.data = data as IAppSettings;
    this.data = this.service.getSettings();
    this.orderColors = this.data.admSettings.orders.colors;

    this.form = this.fb.group({
      contacts: this.fb.group({
        service_email: new FormControl(this.data.serviceEmail, Validators.nullValidator),
        service_url: new FormControl(this.data.serviceUrl, Validators.nullValidator),
        service_phone_number: new FormControl(this.data.servicePhoneNumber, Validators.nullValidator),
        service_telegram: new FormControl(this.data.serviceTelegram, Validators.nullValidator),
        service_whatsapp: new FormControl(this.data.serviceWhatsApp, Validators.nullValidator),
        service_vk: new FormControl(this.data.serviceVK, Validators.nullValidator)
      }),
      service: this.fb.group({
        service_stopped_to_date: new FormControl(this.data.serviceStoppedToDate, Validators.nullValidator),
        service_stopped_message: new FormControl(this.data.serviceStoppedMessage, Validators.nullValidator),
        start_blocking_orders: new FormControl(this.data.startBlockingOrders, Validators.nullValidator),
        end_blocking_orders: new FormControl(this.data.endBlockingOrders, Validators.nullValidator),
        allow_night_orders: new FormControl(this.data.allowNightOrders, Validators.nullValidator),
        is_work_day: new FormControl(this.data.isWorkDay, Validators.nullValidator),
        orders_handling_start_time: new FormControl(this.data.ordersHandlingStartTime, Validators.nullValidator),
        orders_handling_end_time: new FormControl(this.data.ordersHandlingEndTime, Validators.nullValidator),
        order_will_be_handled_tomorrow: new FormControl(this.data.orderWillBeHandledTomorrow, Validators.nullValidator),
        night_time_tankup_message: new FormControl(this.data.nightTimeTankupMessage, Validators.nullValidator),
        payment_systems: new FormControl(this.data.paymentSystems, Validators.nullValidator)
      }),
      auto_assignment: this.fb.group({
        status: new FormControl(this.data.autoAssignment.status, Validators.nullValidator),
        baseRadius: new FormControl(this.data.autoAssignment.baseRadius, Validators.nullValidator),
        radiusStep: new FormControl(this.data.autoAssignment.radiusStep, Validators.nullValidator),
        boosterOrdersCountPrefer: new FormControl(this.data.autoAssignment.boosterOrdersCountPrefer, Validators.nullValidator),
        maxRadius: new FormControl(this.data.autoAssignment.maxRadius, Validators.nullValidator),
        boosterOrdersCountSkip: new FormControl(this.data.autoAssignment.boosterOrdersCountSkip, Validators.nullValidator),
        stopRadius: new FormControl(this.data.autoAssignment.stopRadius, Validators.nullValidator)
      }),
      sys_load: this.fb.group({
        weights: this.fb.group({
          boosters: new FormControl(this.data.sysLoad.weights.boosters, Validators.nullValidator),
          delay: new FormControl(this.data.sysLoad.weights.delay, Validators.nullValidator),
          orders: new FormControl(this.data.sysLoad.weights.orders, Validators.nullValidator)
        }),
        defaults: this.fb.group({
          defaultDelay: new FormControl(this.data.sysLoad.defaults.defaultDelay, Validators.nullValidator),
          ordersLimitPerBooster: new FormControl(this.data.sysLoad.defaults.ordersLimitPerBooster, Validators.nullValidator),
          avgOrdersPerHour: new FormControl(this.data.sysLoad.defaults.avgOrdersPerHour, Validators.nullValidator),
          radiusAroundCoord: new FormControl(this.data.sysLoad.defaults.radiusAroundCoord, Validators.nullValidator)

        }),
        descriptions: new FormControl(this.data.sysLoad.descriptions, Validators.nullValidator)
      })
      // admSettings: this.fb.group(({
      //   ver: new FormControl(this.data.admSettings.ver, Validators.nullValidator),
      //   components: []
      // }))
    });
  }

  ngOnDestroy(): void {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  submit() {
    if (this.form.valid) {
      let resultIsWorkDay = 0;
      if (this.form.value.service.is_work_day === false) {
        resultIsWorkDay = 0;
      } else {
        resultIsWorkDay = 1;
      }
      const d: IAppSettings = {
        isWorkDay: resultIsWorkDay,
        ordersHandlingStartTime: this.form.value.service.orders_handling_start_time,
        ordersHandlingEndTime: this.form.value.service.orders_handling_end_time,
        orderWillBeHandledTomorrow: this.form.value.service.order_will_be_handled_tomorrow,
        nightTimeTankupMessage: this.form.value.service.night_time_tankup_message,
        paymentSystems: this.form.value.service.payment_systems,
        serviceUrl: this.form.value.contacts.service_url,
        serviceEmail: this.form.value.contacts.service_email,
        servicePhoneNumber: this.form.value.contacts.service_phone_number,
        serviceTelegram: this.form.value.contacts.service_telegram,
        serviceWhatsApp: this.form.value.contacts.service_whatsapp,
        serviceVK: this.form.value.contacts.service_vk,
        serviceStoppedToDate: this.form.value.service.service_stopped_to_date,
        serviceStoppedMessage: this.form.value.service.service_stopped_message,
        startBlockingOrders: this.form.value.service.start_blocking_orders,
        endBlockingOrders: this.form.value.service.end_blocking_orders,
        allowNightOrders: this.form.value.service.allow_night_orders,
        defaultLocation: this.service.getCurrentCity().default_location,
        autoAssignment: this.form.value.auto_assignment,
        sysLoad: this.form.value.sys_load,
        admSettings: {
          orders: {
            colors: this.orderColors
          }
        }
      };
      console.log(d);
      this.service.saveSettings(d).subscribe(response => {
          this.notifier.notify('success', 'Настройки обновлены');
          // console.log(response);
        }, error => {
          this.notifier.notify('error', 'Ошибка записи настроек');
          // console.log(error);
        }
      );
    }

  }
}
