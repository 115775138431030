<div class="container">
  <div class="header">
    <h1>{{Title}}</h1>
    <span class="spacer"></span>
    <a mat-mini-fab color="accent" (click)="newItem()">
      <mat-icon aria-label="add">add</mat-icon>
    </a>
  </div>

  <div class="table-container mat-elevation-z2">
    <div class="table-header">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
      </mat-form-field>
    </div>
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Код </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Рег. номер </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="is_active">
        <mat-header-cell *matHeaderCellDef> Акт. </mat-header-cell>

        <mat-cell *matCellDef="let element">
          <mat-checkbox [checked]="element.is_active" [disabled]="true">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a mat-icon-button title="Просмотр" (click)="displayItem(element.id)">
            <mat-icon color="primary">preview</mat-icon>
          </a>

          <a mat-icon-button title="Редактировать" (click)="editItem(element.id)">
            <mat-icon color="primary">create</mat-icon>
          </a>
          <!--<a mat-icon-button title="Удалить" (click)="deleteItem(element.id)">
            <mat-icon color="primary">delete</mat-icon>
          </a>-->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[3, 5, 10]"></mat-paginator>
  </div>
</div>


<div class="modal fade" [class.show]="showDeletionConfirmation" [ngStyle]="{'display': displayDeletionConfirmation}"
  id="deletionConfirmation" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmDeletion">Удаление записи !</h5>
        <button type="button" class="close" (click)="cancelDeletion()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Удалить запись '{{toBeDeleted}}'?</p>
      </div>
      <div class="modal-footer">
        <button type="button" id="cancel" class="btn btn-secondary" (click)="cancelDeletion()">Нет</button>
        <button type="button" id="confirm" class="btn btn-primary" (click)="confirmDeletion()">Да</button>
      </div>
    </div>
  </div>
</div>