import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';
import { IProduct } from 'src/app/shared/interfaces';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent extends DialogEntityBaseComponent<IProduct> implements OnInit {
  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: ProductService
  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }


  public getSubmitRoute() {
    return '/products';
  }

  public getCancelRoute(): string {
    return '/products';
  }

  protected getData(id: number) {
    return this.service.getProduct(id);
  }

  protected saveData(Entity: IProduct) {
    return this.service.saveProduct(Entity);
  }

  protected _dataFromResponse(data) {
    return data.product;
  }

  protected newEntity() {
    const item = super.newEntity() as IProduct;
    item.is_dirty = true;
    item.name = '';
    item.measure = '';
    item.price = 0.0;
    item.night_price = 0.0;
    item.min_amount = 0.0;
    item.max_amount = 0.0;
    item.mark_color = '';
    item.is_optional = false;

    return item;
  }

  protected _generateUserForm(data: IProduct): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      measure: [data.measure, [Validators.required]],
      description: [data.description],
      is_optional: [data.is_optional],
      is_active: [data.is_active],
      code_1c: [data.code_1c],
      index: [data.index],
      min_amount: [data.min_amount],
      max_amount: [data.max_amount],
      mark_color: [data.mark_color],

      //prices:this.fb.group({
      price: [data.price],
      night_price: [data.night_price]
      //})
    });
    //this.entityForm.get('id').disable();
    super._generateUserForm(data);

  }

  protected _composeChanges() {

    let result = super._composeChanges();
    if (result) {
      this.Entity = this.entityForm.value;
    }
    return result;
  }

  protected _setCheckBoxState() {

    if (this.readonly) {

      this.entityForm.get('name').disable();
      this.entityForm.get('measure').disable();
      this.entityForm.get('description').disable();
      this.entityForm.get('is_optional').disable();
      this.entityForm.get('is_active').disable();
      this.entityForm.get('code_1c').disable();
      this.entityForm.get('index').disable();
      this.entityForm.get('min_amount').disable();
      this.entityForm.get('max_amount').disable();
      this.entityForm.get('mark_color').disable();
      //const prices = this.entityForm.get('prices') as FormGroup;
      this.entityForm.get('price').disable();
      this.entityForm.get('night_price').disable();

    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('measure').enable();
      this.entityForm.get('description').enable();
      this.entityForm.get('is_optional').enable();
      this.entityForm.get('is_active').enable();
      this.entityForm.get('code_1c').enable();
      this.entityForm.get('index').enable();
      this.entityForm.get('min_amount').enable();
      this.entityForm.get('max_amount').enable();
      this.entityForm.get('mark_color').enable();

      //const prices = this.entityForm.get('prices') as FormGroup;
      this.entityForm.get('price').enable();
      this.entityForm.get('night_price').enable();
    }

  }

}
