import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { IReport, IApiListResponse, IApiReportExecuteResponse, IRange, IObjectAny } from '../shared/interfaces';
import { Observable } from 'rxjs';
import * as moment from 'moment';

export enum ReportType {
  Procedure = 0,
  Query = 1,
  PBI = 2,
}

// API
const requestListApiUrl: string = 'reports';
const requestItemApiUrl: string = 'report/{id}/info';
const requestItemCreateApiUrl: string = 'report';
const requestSetItemApiUrl: string = 'report';
const requestExecuteReportApiUrl: string = 'report/execute';
const requestManagerReportsApiUrl: string = 'manager/reports';


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private apiService: ApiV5Service) {

  }

  getReports(): Observable<IApiListResponse<IReport>> {
    return this.apiService.getAllAsObject(requestListApiUrl);
  }

  getReport(id: number): Observable<IReport> {
    return this.apiService.getEntity(requestItemApiUrl.replace('{id}', String(id)));
  }

  saveReport(entity: IReport): Observable<IReport> {
    return this.apiService.create(requestSetItemApiUrl, entity);
  }

  executeReport(
    id: number,
    settings: IObjectAny,
    startDate: Date,
    stopDate: Date,
    boosters: number[],
    products: number[],
    drivers: number[],
    page,
    pageSize
  ): Observable<IApiReportExecuteResponse> {
    const period: IRange = {
      start: moment(startDate).format('DD.MM.YYYY'),
      stop: moment(stopDate).format('DD.MM.YYYY')
    };

    const allParams = {
      period,
      boosters,
      products,
      drivers
    };

    const params = {};
    if (settings.params) {
      settings.params.forEach(param => {
        params[param] = allParams[param];
      });
    }

    const requestParams = {
      id,
      params,
      page,
      pageSize
    };
    return this.apiService.getAllWParamsAsObject(requestExecuteReportApiUrl, requestParams);
  }

  managerReports(): Observable<IApiListResponse<IReport>> {
    return this.apiService.getAllAsObject(requestManagerReportsApiUrl);
  }
}
