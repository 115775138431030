<div mat-dialog-title>
  Введите время выполнения заказа(от текущего времени):
</div>
<div mat-dialog-content align='center'>

  <!--  <mat-form-field>-->
  <!--    <input matInput [(ngModel)]="data" style="font-size: 36px">-->
  <!--[defaultTime]="'00:45'"-->
  <ngx-timepicker-field
    [(ngModel)]='data.time'
    [format]='24'

    [controlOnly]='true'
    (ngModelChange)='changeDate()'
  ></ngx-timepicker-field>
  <div><b>Заказ должен быть доставлен до: {{data.date}}</b></div>
  <div>
    <mat-form-field class='comment' fxFlex='100%'>
      <mat-label>Комментарий водителю</mat-label>
      <textarea matInput [(ngModel)]='data.manager_comment'></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align='end'>
  <button mat-button (click)='onNoClick()'>Отмена</button>
  <button
    type='submit'
    mat-raised-button
    color='primary'
    [disabled]="data.time==='00:00'"
    [mat-dialog-close]='data'
  >
    Далее
  </button>

</div>
<ng-template #loading>
  <p class='text-center'>загрузка ...</p>
</ng-template>
