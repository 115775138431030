import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IOrder, IOrders, INewOrder, IProduct, IClientInfo, IVehicle, IDrivers } from 'src/app/shared/interfaces';
import { ProductService } from 'src/app/services/product.service';
import { ClientsService } from 'src/app/services/clients.service';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { defineLocale } from 'moment';
import { BoostersService } from 'src/app/services/boosters.service';
import { round, values } from 'lodash';
import { NotifierService } from 'angular-notifier';
import ymaps from 'ymaps';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-create-order-dialog',
  templateUrl: './create-order-dialog.component.html',
  styleUrls: ['./create-order-dialog.component.scss']
})
export class CreateOrderDialogComponent implements OnInit {
  private readonly notifier: NotifierService;
  form: FormGroup;
  description: string;
  fuel: IProduct;
  client = new FormControl();
  vehicle: IVehicle;
  testMode = true;

  orderSumm: number;
  order: INewOrder = {
    vehicle: null,
    lat: 0,
    lng: 0,
    comment: null,
    night_time: null,
    address: null,
    contact_number: null,
    sellings: [{
      id: null,
      price: null,
      amount: null,
      summ: 0.0,
      paymentAmount: 0.0,
      paymentSum: 0.0,
      discountAmount: 0,
      discountSumm: 0,
      balanceAmount: 0,
      balanceSum: 0.0,
      rebateAmount: 0.0,
      rebateSum: 0.0,
      rebatePercent: 0.0
    }]
  };
  bounds: [[54.58890871451359, 81.68754410607335], [55.35438409561197, 84.32426285607332]];
  clients: IClientInfo[];
  products: IProduct[];
  cars: IVehicle[];
  addressSuggestView: any;
  maps: ymaps;
  paymentTypeBank = true;
  filteredOptionsClient: Observable<any[]>;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private productsService: ProductService,
    private settingsService: SettingsService,
    private clientsService: ClientsService,
    private ordersService: OrdersService,
    private boostersService: BoostersService,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<CreateOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.notifier = notifierService;
    this.description = 'Новый заказ';
    // this.description = data.description;
  }

  ngOnInit() {
    this.form = this.fb.group({
      volume: [this.order.sellings[0].amount, ''],
      // client: [this.client, ''],
      car_no: ['Не задан', this.order.car_no],
      car_model: [this.order.car_model, ''],
      phone_number: [this.order.contact_number, ''],
      address: [this.order.address, ''],
      date_delivery: [this.order.date_delivery, new Date()],
      //      car: [this.vehicle, ''],

      comment_text: [this.order.comment, '']

    });
    ymaps
      // .load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU')
      .load(this.settingsService.getMapInitUrl())
      .then(maps => {
        this.maps = maps;

        const city = 'город' + this.settingsService.getCurrentCity().name;
        this.addressSuggestView = new maps.SuggestView('suggest_address', {
            provider: {
              suggest: (function(request, options) {

                return maps.suggest(city + ', ' + request);
              })
            }

            // boundedBy: this.bounds
          }
        );
      });


    this.form.get('volume').valueChanges.subscribe(value => {
      this.order.sellings[0].amount = value;
      this.order.sellings[0].paymentAmount = value;
      this.calcOrderSumm();
    });
    this.productsService.getProducts().subscribe(d => {
      const prods = d.products.filter(i => i.is_optional == false && i.is_active === true);
      this.products = prods;
    });
    this.clientsService.getFirms().subscribe(f => {
      this.clients = f.list;
      this.filteredOptionsClient = this.client.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.clients.slice())
        );
    });
  }

  displayFnClient(client: any): string {
    return client && client.name ? client.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.clients.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  save() {
    const ord: INewOrder = this.order;
    const address = this.addressSuggestView.state._data.request;
    ord.address = address;
    ord.comment = this.form.value.comment_text;
    ord.contact_number = this.form.value.phone_number;
    ord.car_no = this.form.value.car_no;
    ord.car_model = this.form.value.car_model;
    ord.payment_info = {
      paymentStatus: 1,
      paymentAuthStatus: 'success',
      paymentType: this.paymentTypeBank ? 'Bank' : 'Cash',
      paymentId: null,
      paymentErrorCode: null,
      paymentMessage: null,
      paymentAppleTransactionId: null,
      paymentApplePaymentData: null,
      paymentLog: null
    };
    if (this.auth.isRole(['SuperAdmin'])) {
      ord.additional_options = {
        testMode: this.testMode
      };
    }
    ord.date_delivery = this.form.value.date_delivery;
    if (this.client) {
      ord.clientId = this.client.value.id;
    }
    const geocoder = new this.maps.geocode(address).then(res => {
        if (res.metaData.geocoder.found > 0) {
          const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
          ord.lat = coordinates[0];
          ord.lng = coordinates[1];

        } else {
          const city = this.settingsService.getCurrentCity();
          if (city) {
            ord.lat = city.default_location.longitude;
            ord.lng = city.default_location.latitude;
          }
        }
        console.log(ord);
        this.ordersService.createOrder(ord).subscribe(response => {
            if (response.code < 0) {
              this.notifier.notify('error', response.message);
            } else {
              this.notifier.notify('success', 'Заказ создан');

              this.dialogRef.close(this.form.value);
            }
          }, error => {
            this.notifier.notify('error', 'Ошибка создания заказ (см. логи для подробностей');
            // console.log(error);
          }
        );

      },
      function(err) {
        this.notifier.notify('error', 'Ошибка создания заказ ' + err);
      }
    );

  }

  close() {
    this.dialogRef.close();
  }

  setFuel(product) {
    if (product) {
      this.order.sellings[0].id = product.id;
      this.order.sellings[0].price = product.prices[0].price;
      this.calcOrderSumm();
    }
  }

  setVolume(event) {
    if (event.target.value) {
      this.order.sellings[0].amount = event.target.value;
      this.order.sellings[0].paymentAmount = event.target.value;
      this.calcOrderSumm();
    }
  }

  setClient(client) {
    if (client) {
      this.order.phone_number = client.phone_number;
      this.clientsService.getCars(client.id).subscribe(f => {
        this.cars = f.list;
      });
    }
  }

  setVehicle(event) {
    if (event.value) {
      this.order.vehicle = event.value.id;
    }


  }

  calcOrderSumm() {
    this.orderSumm = round(this.order.sellings[0].amount * this.order.sellings[0].price, 2);
    this.order.sellings[0].summ = this.orderSumm;
    this.order.sellings[0].paymentSum = this.orderSumm;
  }

  setPaymentTypeBank() {

  }
}
