<div fxLayout='row' class='fixedH'>
  <div fxFlexOrder='1' fxFlex='1 1 auto' class='userListContainer' fxLayout='column' fxLayoutGap='10px'>
    <div class='userListFilter' fxLayoutGap='5px' fxFlex='0 0 40px' fxLayout='row'>
      <div fxFlex='3 2 auto' fxLayoutAlign='left center'>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput type='text' placeholder='Поиск'
               (input)='this.child.filterUsers($event.target.value)'>
      </div>
      <div fxFlex='1 1 auto' fxLayoutAlign='center center'>
        <select style='height: 21px;' (change)='applyFilter($event.target.value)'>
          <option *ngFor='let listFlt of listFilters' [value]='listFlt?.Id'>
            {{listFlt.Name}}
          </option>
        </select>
      </div>
    </div>
    <!-- <mat-button-toggle-group class="userListFilter"  fxFlex="0 0 30px" #group="matButtonToggleGroup" appearance="legacy"
        multiple="false" [value]="currentFilter" (change)="applyFilter(group.value)">
        <mat-button-toggle *ngFor="let listFlt of listFilters" [value]="listFlt?.Id">
          {{listFlt.Name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
      -->
    <div fxFlex class='userListWrapper'>
      <app-chat-user-list></app-chat-user-list>
    </div>
  </div>
  <div fxFlexOrder='2' fxFlex='5 5 auto' fxLayout='column'>
    <div fxFlex #scroller class='feedWrapper fixedH' (scroll)='scrolled($event)'>
      <app-chat-feed></app-chat-feed>
    </div>
    <div fxFlex='0 0 50px' fxLayoutAlign='left center' class='chatFormWrapper'>
      <app-chat-form></app-chat-form>
    </div>
  </div>
  <div fxFlexOrder='3' fxFlex='2 2 auto' fxLayout='column' class='userInfoWrapper'>
    <div class='userInfo'>
      <app-client-info></app-client-info>
    </div>
    <div fxLayout='row' class='ordersListHeader' fxLayoutAlign='center center' fxLayoutGap='5px'>
      <mat-icon fxFlex='0 0 7%'> history</mat-icon>
      <span>История заказов</span>
    </div>
    <div fxFlex class='userInfoOrders'>
      <app-client-order-list></app-client-order-list>
    </div>
  </div>
</div>
