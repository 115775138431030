import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { Observable } from 'rxjs';
import { IModel } from '../shared/interfaces';
// API
const getGetItemApiUrl = 'models/{id}/info';
const getSetItemApiUrl = 'models';
const getGetAllModelsApiUrl = 'models/all';

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor(private apiService: ApiV5Service) {
  }

  public getAllModels() {
    return this.apiService.getAllAsObject(getGetAllModelsApiUrl);
  }

  getModel(id: number): Observable<IModel> {
    return this.apiService.getEntity(getGetItemApiUrl.replace('{id}', String(id)));
  }

  saveModel(entity: IModel): Observable<IModel> {
    return this.apiService.create(getSetItemApiUrl, entity);
  }
}
