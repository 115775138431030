<p class="report_header">Информация по заказу <b>{{data.orderId}}</b></p>
<div fxLayout="column" flLayoutGap="10px">

  <span fxFlex>Остаток топлива: {{rest}}</span>


  <div fxFlex fxLayout="row" fxLayoutAlign="center center">
    <div *ngFor="let image of images">
      <img class="order_image" src="data:image/JPEG;base64,{{image}}">
    </div>
  </div>
  <p fxFlex><b>Отчет водителя:</b></p>

  <div fxFlex *ngFor="let item of report" fxLayoutGap="5px">
    {{item.name}} - {{item.amount  }}({{item.measure}})
  </div>
  <div fxFlex fxLayout="column">
    <p> <b>Комментарий:</b></p>
    <div fxFlex>{{internalComment}}</div>
  </div>



</div>