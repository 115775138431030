<div fxFlex fxLayout="row" fxLayoutGap="20px" fxLayoutAlign ="left center ">
<input fxFlex  *ngIf="auth.isVisible('chat','send')" matInput placeholder="Введите сообщение"
    class="chatInput" 
    
    [(ngModel)]="message" 
    (keydown)="handleSubmit($event)"/>

<button  fxFlex ="1 1 auto" fxLayoutAlign="center center" *ngIf="auth.isVisible('chat','send')" class="chatButton"  matTooltip="Отправить (Ctrl + Enter)"
  (click)=send()>
<mat-icon style="vertical-align: middle; ">send</mat-icon>
</button>
</div>