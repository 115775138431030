import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {ApiService} from '../../shared/services/api.service';
import {IDriver} from '../../shared/interfaces';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-driver-create-dialog',
  templateUrl: './driver-create-dialog.component.html',
  styleUrls: ['./driver-create-dialog.component.scss']
})
export class DriverCreateDialogComponent implements OnInit {

  form: FormGroup;
  submited: boolean;
  crSub: Subscription;
  constructor(
    public dialogRef: MatDialogRef<DriverCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private api: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      login: new FormControl(null,
        [Validators.required]),
      name: new FormControl(null,
        [Validators.required])
    });
  }
  submit() {
    if (this.form.invalid) {
      return;
    }
    this.submited = true;
    const driver: IDriver = {
      login: this.form.value.login,
      name: this.form.value.name
    };
    this.crSub = this.api.create('manager/driver/create', driver)
      .subscribe(() => {
        this.form.reset();
        this.dialogRef.close();
        this.submited = true;
    }, () => {
        this.submited = false;
      });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }


}
