import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../shared/interfaces';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from '../../shared/services/auth.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  form: FormGroup;
  message: string;
  submited: boolean;

  constructor(
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      login: new FormControl(null,
        [Validators.required]),
      password: new FormControl(null,
        [Validators.required,
          Validators.minLength(5)
        ])
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.submited = true;
    const user: User = {
      login: this.form.value.login,
      password: this.form.value.password,
      roles: "",
      cities: []
    };

    this.auth.login(user).subscribe(() => {
      this.form.reset();
      // this.router.navigate(['/']);
      this.dialogRef.close();
      this.submited = true;
    }, () => {
      this.submited = false;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
