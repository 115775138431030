// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {Environment} from './environment.interface';

export const environment: Environment = {
    appVersion: require('../../package.json').version + '-dev',
    production: true,
    apiKey: '',
    apiUrl: 'http://158.160.161.127',
    
    //  apiUrl: 'http://localhost:8080',
    // apiUrl: 'http://84.201.185.149',
    //apiUrl: 'http://84.201.188.39:8085',
    // apiUrl: 'http://130.193.62.45',
    // apiUrl: 'http://84.201.185.100',
    DbUrl: ''
};
