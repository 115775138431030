import { Component, OnInit, Input } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { ChatMessage } from '../../models/chat-message.model';
import { IMessages } from 'src/app/shared/interfaces';
@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() chatMessage:IMessages;
  messageContent:string;
  timeStamp:Date = new Date();
  userName: string;
  isClient: boolean;
  isReaded: boolean;

  constructor() { }

  ngOnInit(chatMessage = this.chatMessage) {
    this.messageContent =chatMessage.message;
    this.timeStamp=chatMessage.created;
    this.userName=chatMessage.userName;
    this.isClient = chatMessage.isClient===1;
    this.isReaded = (chatMessage.readStatus & 2)==2;

  }

}
