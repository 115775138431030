import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { FbAuthResponse, ICity, User } from '../interfaces';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // private handleError: any;
  public error$: Subject<string> = new Subject<string>();
  private access_info =
    {
      "order": {
        "set_payment": ["User","Admin", "SuperAdmin"],
        "cancel": ["User", "Admin", "SuperAdmin"],
        "complete": ["User", "Admin", "SuperAdmin"],
        "assign": ["User", "Admin", "SuperAdmin"],
        "export_buh": ["buh", "SuperAdmin"],
        "export": [ "OrdersExport","Admin", "SuperAdmin"],
        "select_period": ["buh", "User", "Admin", "SuperAdmin"],
        "create": ["FirmsOperator"],
        "createBuyers": ["ClientManager"],
        "field_summ": ["Admin"],
        "field_payment_type": ["Admin"],
        
      },
      "chat": {
        "send": ["ChatSend","User", "Admin", "SuperAdmin"],
      },
      "clients":{
        "list": ["ClientManager"],}
    }

  constructor(private http: HttpClient,
    
    
    
    private cookieService: CookieService
  ) { }

  get token(): string {
    return sessionStorage.getItem('token');
  }
  get user_name(): string {
    return sessionStorage.getItem('login');
  }
  get user_roles(): string {
    return sessionStorage.getItem('roles');
  }
  get user(): User {
    return JSON.parse(sessionStorage.getItem('user')) as User;
  }
  get user_city() {
    let city = sessionStorage.getItem('city');
    var res =null;
    if (city!='undefined') {
      res = JSON.parse( city) ;
    }
    return res;

  }
  set user_city(city: number ) {
    sessionStorage.setItem('city',JSON.stringify(city));
  }
  get user_cities(): number[] {
    var res = [];
    let list = sessionStorage.getItem('cities');
    if (list)
      res = JSON.parse(list);
    return res;
      

  }

  login(user: User): Observable<any> {
    user.returnSecureToken = true;
    sessionStorage.setItem('login', user.login);
    sessionStorage.setItem('roles', user.roles);

    
    // console.log(user);
    // this.headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': sessionStorage.getItem('token')
    // });
    // const myHeaders = new Headers();
    // myHeaders.append('Content-Type', 'application/json');
    // myHeaders.append('Access-Control-Allow-Origin', '*');
    // this.headers = this.headers.append('access-control-allow-credentials', 'true');
    // this.headers = this.headers.append('Authorization', sessionStorage.getItem('token'));
    // console.log(this.headers);
    // this.http.request()
    return this.http.post(`${environment.apiUrl}/api/v5/manager/auth/`, user)
      .pipe(
        tap(this.setToken.bind(this)),
        catchError(this.handleError.bind(this))
      );
  }
  handleError(error: HttpErrorResponse) {
    const { message } = error.error.error;
    this.setToken(null);
    switch (message) {
      case 'EMAIL_NOT_FOUND':
        this.error$.next('EMAIl NOT FOUNT');
        break;
      case 'INVALID_EMAIL':
        this.error$.next('EMAIl INCORRECT');
        break;
      case 'INVALID_PASSWORD':
        this.error$.next('PASSWORD INCORRECT');
        break;

    }
    return throwError(error);
  }

  logout() {
    this.setToken(null);
  }

  isAuthenticated(): boolean {
    return !!this.token;
  }
  isVisible(object, control) {
    var result = true;
    let obj = this.access_info[object];
    if (obj) {
      let ctrl = obj[control];
      if (ctrl) {
        result = this.isRole(ctrl);
      }

    }
    return result
  }
  isRole(roles): boolean {
    var result = false;
    if (this.token) {
      const userRoles: string[] = JSON.parse(this.user.roles);


      if (roles && userRoles) {
        roles.map(v => v.toLowerCase()).forEach(role => {

          if (userRoles.map(v => v.toLowerCase()).indexOf(role.toLowerCase()) != -1) {
            result = true;
            return result;
          }
        });
      }
    }
    return result;
  }
  private setToken(response: FbAuthResponse | null) {
    if (response) {
      sessionStorage.setItem('token', response.token);
      sessionStorage.setItem('user', JSON.stringify(response));
      sessionStorage.setItem('role', response.role);
      if (response.cities) {
          let cities=[];
          //if (this.settingsService.getSettings().cities.length>0){
            //cities = this.settingsService.getSettings().cities.filter(item => response.cities.includes(item.id));
            cities =  response.cities;
            
          //}
          
        
        sessionStorage.setItem('cities',JSON.stringify( cities));
        if (cities.length>0)
          this.user_city = cities[0]
      }

      try {
        this.cookieService.set('user', JSON.stringify(response));
      } catch (e) {
        console.log(e);
      }

    } else {
      sessionStorage.clear();
    }
  }

}

