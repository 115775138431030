import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, Sort, MatPaginator, MatDialog } from '@angular/material';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { IProduct } from 'src/app/shared/interfaces';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ProductService } from 'src/app/services/product.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { round } from 'lodash';
import { MessageService } from 'ui-message-angular';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-products-page',
  templateUrl: './products-page.component.html',
  styleUrls: ['./products-page.component.scss']
})
export class ProductsPageComponent extends BaseListPageComponent<IProduct> implements OnInit {
  displayedColumns = ['id', 'is_active', 'name', 'measure', 'is_optional', 'index', 'price', 'night_price', 'min_amount', 'max_amount', 'mark_color', 'description', 'actions'];
  DetailRoute: string = 'productDetail';

  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private productsService: ProductService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
      
    );
    this.Title = 'Продукты';
  }

  protected getData() {
    this.productsService.getProducts().subscribe(data => {
      console.log(data);
    });
    return this.productsService.getProducts();
  }

  confirm(obj: IProduct) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '540px',
      data: { title: obj.name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.delete(obj);
      }
    });
  }

  newItem(): void {
    this.router.navigate(['productDetail', '/', { action: 'new' }]);
  }

  delete(obj: IProduct) {
    /*
       this.articleService.deleteArticle(obj);
       this.dataSource._updateChangeSubscription();
       this.snackBar.open('Article deleted', 'dismiss', {
         duration: 2000,
       });
       */
  }

  protected getDataSort(): Sort {
    return { active: 'index', direction: 'asc' };

  }

  protected getDataSource(data) {
    let ds = new MatTableDataSource<IProduct>(data.products/*.sort((a,b)=> {return a.index - b.index
                                                                            && Number(a.is_optional) - Number(b.is_optional) 
                                                                            } )*/);


    return ds;
  }

}
