import { Component, OnInit, OnDestroy } from '@angular/core';
import { IOrder } from 'src/app/shared/interfaces';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import * as moment from 'moment';

@Component({
  selector: 'app-client-order-list',
  templateUrl: './client-order-list.component.html',
  styleUrls: ['./client-order-list.component.scss']
})
export class ClientOrderListComponent implements OnInit,OnDestroy {
  orders: IOrder[]=[];
  
  timeSub;
  dataSub:Subscription;
  constructor(private chat: ChatService) {}
    
  
  dataRefresh() {
    this.dataSub = this.chat.getCurrentUserOrders().subscribe(orders => {
      this.orders = orders;
      this.orders = this.orders.sort((val1, val2) => { return (moment(val2.created_at, 'DD.MM.YYYY HH:mm:ss').diff(moment(val1.created_at, 'DD.MM.YYYY HH:mm:ss'))) });
    })}

  ngOnInit() {
    this.dataRefresh();
     this.timeSub = setInterval(() => {
      this.dataRefresh();
    }, 15*1000);
    this.chat.currentUser.subscribe(client => {
      if (client)
        this.dataRefresh();
    })
  }
  ngOnChanges() {
    //this.feed = this.chat.getMessages()
    this.dataRefresh();
  
  }
  
  ngOnDestroy(): void {
  
    if(this.dataSub) {
    this.dataSub.unsubscribe();
  }
  clearInterval(this.timeSub);
  }

  }
  
