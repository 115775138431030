import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { IProduct, IClientInfo } from 'src/app/shared/interfaces';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { Router } from '@angular/router';
import { MessageService } from 'ui-message-angular';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-client-price-list',
  templateUrl: './client-price-list.component.html',
  styleUrls: ['./client-price-list.component.scss']
})
export class ClientPriceListComponent extends BaseListPageComponent<IProduct> implements OnInit {
  displayedColumns = ['id', 'name', 'actions'];
  
 
  @Input()
  set client(val: any) {
    console.log('client = ', val);
    this._item = val;
  }
  get item(): any { 
    return this._item;
  }
  private _item; // private property _item
  constructor(
    protected dialog: MatDialog,
     protected settings:SettingsService,
     protected auth: AuthService,
     protected router: Router,
     protected messageService: MessageService,
     private clientsService: ClientsService,
     
  ) {
    super(
      dialog,
      settings,      
      auth,
      router,
      messageService,
     
    );
    
    
  }
  
  protected getData() {
    if (this.client)
      return this.clientsService.getProducts(this.client.id);
  }
  
}
