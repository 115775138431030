import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import ymaps from 'ymaps';
import { IAssignOrder, IChangeStatus, IDrivers, IOrders, IOrder, ILocation } from '../../shared/interfaces';
import { Subscription } from 'rxjs';
import { WtDialogComponent } from '../../wt-dialog/wt-dialog.component';
import { MatDialog } from '@angular/material';
import { Columns, Config, DefaultConfig, Event } from 'ngx-easy-table';
import { ApiV5Service } from 'src/app/shared/services/apiV5.service';
import * as moment from 'moment';
import { OrdersService } from 'src/app/services/orders.service';
import * as _ from 'lodash';
import { OrderDetailsPopupComponent } from 'src/app/chat/chat-popup/chat-popup.component';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { BoostersService } from 'src/app/services/boosters.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { AssignDriverDialogComponent } from 'src/app/dialogs/assign-driver-dialog/assign-driver-dialog.component';
import { CompleteOrderDialogComponent } from 'src/app/dialogs/complete-order-dialog/complete-order-dialog.component';
import { Title } from '@angular/platform-browser';
import { ChangeAddressDialogComponent } from '../../dialogs/change-address-dialog/change-address-dialog.component';
import { ChatService } from '../../services/chat.service';


@Component({
  selector: 'app-mapjs-page',
  templateUrl: './mapjs-page.component.html',
  styleUrls: ['./mapjs-page.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class MapjsPageComponent implements OnInit {
  @ViewChild(OrderDetailsPopupComponent) child: OrderDetailsPopupComponent;
  public clicked: string;
  promiseAdres: Promise<any>;
  data: IOrders[] = [];
  ordersByStatus: any;
  currentStatusFilter = [];
  isNightFlt = { status: 'unchecked' };
  dataAll: IOrders[] = [];
  dataFiltered: IOrders[] = [];
  dataDriver: IDrivers[] = [];
  dataSub: Subscription;
  drSub: Subscription;
  // public data$: Observable<HttpResponse<IOrders[]>>;
  timeSub;
  ballonState = false;
  filter_optional = { is_optional: 1 };
  public selected = new Set<any>();
  private innerWidth: number;
  private innerHeight: number;
  public configuration: Config;
  public columns: Columns[];
  public clientTypeFilter = '-1';
  placemarkList = [];
  map: any;
  showOrdersPanel = true;
  @ViewChild('table', { static: true }) table: Event;
  defaultLocation: ILocation;

  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView();
  }

  constructor(private apiV5Service: ApiV5Service,
              private titleService: Title,
              public settings: SettingsService,
              public auth: AuthService,
              private orders: OrdersService,
              private boosters: BoostersService,
              public dialog: MatDialog,
              private chat: ChatService
              // public newOrdersService: NewOrdersService
  ) {
  }

  ngOnInit() {
    //this.titleService.setTitle("Танкап - карта");
    this.defaultLocation = this.settings.getCurrentCity().default_location;

    this.configuration = { ...DefaultConfig };
    this.configuration.searchEnabled = true;
    this.columns = [
      { key: 'id', title: 'Список заказов' }

    ];
    this.currentStatusFilter = [2, 3, 4, 5];
    this.checkView();
    // this.table.
    this.initMap();
    /*
    this.dataSub = this.apiV5Service.getAll('manager/find').subscribe(data => {
      // this.data = data;
      // console.log(this.data.filter(dataK => dataK.order_status !== 3));
      this.setDataVars(data);
      this.dataAll = data;
      // console.log(data);
      this.data = data.filter(dataK => dataK.order_active === 1);
      
      this.data.forEach(o => o.checkAddress = (o.lat == this.defaultLocation.longitude || o.lat ==55.0415 ) && (o.lon == this.defaultLocation.latitude || o.lon == 82.9346));
      
      // @ts-ignore
      this.drSub =this.boosters.getAll().subscribe(dataDr => {
        // console.log(data);
        this.dataDriver = dataDr.boosters;
        // console.log(this.dataDriver.filter(dataK => dataK.id === 14));

//        this.initMap();
      });
    });*/
  }

  initMap() {
    ymaps
      //.load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU&load=package.full')
      .load(this.settings.getMapInitUrl('package.full'))
      .then(maps => {

        this.map = new maps.Map('map', {
          center: [this.defaultLocation.latitude, this.defaultLocation.longitude],
          zoom: 12,
          controls: ['zoomControl'],

          clusterer: [{
            preset: 'islands#invertedVioletClusterIcons',
            hasBaloon: false
          }]
        });
        let map = this.map;

        var trafficControl = new maps.control.TrafficControl({
          state: {
            // Отображаются пробки "Сейчас".
            providerKey: 'traffic#actual',
            // Начинаем сразу показывать пробки на карте.
            trafficShown: false
          }
        });
        // Добавим контрол на карту.
        map.controls.add(trafficControl);
        // Получим ссылку на провайдер пробок "Сейчас" и включим показ инфоточек.
        trafficControl.getProvider('traffic#actual').state.set('infoLayerShown', true);
        this.loadOrders(map, maps);
        this.timeSub = setInterval(() => {
          this.loadOrders(map, maps);
        }, 30000);
        map.geoObjects.events.add('balloonopen', (e => {
          this.ballonState = true;
        }));
        map.geoObjects.events.add('balloonclose', (e => {
          this.ballonState = false;
        }));


      })
      .catch(error => console.log('Failed to load Yandex Maps', error));
  }

  driverInfo(id: number): string {
    let driver = this.dataDriver.filter(booster => booster.id === id).pop();
    if (driver) {
      return driver.name + '(' + driver.fuel_type + ') / ' + driver.login;
    } else {
      return '';
    }
  }

  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  private onClickAssign() {

  }

  openOrderDetails(order) {
    const dialogRef = this.dialog.open(OrderDetailsPopupComponent, {
      data: order,
      disableClose: false,
      //width: '600px',
      //height: '700px',
      panelClass: 'close-btn-dialog'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

      }
    });

  }

  loadOrders(map, maps) {
    if (!this.ballonState) {
      map.geoObjects.removeAll();
      this.placemarkList = [];
      this.dataSub = this.orders.getOrdersFind().subscribe(dataI => {

        if (dataI) {
          dataI = this.orders.filterOrdersForSuperAdmin(dataI);
          dataI.forEach(o => o.checkAddress = (o.lat == this.defaultLocation.longitude || o.lat == 55.0415) && (o.lon == this.defaultLocation.latitude || o.lon == 82.9346));
          dataI = dataI.sort((val1, val2) => {
            return val2.id - val1.id;
          });
          if (this.selected.size === 0 || !this.selected) {
            this.dataAll = dataI;
          }
          this.setDataVars(dataI);
          this.data = dataI.filter(dataK => dataK.order_active === 1);

          this.chat.getUsers().subscribe(usersAll => {
            const users = usersAll.filter(u => u.newMessagesCnt > 0);
            this.data = this.data.map(d => {
              const user = users.find(u => u.id === d.client_id);
              return {
                ...d,
                newMessagesCnt: user ? user.newMessagesCnt : 0
              };
            });


            this.drSub = this.boosters.getAll().subscribe(dataDr => {
              this.dataDriver = dataDr.boosters;
              for (const d of this.data) {
                let image = '';
                if (d.order_status === 2) {
                  if (d.night_time == 1) {
                    image = 'assets/img/placemark_new_night.svg';
                  } else {
                    image = 'assets/img/placemark_new.svg';
                  }
                } else if (d.night_time == 1) {
                  image = 'assets/img/placemark_night.svg';
                } else {
                  image = 'assets/img/placemark.svg';
                }


                const placemark = new maps.Placemark(
                  [d.lat, d.lon],
                  {
                    hintContent: '№ ' + d.id + '  Водитель:' + d.client_name,
                    /*iconContent: [
                      d.newMessagesCnt ? `<div
                        style='position: relative;
                        left: 25px;
                        top: -15px;
                        height: 17px;
                        width: 17px;
                        background-color: red;
                        border-radius: 50%;'></div>` : ''
                    ].join('')*/
                    /* balloonContentHeader: 'Заказ № ' + (d.id + 1),
                     balloonContentBody: [
                       '<p> <b>Автомобиль:</b> ' + d.car_model + ' <b>гос номер:</b> ' + d.car_no + '</p>',
                       '<p> <b>Детали заказа:</b> </p>',
                       '<p> <b>Вид ГСМ:</b> ' + d.fuel_name + ' - ' + d.liters + 'л. </p>'

                     ].join(''),*/
                    //iconContent: d.id
                  },
                  {
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: image,
                    iconImageSize: [64, 64],
                    iconImageOffset: [-32, -32],
                    iconContentOffset: [15, 15]
                  }
                );

                map.geoObjects.add(placemark);
                let pm = maps.geoQuery(placemark).getCenter(map);

                this.placemarkList.push({ 'id': d.id, 'object': placemark, 'geoObject': pm });

                placemark.data = d;
                placemark.events.add('click', (e) => {
                  var object = e.get('target');
                  this.openOrderDetails(object.data);
                });
              }

              for (const d of this.dataDriver) {
                let content = `№ ${d.id} Заправщик: ${d.name} <br><b>Заказов:</b> ${d.orders.length}`;
                content += d.orders.length > 8 ? ` (отображено 8 заказов из ${d.orders.length})` : '';
                content += '<ol style="margin-top:1px;width:250px;border:1px solid black;" >';

                d.orders.forEach((o, i) => {
                  const order = this.data.find(or => or.id === o.id);
                  const title = order ? `${order.id} - ${order.fuel_name} (${order.liters} л.)` : '';
                  if (i < 8) {
                    content += o.current === 1 ?
                      `<li><b>${title}</b></li>` :
                      `<li>${title}</li>`;
                  }
                });

                content = content + '</ol>';
                content = content + '<b>Остатки:</b>';
                content = content + '<ol style="margin-top:1px;width:250px;border:1px solid black;-moz-column-count: 2;-moz-column-gap: 10px;-webkit-column-count: 2;-webkit-column-gap: 10px;column-count: 2;column-gap: 10px;" >';
                for (const b of d.balance) {
                  if (b.is_optional != 1) {
                    content = content + '<li><b>' + b.name + ' (' + b.amount + ' ' + b.msr + ')</b></>';
                  } else {
                    content = content + '<li>' + b.name + ' (' + b.amount + ' ' + b.msr + ')</>';
                  }
                }
                content = content + '</ol>';

                const placemark = new maps.Placemark(
                  [d.lat, d.lon],
                  {
                    hintContent: content,
                    iconContent: [
                      '<div class ="driver_icon_content">',
                      '<div class="driver_orders_cnt" style="position: relative;left: 28px;top: 15px;font-size:20px"> <b>' + d.orders.length + ' </b></div>',
                      '<div class="driver_name" style="position: relative;left: 42px;top: -14px;"<b> ' + d.name + '</b> </div>',
                      '</div>'
                    ].join('')
                  },
                  {
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: (d.orders.length > 0) ? 'assets/img/vectorpaint.svg' : 'assets/img/vectorpaint_free.svg',
                    iconImageSize: [64, 64],
                    iconImageOffset: [-32, -32],
                    //iconContentOffset: [44, 3]
                    iconContentOffset: [0, 0]
                  }
                );
                map.geoObjects.add(placemark);

                placemark.data = d;
              }
            });
          });
        }
      });
    }
  }


  onChangeCheck(order: IOrders, $event: any) {

    if ($event.checked) {
      this.selected.add(order.id);
    } else {
      this.selected.delete(order.id);
    }
    // console.log(order.id);
    // console.log(this.selected);
  }

  getDriver(id: number) {
    // console.log(this.dataDriver.filter(dataK => dataK.id === id));
    return this.dataDriver.filter(dataK => dataK.id === id);
    // return null;
  }

  openAssignDialog(id: number, mass: boolean): void {

    let orderIsNight = this.data.find(o => o.id == id).night_time === 1;
    let hours = moment().hours();
    if (hours < 20 && orderIsNight) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Внимание !!! ', text: 'Заказ на ночную заправку. Вы уверены что хотите назначить водителя на заказ сейчас ?' },
        width: '500px'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          this.assignDriver(id, mass);
        }
      });
    } else {
      this.assignDriver(id, mass);
    }
  }

  assignDriver(id: number, mass: boolean): void {
    const order = this.data.find(o => o.id === id);
    const dialogRef = this.dialog.open(AssignDriverDialogComponent, {
      data: { id, order },
      width: '800px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // @ts-ignore
          const dialogRefT = this.dialog.open(WtDialogComponent, {
            data: { time: '00:45', date: '', manager_comment: '' },
            width: '800px'
          });
          dialogRefT.disableClose = true;
          dialogRefT.afterClosed().subscribe(resultD => {
            const d: IAssignOrder = {
              order_id: id,
              driver_id: result,
              date_delivery_plan: resultD.date,
              waiting_time: resultD.time,
              manager_comment: resultD.manager_comment
            };

            this.orders.assignDriver(d).subscribe(response => {
              this.refreshAllOrders();
            });
          });
        }
      }
    );
  }

  complete(id: number) {
    const dialogRef = this.dialog.open(CompleteOrderDialogComponent, {
      data: { title: 'Завершение заказа', text: 'Завершить заказ?', value: 0 },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const d: IChangeStatus = {
          order_id: id,
          order_status_id: 6,
          fuel_rest: result.value


        };
        this.apiV5Service.create('manager/order/set/status', d).subscribe(response => {
          this.refreshAllOrders();
        });
      }
    });
  }

  canceled(id: number) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Отмена заказа', text: 'Отменить заказ?', value: 0 },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        const d: IChangeStatus = {
          order_id: id,
          order_status_id: -2
        };
        this.apiV5Service.create('manager/order/set/status', d).subscribe(response => {
          this.refreshAllOrders();
        });

      }
    });
  }

  eventEmitted($event: { event: string, value: any }): void {
    if ($event.event === 'onClick') {
      let id = $event.value.row.id;
      console.log(id);
      if (id) {
        let baloon = this.placemarkList.find(o => o.id == id);
        if (baloon) {

          //baloon.object.events.fire('click');
          this.map.setCenter(baloon.geoObject);
          /*ymaps
          .load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU&load=package.full')
          .then(maps => {
          let pm = maps.geoQuery(baloon.object).getCenter(this.map);
          this.map.setCenter(pm);
          });
          */
        }


      }
    }
    if ($event.event === 'onPagination') {
      this.selected.clear();
    }
  }

  getWidth() {
    if (this.isMobile) {
      return this.innerWidth;
    } else {
      return '30%';
    }
  }

  getMapHeight() {
    if (this.isMobile) {
      return this.innerHeight;
    } else {
      return '650px';
    }

  }

  refreshAllOrders() {
    this.dataSub = this.orders.getOrdersFind().subscribe(dataI => {
      const data = this.orders.filterOrdersForSuperAdmin(dataI);
      this.dataAll = data;
      this.setDataVars(data);
    });
  }

  setDataVars(dataI: IOrders[]) {
    dataI = this.applyIsNightFltToDS(dataI);

    dataI = dataI.filter(x => x.order_status > 0);
    var result = _.groupBy(dataI, function(n) {
      return n.order_status;
    });

    this.ordersByStatus = Object.keys(result).map(key => ({
      statusId: Number(key), statusName: this.orders.getStatusOrder(Number(key)),
      color: this.orders.colorByStatus(Number(key)),
      items: result[key], count: result[key].length
    }));
    // this.newOrdersService.setNewOrders(this.ordersByStatus);
    this.applyOrderStatusFilter(null);
    // this.applyClientTypeFilter(null);
  }

  getStatusOrder(status: number) {
    return this.orders.getStatusOrder(status);

  }

  applyOrderStatusFilter(filter) {
    let fltValue = this.currentStatusFilter;
    if (filter) {
      fltValue = filter;
      this.currentStatusFilter = fltValue;
    }
    if (fltValue.length > 0) {
      this.dataFiltered = this.dataAll.filter(i => fltValue.includes(i.order_status));
    } else {
      this.dataFiltered = this.dataAll.filter(x => x.order_status > 0);
    }
    if (this.clientTypeFilter !== '-1') {
      this.dataFiltered = this.dataFiltered.filter(d => d.client_type === Number(this.clientTypeFilter));
    }

    this.dataFiltered = this.applyIsNightFltToDS(this.dataFiltered);
  }

  applyClientTypeFilter(value) {
    let fltValue = this.clientTypeFilter;
    if (value !== null) {
      fltValue = value;
      this.clientTypeFilter = fltValue;
    }
    if (fltValue !== '-1') {
      this.dataFiltered = this.dataAll.filter(d => d.client_type === Number(fltValue));
    } else {
      this.dataFiltered = this.dataAll;
    }
    if (this.currentStatusFilter.length > 0) {
      this.dataFiltered = this.dataFiltered.filter(i => this.currentStatusFilter.includes(i.order_status));
    } else {
      this.dataFiltered = this.dataFiltered.filter(x => x.order_status > 0);
    }
    this.dataFiltered = this.applyIsNightFltToDS(this.dataFiltered);
  }

  changeAddress(lat, lon, id) {
    const dialogRef = this.dialog.open(ChangeAddressDialogComponent, {
      data: { title: 'Изменение адреса', order: this.data.find(d => d.id === id), lat, lon },
      width: '700px',
      height: '250px'
    });
    dialogRef.disableClose = false;
  }

  checkIsNightFlt(isNightFlt) {
    switch (isNightFlt.status) {
      case 'checked': {
        isNightFlt.status = 'unchecked';

        break;
      }
      case 'unchecked': {
        isNightFlt.status = 'crossed';
        break;
      }
      case 'crossed': {
        isNightFlt.status = 'checked';

        break;
      }
    }
    this.setDataVars(this.dataAll);

  }

  applyIsNightFltToDS(data: IOrders[]): IOrders[] {
    switch (this.isNightFlt.status) {
      case 'checked': {
        data = data.filter(x => x.night_time == 1);
        break;
      }
      case 'unchecked': {
        data = data.filter(x => x.night_time == 0);
        break;
      }
    }
    return data;
  }
}
