<div class='mat-form-field-flex header-container'>

  <div *ngIf="auth.isVisible('order','select_period')">

    <div fxLayout='row' fxLayoutAlign='space-around center'>
      <span><b>Реестр заказов за период </b> </span>

      <input fxFlexOffset='20px' [matDatepicker]='startPeriod' [(ngModel)]='startDate'>
      <mat-datepicker-toggle [for]='startPeriod'>

      </mat-datepicker-toggle>
      <mat-datepicker #startPeriod></mat-datepicker>
      <mat-label> -</mat-label>
      <input [matDatepicker]='stopPeriod' [(ngModel)]='stopDate'>
      <mat-datepicker-toggle [for]='stopPeriod'>

      </mat-datepicker-toggle>
      <mat-datepicker #stopPeriod></mat-datepicker>
      <button mat-flat-button class='refreshDataButton' matTooltip='Обновить' (click)='refreshData()'>
        <i class='material-icons'>
          refresh
        </i>
      </button>


      <div fxLayout='row' fxFlexOffset='20px'>
        <div fxLayout='row' fxLayoutWrap fxLayoutAlign='space-around center' fxLayoutGap='20px'>
          <mat-button-toggle-group #group='matButtonToggleGroup' name='fltClientType' appearance='legacy'
                                   [value]='clientTypeFilter' (change)='applyClientTypeFilter(group.value)'>
            <mat-button-toggle value='-1'>Все</mat-button-toggle>
            <mat-button-toggle value='0'>Физ. лица</mat-button-toggle>
            <mat-button-toggle value='1'>Юр. лица</mat-button-toggle>
          </mat-button-toggle-group>

          <input type='checkbox' [indeterminate]="isNightFlt?.status === 'crossed'"
                 [ngModel]="isNightFlt?.status === 'checked'" (ngModelChange)='checkIsNightFlt(isNightFlt)'> Ночная
          заправка

        </div>

      </div>
    </div>
  </div>
  <div fxFlex='1 1 auto'></div>

  <span fxFlex='1 1 auto'></span>
  <div class='form-control'>
    <div>
      <button *ngIf="auth.isVisible('order','export')" mat-flat-button class='exportToExcel'
              matTooltip='Экспорт в Excel' (click)="exportToExcel('common')">
        <i class='material-icons'>
          text_snippet
        </i>
      </button>
    
    </div>

    <!--
    <button mat-flat-button color="primary" matTooltip="Массово Назначить" [disabled]="selected.size===0"
      (click)="openAssignDialog(0, true)">
      <i class="material-icons">
        time_to_leave
      </i>

    </button>
    <button mat-flat-button style="margin-left: 10px" color="warn" matTooltip="Массово завершить"
      [disabled]="selected.size===0" (click)="complete(0, true)">
      <i class="material-icons">
        beenhere
      </i>
    </button>
    <button mat-flat-button style="margin-left: 10px" color="primary" matTooltip="Массово отменить"
      [disabled]="selected.size===0" (click)="canceled(0, true)">
      <i class="material-icons">
        format_color_reset
      </i>
    </button>
-->
  </div>
</div>

<div class='columns mt-2'>
  <div class='column col-12'>
    <ngx-table [configuration]='configuration' [data]='filteredData' #table [detailsTemplate]='detailsTemplate'
               [columns]='columns'>
      <ng-template let-row let-index='index'>
        <td [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <mat-checkbox (change)='onChange(row,$event)'></mat-checkbox>
        </td>
        <td [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.id}}
        </td>
        <td [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.created_at}}
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <div *ngIf='row.client_name'>
            {{row.client_name}}
          </div>
          <div *ngIf='!row.client_name'>
            Не узказано
          </div>
        </td>


        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          +7 {{row.phone_number | mask: '(000) 000-00-00'}}
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <div *ngFor='let prod of row.sellings'>
            {{prod?.name}} {{prod?.amount}} {{prod?.measure}}
          </div>
        </td>

        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.car_model}}

          <div *ngIf='row.car_color'>({{row.car_color}})</div>
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.car_no}}
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{statusOrder[row.order_status + 2]}}
        </td>

        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.address}}
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{row.comment?.replace('None', '')}}
        </td>
        <td align='center' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <mat-checkbox [checked]='row.night_time'></mat-checkbox>
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          {{ row.driver?.name }}
        </td>

        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <ng-container *ngIf='row.date_delivery_plan'>
            {{row.date_delivery_plan }}
          </ng-container>

        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <ng-container *ngIf='row.date_delivery_fact'>
            {{row.date_delivery_fact }}
          </ng-container>

        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <ng-container *ngIf='row.order_summ'>
            {{row.order_summ }}
          </ng-container>
        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <ng-container *ngIf='row.payment_type'>
            {{ordersService.orderPaymentType[row.payment_type]}}

          </ng-container>

        </td>
        <td *ngIf='!isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">

          <!--<div [ngClass]="{'disabled':row.order_active===0 && row.order_status!=1}">-->
          <div>
            <button *ngIf="auth.isVisible('order','assign')" mat-icon-button [disabled]='row.order_active===0'
                    (click)='openAssignDialog(row.id, false)'>
              <mat-icon *ngIf='!row.driver?.id' class='material-icons' style='cursor: pointer;' matTooltip='Назначить'>
                time_to_leave
              </mat-icon>
              <mat-icon *ngIf='row.driver?.id' style='color: limegreen; cursor: pointer;' class='material-icons'
                        matTooltip='Переназначить'>
                repeat
              </mat-icon>
            </button>
            <button *ngIf="auth.isVisible('order','complete')" mat-icon-button [disabled]='row.order_active===0'
                    (click)='complete(row.id, false)'>
              <mat-icon [ngClass]="{
              'new-order': row.order_status===2,
              'complite-order': row.order_status===6
              }" matTooltip='Завершить'>
                beenhere
              </mat-icon>
            </button>

            <button *ngIf="auth.isVisible('order','cancel')" mat-icon-button
                    [disabled]='row.order_active===0  && row.order_status!=1' (click)='canceled(row.id, false)'>
              <mat-icon [ngClass]="{
              'new-order':  row.order_active===1,
              'canceled-order': row.order_status !> 0
              }" matTooltip='Отменить'>
                format_color_reset
              </mat-icon>
            </button>

            <button *ngIf="auth.isVisible('order','set_payment')" mat-icon-button
                    [disabled]='!(row.order_status===-1 || row.order_status===1 )' (click)='set_payment(row.id, false)'>
              <mat-icon matTooltip='Пометить как оплачен'>
                money
              </mat-icon>
            </button>

            <button *ngIf="auth.isVisible('order','check_bank')" mat-icon-button [disabled]='row.order_status===-2'
                    (click)='request_payment(row.id, false)'>
              <mat-icon matTooltip='Проверить банк'>
                request_quote
              </mat-icon>
            </button>
            <button mat-icon-button (click)='openOrderDetails(row.id)'>
              <mat-icon matTooltip='Детали заказа'>
                description
              </mat-icon>
            </button>
          </div>
        </td>

        <td *ngIf='isMobile' [ngStyle]="{ 'backgroundColor': settingsService.getColorByOrderStatus(row)}">
          <button mat-flat-button color='primary' id='expandButton-{{index}}' (click)='onRowClickEvent($event, index)'>
            Детали
            <i class='material-icons' *ngIf='!toggledRows.has(index)'>keyboard_arrow_right</i>
            <i class='material-icons' *ngIf='toggledRows.has(index)'>keyboard_arrow_down</i>

          </button>
        </td>
      </ng-template>
    </ngx-table>
    <ng-template #detailsTemplate let-row>
      <div>
        <!--        <div class="mat-action-row">-->
        <mat-card>
          <mat-card-header>
            <h2> {{row.o_name }} </h2>
          </mat-card-header>
          <mat-card-content>
            <p>
              {{row.phone_number }}
              {{row.liters }} л.
              {{row.fuel_name }}
            </p>
            <p>Статус: {{statusOrder[row.order_status + 2]}}</p>
            <p *ngIf='row.driver_id'>Заправщик № {{row.driver_id}}</p>
          </mat-card-content>
          <mat-card-actions align='end'>
            <button mat-flat-button color='primary' [disabled]='row.order_active===0'
                    (click)='openAssignDialog(row.id, false)'>
              <i class='material-icons'>
                time_to_leave
              </i>

            </button>
            <button mat-flat-button style='margin-left: 10px' color='warn' [disabled]='row.order_active===0'
                    (click)='complete(row.id,false)'>
              <i class='material-icons'>
                beenhere
              </i>
            </button>
            <button mat-flat-button style='margin-left: 10px' color='primary' [disabled]='row.order_active===0'
                    (click)='canceled(row.id, false)'>
              <i class='material-icons'>
                format_color_reset
              </i>
            </button>
            <button mat-flat-button style='margin-left: 10px' color='primary' [disabled]='row.order_active===0'
                    (click)='set_payment(row.id, false)'>
              <i class='material-icons'>
                attach_money
              </i>
            </button>
            <button mat-flat-button style='margin-left: 10px' color='primary' [disabled]='row.order_active===0'
                    (click)='request_payment(row.id, false)'>
              <i class='material-icons'>
                request_quote
              </i>
            </button>
          </mat-card-actions>
        </mat-card>

      </div>
    </ng-template>
  </div>
</div>
<app-spinner></app-spinner>
