import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthService
    ) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      
        const currentUser = this.authenticationService.user;
        const userRoles = JSON.parse(currentUser.roles);
        var result = false;
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && userRoles ) {
              route.data.roles.map(v => v.toLowerCase()).forEach(role => {
                if (userRoles.map(v => v.toLowerCase()).indexOf(role.toLowerCase())!=-1) {
                  result = true;
                  return result;
                  
                }
              });
              
            }
            if (!result) {
              this.router.navigate(['/']);
              result = false;
            }
        }
        else {
        // not logged in so redirect to login page with the return url
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          result = false;
        }
        return result;
    }
}