import { Component, OnInit, Input, Inject, ViewChild, ElementRef, OnDestroy, AfterViewInit, AfterViewChecked } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { ChatUser } from 'src/app/models/chat-user.model';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { IOrders } from 'src/app/shared/interfaces';
import { AuthService } from 'src/app/shared/services/auth.service';

import { SettingsService } from 'src/app/services/settings.service';
import { OrdersService } from 'src/app/services/orders.service';
import { BoostersService } from 'src/app/services/boosters.service';
import { OrderBaseComponent } from 'src/app/base/order-base/order-base.component';

@Component({
  selector: 'app-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.scss']
})
export class OrderDetailsPopupComponent extends OrderBaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Input() userId: number;
  @ViewChild('scroller') scrollFrame: ElementRef;
  // @ViewChildren(ChatFeedComponent, { read:  ChatMessageComponent }) itemElements: QueryList<any>;

  private isNearBottom = true;
  private scrollContainer: any;

  constructor(
    settings: SettingsService,
    orders: OrdersService,
    boosters: BoostersService,
    private chat: ChatService,
    public dialog: MatDialog,
    public auth: AuthService,
    public dialogRef: MatDialogRef<OrderDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOrders
  ) {
    super(settings,
      auth,
      orders,
      boosters,
      dialog
    );
  }

  ngOnInit() {
    const user = new ChatUser();
    user.id = this.data.client_id;
    this.chat.setSelectedUser(user);
    super.ngOnInit();
  }

  onNoClick() {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    // this.itemElements.changes.subscribe(_=>{this.onItemElementsChanged(); console.log(_);})
    // console.log(this.itemElements);
  }

  ngAfterViewChecked() {
    this.onItemElementsChanged();
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 50;
    if (this.scrollContainer) {
      const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
      const height = this.scrollContainer.scrollHeight;
      return position > height - threshold;
    } else {
      return true;
    }
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }
}
