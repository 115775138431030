<form [formGroup]='form' (ngSubmit)='submit()'>
  <div mat-dialog-content>
    <div
      class='form-control'
      [ngClass]="{invalid: form.get('login').touched && form.get('login').invalid}"
    >
      <mat-form-field>
        <input matInput id='login' formControlName='login' placeholder='Логин'>
      </mat-form-field>
      <div
        *ngIf="form.get('login').touched && form.get('login').invalid"
        class='validation'
      >
        <small *ngIf="form.get('login').errors.required">
          Пожалуйста введите имя пользователя
        </small>
      </div>
    </div>
    <div
      class='form-control'
      [ngClass]="{invalid: form.get('password').touched && form.get('password').invalid}"
    >
      <mat-form-field>
        <input matInput id='password' formControlName='password' type='password' placeholder='Пароль'>
      </mat-form-field>
      <div
        *ngIf="form.get('password').touched && form.get('password').invalid"
        class='validation'
      >
        <small *ngIf="form.get('password').errors.required">
          Пожалуйста введите пароль
        </small>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class='mat-form-field-flex'>
      <span style='flex: 1 1 auto'></span>
      <button
        type='submit'
        mat-raised-button
        color='warn'
        (click)='onNoClick()'
      >
        Отмена
      </button>
      <button
        type='submit'
        mat-raised-button
        color='primary'
        [disabled]='form.invalid'
      >
        Войти
      </button>
    </div>
  </div>
</form>
