import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

import { PromoService, PromoType, PromoTypeLabel } from 'src/app/services/promo.service';
import { SettingsService } from 'src/app/services/settings.service';
import { IPromo } from 'src/app/shared/interfaces';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MessageService } from 'ui-message-angular';

@Component({
  selector: 'app-promo-page',
  templateUrl: './promo-page.component.html',
  styleUrls: ['./promo-page.component.scss']
})
export class PromoPageComponent extends BaseListPageComponent<IPromo> implements OnInit {
  displayedColumns = ['id', 'name', 'type', 'description', 'actions'];
  Type = PromoType
  DetailRoute: string = 'promoDetail';
  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private service: PromoService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
    
    );
    this.Title = "Промокоды";
  }

  protected getData() {
    return this.service.getAll();
  }


  getTypeName(value) {
    return PromoTypeLabel.get(value);
  }


}
