<div fxLayout="column" fxLayoutAlign='center left' fxLayoutGap="20px" class="chatUserInfoPanel">

    <div fxLayout="row" fxLayoutAlign='left center' style="font-weight:bolder;font-size: 1.2em;">
        <mat-icon >person</mat-icon>{{client?.name}}
        <span *ngIf="client?.gender"> ({{client?.gender}})</span>
    </div>
    <div fxLayout="row" fxLayoutAlign='left center' style="font-weight:bolder;">
        <mat-icon fxFlex="0 0 7%">phone</mat-icon>{{client?.phone_number | phone}} 
    </div>
    <div fxLayout="row" fxLayoutAlign='left center' >
        <mat-icon fxFlex="0 0 7%">email</mat-icon>{{client?.email}}
    </div>

    <div *ngIf ="client?.birth_date" fxLayout="row" fxLayoutAlign='left center' >
        <mat-icon fxFlex="0 0 7%"> perm_contact_calendar</mat-icon>{{client?.birth_date}}
    </div>



</div>