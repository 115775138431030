import {Component, Input, OnInit} from '@angular/core';
import ymaps from 'ymaps';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'app-ymaps-adres',
  templateUrl: './ymaps-adres.component.html',
  styleUrls: ['./ymaps-adres.component.scss']
})
export class YmapsAdresComponent implements OnInit {

  @Input() lat: number;
  @Input() lon: number;
  @Input() id: number;
  
  adresText: string;
  constructor(private settings: SettingsService) { }

  ngOnInit() {
    const adresText = localStorage.getItem('adres_' + this.id);
    if (!adresText) {
      // console.log('New record');
      ymaps
        //.load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU')
        .load(this.settings.getMapInitUrl())
        .then(maps => {
          maps.geocode([this.lat, this.lon]).then((res) => {
            const firstGeoObject = res.geoObjects.get(0);
            const allGeoData = firstGeoObject.properties.getAll();
            this.adresText = allGeoData.text;
            localStorage.setItem('adres_' + this.id, JSON.stringify({ id : this.id, lat: this.lat, lon: this.lon, text: allGeoData.text }));
          });
        });


    } else {
      this.adresText = JSON.parse(adresText)['text'];
    }
  }

}
