import { Component, OnInit, Input } from '@angular/core';
import { IOrderStatistic } from '../shared/interfaces';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit {
  @Input() order: IOrderStatistic;
  constructor() { }

  ngOnInit() {

  }
  statusOrder = [
    "Отменен сервисом","Отменен клиентом","Неизвестен","Ожидает оплаты", "Новый", "Назначен", "В пути", "Доставлен", "Завершен"
  ];
  getStatusOrder(status: number) {
    return this.statusOrder[status+4];
  }


}
