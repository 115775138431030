<div *ngIf='entityForm' [formGroup]='entityForm' (ngSubmit)='onSave(null)' autocomplete='off'>
  <div class='container-dlg'>
    <mat-card>
      <div class='top-bar'>
        <mat-card-title>
          <div class='top-bar-title'>
            {{isNewMode ? 'Новый пользователь' : readonly ? 'Карточка пользователя ' : 'Редактирование пользователя '}}
            <button class='btn btn-lg btn-outline-primary mr-2' type='button' title='Edit/Display'
                    id='editDisplay' (click)='switchEditDisplay()'>
              <span *ngIf='readonly' class='fas fa-edit'> Редактирование</span>
              <span *ngIf='!readonly' class='fas fa-glasses'> Просмотр</span>
            </button>
          </div>
        </mat-card-title>
      </div>
      <div class='form-content' fxLayout='row'>
        <div fxLayout='row' fxLayout.xs='row' fxLayoutWrap fxLayoutGap='10%' fxFlex='1 1 auto'>
          <div fxLayout='column' fxFlex>
            <div fxLayout='row' fxFlex>
              <mat-form-field fxLayoutGap='10%' fxFlex='75%'>
                <mat-label>Имя</mat-label>
                <input matInput id='name' formControlName='name' placeholder='Имя'>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Тип</mat-label>
                <mat-select placeholder='Тип' matInput id='user_type' formControlName='user_type'>
                  <mat-option *ngFor='let item of userTypes | enumLabelToArray' [value]='item.key'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-form-field>
              <mat-label>EMail</mat-label>
              <input matInput id='email' formControlName='email' placeholder='EMail'>
            </mat-form-field>
          </div>
          <div fxFlex='15%' fxLayout='column'>
            <div>
              <input type='checkbox' id='is_active' value='is_active' formControlName='is_active' />
              <label for='is_active' class='form-check-label'>Разрешен</label>
            </div>
            <div fxLayout='column'>
              <mat-form-field>
                <mat-label>Логин</mat-label>
                <input matInput id='login' formControlName='login' placeholder='Логин'>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Пароль</mat-label>
                <input matInput type='password' id='password' formControlName='password'
                       placeholder='Пароль'>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class='additional-panel' fxLayout='column' fxLayoutGap='10%' fxFlex='1 1 auto'
             fxLayoutAlign='top center'>
          <div>
            <label>Роли </label>
            <div class='checkbox-group'>
              <div *ngFor='let item of allRoles; let i = index' class='checkbox'>
                <label><input type='checkbox' [formControl]='rolesFormGroup?.controls[item.id]' />
                  {{ allRoles[i]?.title }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='row-buttons'>
        <span *ngIf='!isNewMode' class='id-field'>Код: {{id}}</span>
        <span class='spacer'></span>
        <a [routerLink]='getSubmitRoute()' class='form-btn' mat-button>Назад</a>
        <button type='submit' class='form-btn' mat-raised-button color='accent'
                [disabled]='readonly' (click)='onSave(null)'>Сохранить
        </button>
      </div>
    </mat-card>
  </div>
</div>
