
<div fxLayout="row"  class="orderInfoContainer"
[ngClass]="{'isActive':order.order_status>2 && order.order_status!>6}">
    <div fxFlex ="0 0 15%" class="orderInfoData">
            <span class="orderId">{{ order.id }}</span>
            <span class="timestamp">{{ order.created_at }}</span>
    </div>
    <div fxFlex  class="orderInfoContent">
            <span class="orderStatus">Статус:{{ statusOrder[order.order_status +2]}}</span>
            <!--<span class="orderSumm">Сумма:{{ order.order_summ }}</span>-->
            <span class="timestamp">Дата доставки:{{ order.date_delivery }}</span>
    </div>
</div>