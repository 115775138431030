import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, Provider, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './main/main-layout/main-layout.component';
import { HomePageComponent } from './pages/home-page/home-page.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullMaterialModule } from './material-modules';
import { AngularYandexMapsModule } from 'angular8-yandex-maps';

import { OrderPageComponent } from './pages/order-page/order-page.component';
import { DriverPageComponent } from './pages/driver-page/driver-page.component';
import { SettingPageComponent } from './pages/setting-page/setting-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthIntercepter } from './shared/auth.intercepter';
import { DatePipe, registerLocaleData } from '@angular/common';
import ruLocale from '@angular/common/locales/ru';
import { CookieService } from 'ngx-cookie-service';
import { TableModule } from 'ngx-easy-table';
import { TableModule as PTableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';


import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { WtDialogComponent } from './wt-dialog/wt-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SvgComponentComponent } from './svg-component/svg-component.component';
import { ProductComponent } from './product/product.component';
import { MapjsPageComponent } from './pages/mapjs-page/mapjs-page.component';
import { YmapsAdresComponent } from './ymaps-adres/ymaps-adres.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ChatroomPageComponent } from './pages/chatroom-page/chatroom-page.component';
import { ChatFormComponent } from './chat/chat-form/chat-form.component';
import { ChatUserListComponent } from './chat/chat-user-list/chat-user-list.component';
import { ChatFeedComponent } from './chat/chat-feed/chat-feed.component';
import { ChatMessageComponent } from './chat/chat-message/chat-message.component';
import { ChatUserItemComponent } from './chat/chat-user-item/chat-user-item.component';

import { ChatService } from './services/chat.service';

import { ClientOrderListComponent } from './client/client-order-list/client-order-list.component';
import { ClientInfoComponent } from './client/client-info/client-info.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { FilterPipe } from './pipes/filter.pipe';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { FooterComponent } from './main/footer/footer.component';
import { HeaderComponent } from './main/header/header.component';

import { OrderDetailsPopupComponent } from './chat/chat-popup/chat-popup.component';

import { ProductService } from './services/product.service';
import { OrdersService } from './services/orders.service';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { SettingsService } from './services/settings.service';


import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OrderDetailsComponent } from './order-details/order-details.component';

import { CreateOrderDialogComponent } from './dialogs/create-order-dialog/create-order-dialog.component';
import { DriverCreateDialogComponent } from './dialogs/driver-create-dialog/driver-create-dialog.component';
import { AssignDriverDialogComponent } from './dialogs/assign-driver-dialog/assign-driver-dialog.component';
import { CompleteOrderDialogComponent } from './dialogs/complete-order-dialog/complete-order-dialog.component';
import { ExportBuhDialogComponent } from './dialogs/export-buh-dialog/export-buh-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmOrderDialogComponent } from './dialogs/confirm-order-dialog/confirm-order-dialog.component';
import { OrderBaseComponent } from './base/order-base/order-base.component';
import { ClientsPageComponent } from './pages/clients-page/clients-page.component';
import { FirmDialogComponent } from './dialogs/firm-dialog/firm-dialog.component';
import { BaseListPageComponent } from './base/base-list-page/base-list-page.component';
import { MaxCharPipe } from './pipes/pipes.pipe';
import { DialogBaseComponent } from './base/dialog-base/dialog-base.component';
import { DialogEntityBaseComponent } from './base/dialog-entity-base/dialog-entity-base.component';
import { ClientVehicleListComponent } from './client/client-vehicle-list/client-vehicle-list.component';
import { ClientPriceListComponent } from './client/client-price-list/client-price-list.component';
import { ProductDialogComponent } from './dialogs/product-dialog/product-dialog.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';

import { MessageModule } from 'ui-message-angular';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { AccountDialogComponent } from './dialogs/account-dialog/account-dialog.component';
import { BoostersPageComponent } from './pages/boosters-page/boosters-page.component';
import { BoosterDialogComponent } from './dialogs/booster-dialog/booster-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReportPageComponent } from './pages/report-page/report-page.component';
import { ReportDialogComponent } from './dialogs/report-dialog/report-dialog.component';
import { ReportUniversalComponent } from './report-universal/report-universal.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { EnumLabelToArrayPipe } from './pipes/enum-label-to-array.pipe';
import { OrderCardComponent } from './templates/order-card/order-card.component';
import { PhonePipe } from './pipes/phone.pipe';
import { PromoPageComponent } from './pages/promo-page/promo-page.component';
import { PromoDialogComponent } from './dialogs/promo-dialog/promo-dialog.component';

import { BuyersPageComponent } from './pages/buyers-page/buyers-page.component';
import { BuyersDialogComponent } from './dialogs/buyers-dialog/buyers-dialog.component';
import { SystemLoadInfoComponent } from './system-load-info/system-load-info.component';
import { ModelsPageComponent } from './pages/models-page/models-page.component';
import { ModelDialogComponent } from './dialogs/model-dialog/model-dialog.component';
import { DropdownModule } from 'primeng/dropdown';
import { ChangeAddressDialogComponent } from './dialogs/change-address-dialog/change-address-dialog.component';
import { DateAdapter } from '@angular/material';
import { CustomDateAdapter } from './validators/CustomDateAdapter';

import { ColorPickerModule } from 'ngx-color-picker';

import { CreateOrderBuyersDialogComponent } from './dialogs/create-order-buyers-dialog/create-order-buyers-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CalendarModule } from 'primeng/calendar';
import { SpinnerComponent } from './base/spinner/spinner.component';


const maskConfig: Partial<IConfig> = {
  validation: false
};
registerLocaleData(ruLocale, 'ru');
// registerLocaleData(enLocale, 'en');

const INTERCEPTER_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  multi: true,
  useClass: AuthIntercepter
};
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
const appInitializerFn = (appConfig: SettingsService) => {
  return () => {
    return appConfig.loadAppSettings();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HomePageComponent,
    LoginDialogComponent,

    OrderPageComponent,
    DriverPageComponent,
    SettingPageComponent,
    DriverCreateDialogComponent,
    AssignDriverDialogComponent,
    ConfirmDialogComponent,
    WtDialogComponent,
    SvgComponentComponent,
    ProductComponent,
    MapjsPageComponent,
    YmapsAdresComponent,
    ChatFormComponent,
    ChatroomPageComponent,
    ChatUserListComponent,
    ChatFeedComponent,
    ChatMessageComponent,
    ChatUserItemComponent,

    ClientOrderListComponent,
    ClientInfoComponent,
    OrderInfoComponent,
    FilterPipe,
    EnumToArrayPipe,
    EnumLabelToArrayPipe,
    StatisticsPageComponent,
    FooterComponent,
    HeaderComponent,
    ConfirmationDialogComponent,
    OrderDetailsPopupComponent,

    ProductDetailComponent,

    ConfirmOrderDialogComponent,
    CompleteOrderDialogComponent,
    MessageDialogComponent,
    ExportBuhDialogComponent,
    OrderDetailsComponent,
    OrderBaseComponent,
    BaseListPageComponent,
    CreateOrderDialogComponent,
    CreateOrderBuyersDialogComponent,
    ChangeAddressDialogComponent,
    ClientsPageComponent,
    FirmDialogComponent,
    MaxCharPipe,
    DialogBaseComponent,
    DialogEntityBaseComponent,

    ClientVehicleListComponent,
    ClientPriceListComponent,
    ProductDialogComponent,
    ProductsPageComponent,
    AccountsPageComponent,
    AccountDialogComponent,
    BoostersPageComponent,
    BoosterDialogComponent,
    ModelsPageComponent,
    ModelDialogComponent,
    ReportPageComponent,
    ReportDialogComponent,
    ReportUniversalComponent,
    EnumToArrayPipe,
    EnumLabelToArrayPipe,
    OrderCardComponent,
    PhonePipe,
    PromoPageComponent,
    PromoDialogComponent,

    BuyersPageComponent,

    BuyersDialogComponent,

    SystemLoadInfoComponent,

    SpinnerComponent

  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // AngularYandexMapsModule.forRoot('1c3657c9-3885-4005-868c-063127aa40f4'),
    NotifierModule.withConfig(customNotifierOptions),
    AngularYandexMapsModule.forRoot(null),
    FullMaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    PTableModule,
    MultiSelectModule,
    FormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgxMaterialTimepickerModule.setLocale('ru-RU'),
    NgxMaskModule.forRoot(maskConfig),
    MessageModule,
    DropdownModule,
    ColorPickerModule,
    NgxSpinnerModule,
    CalendarModule

  ],
  providers: [Title, DatePipe, INTERCEPTER_PROVIDER, CookieService, {
    provide: LOCALE_ID,
    useValue: 'ru-RU'
  }, ChatService, ProductService, OrdersService,
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [SettingsService]
    },
    { provide: DateAdapter, useClass: CustomDateAdapter }
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginDialogComponent, DriverCreateDialogComponent, AssignDriverDialogComponent, CompleteOrderDialogComponent,
    ConfirmDialogComponent, WtDialogComponent, OrderDetailsPopupComponent, MessageDialogComponent, ExportBuhDialogComponent, OrderDetailsComponent,
    CreateOrderDialogComponent, CreateOrderBuyersDialogComponent, ChangeAddressDialogComponent, FirmDialogComponent, ProductDialogComponent, ReportDialogComponent]
})

export class AppModule { }
