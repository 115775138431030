import { Component, OnInit, Input } from '@angular/core';
import { ChatUser } from 'src/app/models/chat-user.model';
import { IClientInfo } from 'src/app/shared/interfaces';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {
  client: IClientInfo;
  constructor(private chat: ChatService) { 
  }

  ngOnInit() {
    this.chat.currentUser.subscribe(client => {
      if (client)
        this.client = client.data as IClientInfo;
      
    })

  }

}
