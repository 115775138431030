import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'maxChar' })
export class MaxCharPipe implements PipeTransform {

  transform(value: string, limit: number): string {
    let res = value;
    if (res)
    {
      res = value.substr(0, limit).trim()
      if ( value.trim().length!=res.length)
        res = res + '...';
    }
      return  res
  }

}
