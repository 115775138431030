<!--<div class="main-container">-->

<!--  <button mat-mini-fab color="primary">-->
<!--    <i class="material-icons href-cursor">person</i>-->
<!--  </button>-->

<!--</div>-->

<div class='main-container'>
  <app-header ui-view='header'></app-header>
  <div fxLayout='row' fxLayoutAlign='left center' *ngIf='auth.isAuthenticated()'>

    <button mat-icon-button (click)='snav.toggle()'>
      <mat-icon>menu</mat-icon>
    </button>


    <nav mat-tab-nav-bar fxFlex='1 1 auto'>

      <a mat-tab-link *ngFor='let link of navLinks' [routerLink]='link.link' routerLinkActive #rla='routerLinkActive'
         [active]='rla.isActive'>
        <mat-icon>{{link.icon}}</mat-icon>
        <span [matBadge]='setLinksBadge(link)'
              matBadgeOverlap='false' matBadgeColor='warn'>{{link.label}}</span>
      </a>
    </nav>
    <app-system-load-info fxFlex='1 1 70px' fxLayoutAlign='right center'></app-system-load-info>
  </div>
  <mat-sidenav-container class='sidenav-container'>

    <!--<mat-sidenav class='main-sidenav' #snav fixedTopGap='56' mode='over'>-->
    <mat-sidenav class='main-sidenav' #snav fixedTopGap='56' mode='over'>

      <mat-nav-list>


        <!--<li *ngIf="auth.isAuthenticated()" (click)="snav.close()"><a mat-list-item [routerLink]="['/','map'] ">Карта</a>
        </li>
        <li *ngIf="auth.isAuthenticated()" (click)="snav.close()"><a mat-list-item
            [routerLink]="['/','orders']">Заказы</a></li>
        <li *ngIf="auth.isAuthenticated()" (click)="snav.close()"><a mat-list-item [routerLink]="['/','chats']">Чаты</a>
        </li>
        <mat-divider></mat-divider>
      -->
        <li *ngIf="auth.isRole(['ClientManager'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','clients']">
            <mat-icon class='menu-item-icon'>contact_page</mat-icon>
            Юр. лица
          </a></li>

        <li (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','buyers']">
            <mat-icon class='menu-item-icon'>contact_page</mat-icon>
            Физ. лица
          </a></li>
        <mat-divider></mat-divider>

        <li *ngIf="auth.isRole(['Analitycs'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','statistics']">
            <mat-icon class='menu-item-icon'>analytics</mat-icon>
            Статистика
          </a></li>
        <mat-divider></mat-divider>
        <li *ngIf="auth.isRole(['Admin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','boosters']">
            <mat-icon class='menu-item-icon'>local_shipping</mat-icon>
            Машины
          </a></li>
        <li *ngIf="auth.isRole(['Admin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','models']">
            <mat-icon class='menu-item-icon'>local_taxi</mat-icon>
            Модели
          </a></li>
        <li *ngIf="auth.isRole(['Admin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','products']">
            <mat-icon class='menu-item-icon'>dns</mat-icon>
            Продукты
          </a></li>
        <li *ngIf="auth.isRole(['Admin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','accounts']">
            <mat-icon class='menu-item-icon'>supervisor_account</mat-icon>
            Пользователи
          </a></li>
        <li *ngIf="auth.isRole(['SuperAdmin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','reports']">
            <mat-icon class='menu-item-icon'>print</mat-icon>
            Отчеты
          </a></li>
        <mat-divider></mat-divider>

        <li *ngIf="auth.isRole(['SuperAdmin'])" (click)='snav.close()'>
          <a mat-list-item [routerLink]="['/','settings']">
            <mat-icon class='menu-item-icon'>admin_panel_settings</mat-icon>
            Настройки
          </a></li>

      </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content class='child-container'>
      <notifier-container></notifier-container>

      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>
  <!-- footer -->
  <app-footer class='flex-column' ui-view='footer'></app-footer>
</div>
