import { Component, OnInit } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-chat-form',
  templateUrl: './chat-form.component.html',
  styleUrls: ['./chat-form.component.scss']
})
export class ChatFormComponent implements OnInit {
  message: string;

  constructor(
    private chat: ChatService,
    public auth: AuthService) {
  }

  ngOnInit() {
  }

  send() {
    this.chat.sendMessage(this.message);
    this.message = '';
  }

  handleSubmit(event) {
    if (this.auth.isVisible('chat', 'send')) {
      if (event.ctrlKey && event.keyCode === 13 && this.message && this.message.length > 0) {
        this.send();
      }
    }
  }
}
