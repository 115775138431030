import { Component, Injectable, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ChatService } from '../../services/chat.service';
import { ApiV5Service } from '../../shared/services/apiV5.service';
import { OrdersService } from '../../services/orders.service';

// @Injectable({
//   providedIn: 'root'
// })
// export class NewOrdersService {
//   private newOrders = new BehaviorSubject([]);
//
//   getNewOrders(): Observable<any> {
//     return this.newOrders;
//   }
//
//   setNewOrders(state): void {
//     this.newOrders.next(state);
//   }
// }

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnChanges, OnDestroy {

  navLinks: any[];
  activeLinkIndex = -1;
  chatUsersTime: NodeJS.Timeout;
  chatUsersSub: Subscription;
  newMessagesInfo = { msgCount: 0, usrCount: 0 };
  newOrders;

  constructor(
    private apiV5Service: ApiV5Service,
    public auth: AuthService,
    private router: Router,
    private chat: ChatService,
    private ordersService: OrdersService
    // public newOrdersService: NewOrdersService
  ) {

    this.navLinks = [
      {
        icon: 'add_location',
        label: 'Карта',
        link: '/map',
        index: 0
      }, {
        icon: 'assignment',
        label: 'Заказы',
        link: '/orders',
        index: 1
      }, {
        icon: 'chat',
        label: 'Чаты',
        link: '/chats',
        index: 2
      }
    ];

  }

  ngOnInit() {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });

    this.router.navigate(['/']);

    this.dataRefresh();
    this.chatUsersTime = setInterval(() => {
      this.dataRefresh();
    }, 15 * 1000);
  }

  ngOnChanges() {
    this.dataRefresh();
  }

  ngOnDestroy(): void {
    if (this.chatUsersSub) {
      this.chatUsersSub.unsubscribe();
    }
    clearInterval(this.chatUsersTime);
  }

  dataRefresh() {
    this.chatUsersSub = this.chat.getUsers().subscribe(users => {
      const usr = users;
      usr.forEach(element => {
        element.data = JSON.parse(element.info);
      });
      const msgCount = usr.reduce((acc, cur) => acc + cur.newMessagesCnt, 0);
      const usrCount = usr.filter(u => u.newMessagesCnt > 0).length; // reduce((acc,cur) => acc + Number(cur.newMessagesCnt));
      if (this.newMessagesInfo.msgCount < msgCount) {
        this.playAudio('new_msg');
      }
      this.newMessagesInfo = { msgCount, usrCount };
    });
    if (!this.newOrders) {
      this.ordersService.getOrdersFind().subscribe(dataI => {
        let orders = dataI.filter(x => x.order_status === 2 && x.night_time === 0);
        orders = this.ordersService.filterOrdersForSuperAdmin(orders);
        this.playNotification(orders);
        this.newOrders = orders;
      });
    }
    // else {
    //   this.newOrdersService.getNewOrders().subscribe(data => {
    //     let orders = data ? data.find(d => d.statusId === 2) : null;
    //     orders = orders ? orders.items : [];
    //     this.playNotification(orders);
    //     console.log('subscribe', orders);
    //     this.newOrders = orders;
    //   });
    // }
  }

  setLinksBadge(link) {
    return '';
    switch (link.index) {
      case 0:
        return this.newOrders?.length ? this.newOrders.length.toString() : '';
      case 2:
        return this.newMessagesInfo.msgCount ? this.newMessagesInfo.msgCount.toString() : '';
      default:
        return '';
    }
  }

  playNotification(orders) {
    return;
    if (this.newOrders) {
      const difference = this.newOrders.filter(x => orders.indexOf(x) === -1);
      if (this.newOrders.length < orders.length && difference) {
        this.playAudio('new_order');
      }
    }
  }

  playAudio(type: string) {
    const audio = new Audio();
    audio.src = type === 'new_msg' ? '../../../assets/new-msg-sound.mp3' : '../../../assets/new-order-sound.mp3';
    audio.load();
    audio.play().then(_ => {
      console.log('Autoplay started!');
    }).catch(error => {
      console.log(error);
    });
  }
}
