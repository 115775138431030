import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../services/report.service';
import { MatSelectChange } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { BoostersService } from '../services/boosters.service';
import { ProductService } from '../services/product.service';
import { AccountsService } from '../services/accounts.service';
import { IObjectAny, IReport, IReportDataTable } from '../shared/interfaces';
import { LazyLoadEvent } from 'primeng/api';
import { ExcelService } from '../shared/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-report-universal',
  templateUrl: './report-universal.component.html',
  styleUrls: ['./report-universal.component.scss']
})

export class ReportUniversalComponent implements OnInit {
  Title: string;
  dataSource: IReportDataTable[];
  // dataSourceFiltered: IReportDataTable[];
  page = 0;
  pageSize = 10;
  // totalDataLength = 100;
  columns = [];
  selectedColumns = [];
  settings: IObjectAny = {};
  reports = [];
  selectedReport: IReport | IObjectAny = {};
  boosters = [];
  selectedBoosters = [];
  products = [];
  selectedProducts = [];
  drivers = [];
  selectedDrivers = [];
  startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  stopDate = new Date();

  constructor(
    private service: ReportsService,
    private boostersService: BoostersService,
    private productsService: ProductService,
    private accountsService: AccountsService,
    public sanitizationService: DomSanitizer,
    public excelService: ExcelService,
    private SpinnerService: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.openReport();
  }

  save_as_excel() {
    const data = this.dataSource.map(report => {
      const result = {};
      this.selectedColumns.forEach(column => {
        result[column.header] = report[column.field];
      });
      return result;
    });
    this.excelService.exportAsExcelFile(data, this.selectedReport.name);
  }

  selectedValue(event: MatSelectChange) {
    this.selectedReport = event.value;
    this.settings = this.selectedReport.settings;
    this.refreshData();
  }

  openReport() {
    this.SpinnerService.show();
    this.service.managerReports().subscribe(data => {
      const reports = data.list.map(report => {
        return {
          ...report,
          settings: JSON.parse(report.settings)
        };
      });
      this.reports = reports.filter(report => report.is_active);
      this.selectedReport = this.reports.length ? this.reports[0] : {};
      this.settings = this.selectedReport.settings;
      this.SpinnerService.hide();
    });

    this.boostersService.getAllBoosters().subscribe(data => {
      this.boosters = data.list;
      this.selectedBoosters = data.list;
    });

    this.productsService.getProducts().subscribe(data => {
      this.products = data.products;
      this.selectedProducts = data.products;
    });

    this.accountsService.getAll().subscribe(data => {
      const list = data.list.filter(driver => driver.user_type === 2);
      this.drivers = list;
      this.selectedDrivers = list;
    });
    this.refreshData();
  }

  handleRefreshData() {
    this.page = 0;
    this.refreshData();
  }

  /** НЕ УДАЛЯТЬ Lazy Load*/

  refreshData(sortField = '', sortOrder = 0) {
    const boosters = this.selectedBoosters.map(booster => booster.id);
    const products = this.selectedProducts.map(product => product.id);
    const drivers = this.selectedDrivers.map(driver => driver.id);
    this.SpinnerService.show();
    this.service.executeReport(this.selectedReport.id, this.settings, this.startDate, this.stopDate,
      boosters, products, drivers, this.page + 1, this.pageSize)
      .subscribe(d => {
        this.dataSource = d.data;
        // this.dataSource = d.data ? d.data.sort((a, b) => this.compareField(a, b, sortField) * sortOrder) : [];
        // this.dataSourceFiltered = this.dataSource;
        if (d.columns) {
          const columns = d.columns.map((col, index) => {
            return { ...col, index };
          });
          this.columns = columns;
          this.selectedColumns = columns;
        }
        this.SpinnerService.hide();
      });
  }

  // compareField(rowA, rowB, field: string): number {
  //   if (rowA[field] == null) {
  //     return 1;
  //   }
  //   if (typeof rowA[field] === 'string') {
  //     return rowA[field].localeCompare(rowB[field]);
  //   }
  //   if (typeof rowA[field] === 'number') {
  //     if (rowA[field] > rowB[field]) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   }
  // }

  // filterByField(value: string, field: string) {
  //   this.dataSourceFiltered = this.dataSource.filter((row: any) => row[field].toUpperCase().includes(value.toUpperCase()));
  // }

  // loadDataLazy(event: LazyLoadEvent) {
  //   this.pageSize = event.rows;
  //   this.page = (event.first + this.pageSize) / this.pageSize - 1;
  //   this.refreshData(event.sortField, event.sortOrder);
  // }

  selectedColumnsChange(columns) {
    columns.sort((a, b) => a.index - b.index);
    this.selectedColumns = columns;
  }

  selectedColumnsSearchable(columns) {
    const data = columns.filter(col => col.search === undefined ? true : col.search);
    return !!data.length;
  }
}
