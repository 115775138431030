import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IDrivers, IOrder } from '../../shared/interfaces';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BoostersService } from '../../services/boosters.service';
import { SettingsService, AutoAssignmentStatus } from 'src/app/services/settings.service';

@Component({
  selector: 'app-assign-driver-dialog',
  templateUrl: './assign-driver-dialog.component.html',
  styleUrls: ['./assign-driver-dialog.component.scss']
})
export class AssignDriverDialogComponent implements OnInit, OnDestroy {
  order: IOrder;
  drivers: IDrivers[] = [];
  sSub: Subscription;
  auto_assign_info: any;
  auto_assign_booster: IDrivers;

  constructor(
    private boosters: BoostersService,
    private settings: SettingsService,
    public dialogRef: MatDialogRef<AssignDriverDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.order = data.order;
  }

  ngOnInit() {
    this.sSub = this.boosters.getAll().subscribe(data => {
      this.drivers = data.boosters;
      if (this.settings.getSettings().autoAssignment.status == AutoAssignmentStatus.Advice) {
        this.boosters.getAutoAssignedBooster(this.data.id).subscribe(result => {
          this.auto_assign_info = result.orders[0];
          console.log(result.orders[0].booster);
          this.auto_assign_booster = data.boosters.find(f => f.id == result.orders[0].booster_account);
        });
      }
    });

  }

  ngOnDestroy(): void {
    if (this.sSub) {
      this.sSub.unsubscribe();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(id: number) {
    // console.log(id);
    this.data.id = id;
    this.dialogRef.close();
  }

  isCallMeNow() {
    const additional_options = this.order.additional_options;
    const additionalOptions = additional_options ? JSON.parse(additional_options) : null;
    return additionalOptions ? additionalOptions.callMeNow : false;
  }
}
