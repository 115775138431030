import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDialogData } from '../../shared/interfaces';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ConfirmOrderDialogComponent } from '../confirm-order-dialog/confirm-order-dialog.component';

@Component({
  selector: 'app-complete-order-dialog',
  templateUrl: './complete-order-dialog.component.html',
  styleUrls: ['./complete-order-dialog.component.scss']
})
export class CompleteOrderDialogComponent implements OnInit {
  form: FormGroup;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) { }

  ngOnInit() {
    this.form =this.fb.group({
      value: new FormControl(this.data.value, Validators.nullValidator),
      });

  }

  
  onClick() {
    this.dialogRef.close(this.form.value);
  }
  onNoClick() {
    this.dialogRef.close();
  }
}
