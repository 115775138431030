<div class='container'>
  <!--  <div class="card card-stats">-->
  <!--    <div class="card-header card-header-warning card-header-icon">-->
  <!--      <div class="card-icon">-->
  <!--        <i class="material-icons">content_copy</i>-->
  <!--      </div>-->
  <!--      <p class="card-category">Used Space</p>-->
  <!--      <h3 class="card-title">49/50-->
  <!--        <small>GB</small>-->
  <!--      </h3>-->
  <!--    </div>-->
  <!--    <div class="card-footer">-->
  <!--      <div class="stats">-->
  <!--        <i class="material-icons text-danger">warning</i>-->
  <!--        <a href="javascript:void(0)">Get More Space...</a>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--<div style="display: flex; justify-content: center; align-items: center; flex-wrap: wrap">-->
  <!--  <div style="margin: 15px 15px 15px 15px ;background-color: #00c853">-->
  <!--    <h3>Общее количество заказов:</h3>-->
  <!--    <span style="font-size: large">{{dataAll.length + 1}}</span>-->
  <!--  </div>-->
  <!--  <div style="margin: 15px 15px 15px 15px ;background-color: #00c853">-->
  <!--    <h3>Общее новых заказов:</h3>-->
  <!--    <span style="font-size: large">{{dataNew.length + 1}}</span>-->
  <!--  </div>-->
  <!--  <div style="margin: 15px 15px 15px 15px ;background-color: #00c853">-->
  <!--    <h3>Общее назначенных заказов:</h3>-->
  <!--    <span style="font-size: large">{{dataAssign.length + 1}}</span>-->
  <!--  </div>-->
  <!--  <div style="margin: 15px 15px 15px 15px ;background-color: #00c853">-->
  <!--    <h3>Общее отмененных заказов:</h3>-->
  <!--    <span style="font-size: large">{{dataCancel.length + 1}}</span>-->
  <!--  </div>-->
  <!--  <div style="margin: 15px 15px 15px 15px ;background-color: #00c853">-->
  <!--    <h3>Общее завершенных заказов:</h3>-->
  <!--    <span style="font-size: large">{{dataComplite.length + 1}}</span>-->
  <!--  </div>-->
  <!--</div>-->
  <div id='map'>
  </div>
</div>

