<div *ngIf='entityForm' [formGroup]='entityForm' (ngSubmit)='onSave(null)' autocomplete='off'>
  <div class='container-dlg'>
    <mat-card>
      <div class='top-bar'>
        <mat-card-title>
          <div class='top-bar-title'>
            {{isNewMode ? 'Новый товар' : readonly ? 'Карточка товара ' : 'Редактирование товара '}}

            <button class='btn btn-lg btn-outline-primary mr-2' type='button' title='Edit/Display'
                    id='editDisplay' (click)='switchEditDisplay()'>
              <span *ngIf='readonly' class='fas fa-edit'> Редактирование</span>
              <span *ngIf='!readonly' class='fas fa-glasses'> Просмотр</span>
            </button>
          </div>

        </mat-card-title>
      </div>
      <div class='form-content'>
        <div class='left-panel'>
          <div>
            <mat-form-field>
              <mat-label>Наименование</mat-label>
              <input matInput id='name' formControlName='name' placeholder='Наименование'>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Индекс</mat-label>
              <input matInput id='index' formControlName='index' placeholder='Индекс'>
            </mat-form-field>
          </div>
          <div class='additional-panel'>
            <div>
              <div>
                <mat-form-field>
                  <mat-label>Ед.изм.</mat-label>
                  <input matInput id='measure' formControlName='measure' placeholder='Ед.изм.'>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Код в 1С</mat-label>
                  <input matInput id='code_1c' formControlName='code_1c' placeholder='Код в 1С'>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <mat-label>Цена</mat-label>
                  <input matInput id='price' formControlName='price' placeholder='Цена'>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Ночная цена</mat-label>
                  <input matInput id='night_price' formControlName='night_price'
                         placeholder='Ночная цена'>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <mat-label>Min</mat-label>
                  <input matInput type='number' id='min_amount' formControlName='min_amount'
                         placeholder='Min'>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Max</mat-label>
                  <input matInput type='number' id='max_amount' formControlName='max_amount'
                         placeholder='Max'>
                </mat-form-field>
              </div>
            </div>
            <div>
              <div>
                <input type='checkbox' id='is_optional' value='is_optional'
                       formControlName='is_optional' />
                <label for='is_optional' class='form-check-label'>Доп. товар</label>
              </div>
              <div>
                <input type='checkbox' id='is_active' value='is_active' formControlName='is_active' />
                <label for='is_active' class='form-check-label'>Разрешен</label>
              </div>
              <div>
                <label for='mark_color' class='form-check-label'>Цвет</label>
                <input
                  id='mark_color'
                  [colorPicker]="entityForm.get('mark_color').value"
                  (colorPickerChange)="entityForm.get('mark_color').setValue($event)"
                  [style.background]="entityForm.get('mark_color').value"
                  formControlName='mark_color'
                  style='width: 70px'/>
              </div>
            </div>
          </div>
        </div>
        <div class='right-panel'>
          <mat-form-field class='product-description-field'>
            <mat-label>Описание</mat-label>
            <input class='product-description-control' matInput id='description'
                   formControlName='description' placeholder='Описание'>
          </mat-form-field>
        </div>

      </div>
      <div class='row-buttons'>


        <span *ngIf='!isNewMode' class='id-field'>Код: {{id}}</span>


        <span class='spacer'></span>
        <a [routerLink]='getSubmitRoute()' class='form-btn' mat-button>Назад</a>
        <button type='submit' class='form-btn' mat-raised-button color='accent' (click)='onSave(null)'
                [disabled]='readonly'>Сохранить
        </button>
      </div>

    </mat-card>
  </div>
</div>
