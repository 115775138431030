<div class="container">
    <div class="header">
        <h1>{{Title}}</h1>
        <span class="spacer"></span>
        <a *ngIf="allowAdd" mat-mini-fab color="accent" (click)="newItem()">
            <mat-icon aria-label="add">add</mat-icon>
        </a>
    </div>

    <div class="table-container mat-elevation-z2">
        <div class="table-header">
            <div fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="10px" style="height: 40%;">
                <div fxFlex="40%">
                    <mat-form-field floatLabel="auto" hideRequiredMarker 
                        style="width: 100%;">
                        <mat-label>Поиск</mat-label>
                        <input matInput type="text" [(ngModel)]="filterValue">
                    </mat-form-field>
                </div>
                <div fxFlex="10%" fxLayoutAlign="left center">
                    <button mat-flat-button color="accent" type="button" (click)="applyFilter()">Найти</button>
                </div>
            </div>
        </div>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef> Код </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Наименование </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.name | maxChar:25 }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> EMail </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.email | maxChar:25}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="phone_number">
                <mat-header-cell *matHeaderCellDef> Телефон </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.phone_number}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="gender">
                <mat-header-cell *matHeaderCellDef> Пол </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.gender}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="birth_date">
                <mat-header-cell *matHeaderCellDef> Дата рождения </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.birth_date | date}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="balance">
                <mat-header-cell *matHeaderCellDef> Баланс </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.balance}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="allow_cash" style="align-content: center;">
                <mat-header-cell *matHeaderCellDef> Оплата нал. </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox [checked]="element.allow_cash" [disabled]="true">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="orders_cnt">
                <mat-header-cell *matHeaderCellDef> Заказов </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.orders_cnt}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_order_date">
                <mat-header-cell *matHeaderCellDef> Последний заказ </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.last_order_date | date}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Действия </mat-header-cell>
                <mat-cell *matCellDef="let element">
                       <a mat-icon-button title="Просмотр" (click)="displayItem(element.id)">
                        <mat-icon color="primary">preview</mat-icon>
                    </a>
                
                    <a mat-icon-button title="Редактировать" (click)="editItem(element.id)">
                        <mat-icon color="primary">create</mat-icon>
                    </a>
                    <!--<a mat-icon-button title="Удалить" (click)="deleteItem(element.id)">
          <mat-icon color="primary">delete</mat-icon>
        </a>-->
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator showFirstLastButtons [pageSizeOptions]="[3, 5, 10]"></mat-paginator>
    </div>
</div>
<app-spinner></app-spinner>