<div *ngIf="entityForm" [formGroup]="entityForm" (ngSubmit)="onSave(null)" autocomplete="off">

    <div class="container">
        <mat-card>
            <div class="top-bar">
                <mat-card-title>
                    <div class="top-bar-title">
                        {{isNewMode? 'Новый отчет' : readonly? 'Карточка отчета ' : 'Редактирование отчета '}}

                        <button class="btn btn-lg btn-outline-primary mr-2" type="button" title="Edit/Display"
                            id="editDisplay" (click)="switchEditDisplay()">
                            <span *ngIf="readonly" class="fas fa-edit"> Редактирование</span>
                            <span *ngIf="!readonly" class="fas fa-glasses"> Просмотр</span>
                        </button>
                    </div>
                </mat-card-title>
            </div>
            <div class="form-content" fxLayout="row">
                <div fxLayout="row" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="10%" fxFlex="75%">
                    <div fxLayout="column" fxFlex>
                        <mat-form-field>
                            <mat-label>Имя</mat-label>
                            <input matInput id="name" formControlName="name" placeholder="Имя">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Категория</mat-label>
                            <input matInput id="category" formControlName="category" placeholder="Категория">
                        </mat-form-field>
                    </div>


                    <div fxFlex="15%" fxLayout="column">
                        <div>
                            <input type="checkbox" id="is_active" value="is_active" formControlName="is_active" />
                            <label for="is_active" class="form-check-label">Разрешен</label>
                        </div>
                        <div fxLayout="column">
                            <mat-form-field>
                                <mat-label>Тип</mat-label>
                                <input matInput id="type" formControlName="type" placeholder="Тип">
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Версия</mat-label>
                                <input matInput id="typversione" formControlName="version" placeholder="Версия">
                            </mat-form-field>

                        </div>
                    </div>
                    <mat-form-field>
                        <mat-label>Запрос</mat-label>
                        <input matInput id="query" formControlName="query" placeholder="Запрос">
                    </mat-form-field>
                </div>
            </div>

            <div class="row-buttons">


                <span *ngIf="!isNewMode" class="id-field">Код: {{id}}</span>


                <span class="spacer"></span>
                <a [routerLink]="getSubmitRoute()" class="form-btn" mat-button>Назад</a>
                <button type="submit" class="form-btn" mat-raised-button color="accent" (click)="onSave(null)"
                    [disabled]="readonly">Сохранить</button>
            </div>

        </mat-card>
    </div>
</div>