import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-dialog-base',
  templateUrl: './dialog-base.component.html',
  styleUrls: ['./dialog-base.component.scss']
})
export class DialogBaseComponent implements OnInit {

  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,

  ) { }
  protected getSubmitRoute(){
    return '/';
  }
  protected getCancelRoute(){
    return '/';
  }

  ngOnInit(): void {
  }
  public onSubmit(value) {
   return this.router.navigateByUrl(this.getSubmitRoute());
    
  }
  public onCancel() {
    return this.router.navigateByUrl(this.getCancelRoute());
   
    
  }

}
