import { Component, OnInit ,Input} from '@angular/core';
import { ChatUser } from '../../models/chat-user.model';
import { User } from 'src/app/shared/interfaces';
@Component({
  selector: 'app-chat-user-item',
  templateUrl: './chat-user-item.component.html',
  styleUrls: ['./chat-user-item.component.scss']
})
export class ChatUserItemComponent implements OnInit {
  
  @Input() user: ChatUser;

  constructor() { }

  ngOnInit() {
  }
 
}
