<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <div class="box">
    <div class="box-content"> <p> {{ data.text}}</p></div>

  </div>


<input [matDatepicker]="reestrDate" [(ngModel)]="data.reestrDate">
<mat-datepicker-toggle [for]="reestrDate">

</mat-datepicker-toggle>
<mat-datepicker #reestrDate></mat-datepicker>
<div class="progress-section">
  <mat-progress-bar *ngIf="progress>0" [mode]="progressMode" [value]="progress"></mat-progress-bar>  
</div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions-pane" align="end">

  <button
    type="submit"
    mat-raised-button
    color="primary"
    
    (click)="onRefreshReestrClick()"
  >
    Обновить реестр
  </button>

  <button
    type="submit"
    mat-raised-button
    color="primary"
    [disabled] = "reestrUpdate ? 'disabled': null" 
    (click)="onExportClick()"
  >
    Экспорт
  </button>

</mat-dialog-actions>




  
