import { Pipe, PipeTransform } from '@angular/core';
import { UserTypeLabel } from '../services/accounts.service';

@Pipe({
  name: 'enumLabelToArray'
})
export class EnumLabelToArrayPipe implements PipeTransform {
  transform(value): any {
    let keys = [];
    for (var enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({ key: parseInt(enumMember, 10), value:UserTypeLabel.get(parseInt(enumMember, 10)) });
      }
    }
    return keys;
  }

}
