import { Component, OnInit } from '@angular/core';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material';

import { Router } from '@angular/router';
import { MessageService } from 'ui-message-angular';
import { ModelsService } from '../../services/models.service';
import { IModel } from '../../shared/interfaces';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-models-page',
  templateUrl: './models-page.component.html',
  styleUrls: ['./models-page.component.scss']
})

export class ModelsPageComponent extends BaseListPageComponent<IModel> implements OnInit {
  displayedColumns = ['id', 'vendor', 'model', 'is_active', 'actions'];
  vendorsList = [];
  DetailRoute = 'modelsDetail';

  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private service: ModelsService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
    
    );

    this.Title = 'Модели';
  }

  protected getData() {
    const data = this.service.getAllModels();
    data.subscribe(models => {
      this.vendorsList = [...new Set(models.list.map(obj => obj.vendor))];
    });
    return data;
  }
}
