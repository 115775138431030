import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';
import { IBuyer } from 'src/app/shared/interfaces';
import { ClientsService } from 'src/app/services/clients.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';

@Component({
  selector: 'app-buyers-dialog',
  templateUrl: './buyers-dialog.component.html',
  styleUrls: ['./buyers-dialog.component.scss']
})
export class BuyersDialogComponent extends DialogEntityBaseComponent<IBuyer> implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: ClientsService
  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }

  public getSubmitRoute(): string {
    return '/buyers';
  }

  public getCancelRoute(): string {
    return '/buyers';
  }

  protected getData(id: number) {
    return this.service.getClient(id);
  }

  protected saveData(Entity: IBuyer) {
    return this.service.saveClient(Entity);
  }

  protected newEntity() {
    const item = super.newEntity() as IBuyer;

    item.name = '';
    item.phone_number = '';
    item.email = '';
    item.allow_cash = true;
    item.balance = 0;
    item.gender = '';
    item.link = '';
    return item;
  }

  protected _generateUserForm(data: IBuyer): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      email: [data.email],
      phone_number: [data.phone_number, [Validators.required]],
      allow_cash: [data.allow_cash],
      balance: [data.balance],
      gender: [data.gender],
      link: [data.link]
    });
    super._generateUserForm(data);

  }

  protected _composeChanges() {

    const result = super._composeChanges();
    if (result) {
      this.Entity = this.entityForm.value;
    }
    return result;
  }

  protected _setCheckBoxState() {

    if (this.readonly) {

      this.entityForm.get('name').disable();
      this.entityForm.get('phone_number').disable();
      this.entityForm.get('email').disable();
      this.entityForm.get('allow_cash').disable();
      this.entityForm.get('balance').disable();
      this.entityForm.get('gender').disable();
      this.entityForm.get('link').disable();

    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('phone_number').enable();
      this.entityForm.get('email').enable();
      this.entityForm.get('allow_cash').enable();
      this.entityForm.get('balance').enable();
      this.entityForm.get('gender').enable();
      this.entityForm.get('link').enable();
    }

  }

}
