<div class="messageContainer" 
    [ngClass]="{'isOwnMessageContainer':!isClient}">
    <div class="messageData" 
        [ngClass]="{'isOwnMessageData':!isClient}">
        <span class="sender" 
            [ngClass]="{'isOwnSender':!isClient}">
            {{ userName }}
        </span>
        <span class="timestamp" 
            [ngClass] = "{'isOwnTimestamp':!isClient}">
            {{ timeStamp }}
        </span>
    </div>
    <div class="messageContent"
        [ngClass]="{'isOwnMessageContent':!isClient}">
        {{ messageContent }}
        
    </div>
    
    <img class ="readImage" 
        [ngClass]="{'isReaded':isReaded}" 
        src="assets/icons/checked.png" />
</div>