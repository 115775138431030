import { Component, OnInit } from '@angular/core';
import { DialogBaseComponent } from 'src/app/base/dialog-base/dialog-base.component';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';
import { IFirm } from 'src/app/shared/interfaces';
import { ClientsService } from 'src/app/services/clients.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';

@Component({
  selector: 'app-firm-dialog',
  templateUrl: './firm-dialog.component.html',
  styleUrls: ['./firm-dialog.component.scss']
})
export class FirmDialogComponent extends DialogEntityBaseComponent<IFirm> implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: ClientsService

  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }
  public getSubmitRoute(): string {
    return '/clients';
  }
  public getCancelRoute(): string {
    return '/clients';
  }
  protected getData(id: number) {
    return this.service.getFirm(id)
  }

  protected saveData(Entity: IFirm) {
    return this.service.saveFirm(Entity)
  }

  protected newEntity(){
    var item = <IFirm> super.newEntity();
    
    item.name ='';
    item.phone_number = ''; 
    item.email = '';
    
    return item;
  }
  protected _generateUserForm(data: IFirm): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      email: [data.email],
      phone_number: [data.phone_number,[Validators.required]],
    });
    super._generateUserForm(data);
    
  }
  protected _composeChanges(){
    
     let result = super._composeChanges();
     if (result){
       this.Entity = this.entityForm.value;
     }
     return result;
  }
  protected _setCheckBoxState() {

    if (this.readonly) {
      
      this.entityForm.get('name').disable();
      this.entityForm.get('phone_number').disable();
      this.entityForm.get('email').disable();
      
    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('phone_number').enable();
      this.entityForm.get('email').enable();
    }

  }

}
