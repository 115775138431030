import { Component, OnInit } from '@angular/core';
import { IProduct, IVehicle, IClientInfo } from 'src/app/shared/interfaces';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { MatDialog } from '@angular/material';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClientsService } from 'src/app/services/clients.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { MessageService } from 'ui-message-angular';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-client-vehicle-list',
  templateUrl: './client-vehicle-list.component.html',
  styleUrls: ['./client-vehicle-list.component.scss']
})
export class ClientVehicleListComponent extends BaseListPageComponent<IVehicle> implements OnInit {
  displayedColumns = ['id', 'name', 'actions'];
  client: IClientInfo;

  constructor(
    protected dialog: MatDialog,
     protected settings:SettingsService,
     protected auth: AuthService,
     private clientsService: ClientsService,
     protected messageService: MessageService,
     protected router: Router,
     
  ) {
    super(
      dialog,
      settings,      
      auth,
      router,
      messageService,
     
    );
  }
  
  protected getData() {
    if (this.client)
      return this.clientsService.getCars(this.client.id);
  }
 
}
