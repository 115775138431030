<div mat-dialog-content>

  <form [formGroup]="form" (submit)="submit()">

    <div
      class="form-control"
      [ngClass]="{invalid: form.get('login').touched && form.get('login').invalid}"
    >
      <mat-form-field>
        <input matInput id="login" formControlName="login" placeholder="Логин">
      </mat-form-field>


      <div
        *ngIf="form.get('login').touched && form.get('login').invalid"
        class="validation"
      >
        <small *ngIf="form.get('login').errors.required">
          Пожалуйста телефон водителя
        </small>
      </div>
    </div>

    <div
      class="form-control"
      [ngClass]="{invalid: form.get('name').touched && form.get('name').invalid}"
    >
      <mat-form-field>
        <input matInput id="name" formControlName="name" placeholder="Имя">
      </mat-form-field>
      <div
        *ngIf="form.get('name').touched && form.get('name').invalid"
        class="validation"
      >
        <small *ngIf="form.get('name').errors.required">
          Пожалуйста введите Имя
        </small>
      </div>
    </div>

  </form>


</div>

<div mat-dialog-actions align="end">

  <div class="mat-form-field-flex">
    <span style="flex: 1 1 auto"></span>
    <button
      type="submit"
      mat-raised-button
      color="warn"
      (click)="onNoClick()"
    >
      Отмена
    </button>
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      Создать
    </button>

  </div>


</div>
