import { Component, OnInit } from '@angular/core';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatDialog } from '@angular/material';

import { Router } from '@angular/router';
import { IAccount } from 'src/app/shared/interfaces';
import { AccountsService, UserType, UserTypeLabel } from 'src/app/services/accounts.service';
import { MessageService } from 'ui-message-angular';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss']
})
export class AccountsPageComponent extends BaseListPageComponent<IAccount> implements OnInit {
  displayedColumns = ['id', 'is_active', 'name', 'login', 'email', 'user_type', 'phone', 'actions'];
  UserType = UserType
  DetailRoute: string = 'accountDetail';
  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private service: AccountsService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
    
    );
    this.Title = "Пользователи";
  }

  protected getData() {
    return this.service.getAll();
  }


  getTypeName(value) {
    return UserTypeLabel.get(value);
  }


}
