<h2 mat-dialog-title>{{description}}</h2>

<mat-dialog-content [formGroup]='form' style='overflow: hidden'>
  <div class='container'>
    <div class='client-name'>

      <i class='material-icons inline-icon'>
        supervisor_account
      </i>
      <mat-form-field class='client-name-field'>
        <mat-label>Заказчик</mat-label>
        <input type='text' matInput [formControl]='client' [matAutocomplete]='auto'>
        <mat-autocomplete #auto='matAutocomplete' [displayWith]='displayFnClient'
                          (optionSelected)='setClient($event.option.value)'>
          <mat-option *ngFor='let option of filteredOptionsClient | async' [value]='option'>
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <!--<mat-error *ngIf="animalControl.hasError('required')">Please choose an animal</mat-error>
<mat-hint>{{animalControl.value?.sound}}</mat-hint>-->
      </mat-form-field>
    </div>
    <!--
    <div>
        <i class="material-icons inline-icon">
            directions_car
        </i>
        <mat-form-field>
            <mat-label>Авто</mat-label>
            <mat-select [formControl]="car" (selectionChange)="setVehicle($event)" required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let vehicle of cars" [value]="vehicle">
                    {{vehicle.reg_number}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
-->

    <div class='client-phone'><i class='material-icons inline-icon'>
      call
    </i>
      <mat-form-field>
        <input matInput placeholder='Телефон' formControlName='phone_number' maxlength='10'>
      </mat-form-field>
    </div>
  </div>
  <div class='container'>
    <div class='client-car-no' fxFlex='50%'>
      <div>
        <mat-icon class='menu-item-icon'>directions_car</mat-icon>
      </div>
      <mat-form-field>
        <input matInput placeholder='Рег. Номер' formControlName='car_no' (click)='$event.target.select()' />
      </mat-form-field>
    </div>
    <div class='client-car-model' fxFlex='50%'>
      <div>
        <mat-icon class='menu-item-icon'>local_shipping</mat-icon>
      </div>
      <mat-form-field>
        <input matInput placeholder='Модель' formControlName='car_model' />
      </mat-form-field>
    </div>
  </div>
  <div class='container'>
    <input class='form-control' placeholder='Адрес' ng-model='addressSuggestView'
           id='suggest_address' yandex-suggest-view />
  </div>
  <div class='fuel-container'>
    <mat-button-toggle-group>
      <mat-button-toggle *ngFor='let product of products' [value]='product' (click)='setFuel(product)'
                         [style]='{backgroundColor: product.mark_color, lineHeight: "20px"}'>
        {{product.name}}<br />
        Цена: {{product.price}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class='container fuel'>
    <div class='fuel-block'>
      <div fxFlex='50%'>
        <mat-form-field class='volume-field'>
          <input matInput type='number' placeholder='Количество' formControlName='volume' (input)='setVolume($event)'
                 (click)='$event.target.select()' />
        </mat-form-field>
        <mat-checkbox class='payment-type-bank' [checked]='paymentTypeBank'
                      (change)='this.paymentTypeBank = $event.checked'>
          Безналичный расчет
        </mat-checkbox>
        <div class='date_delivery'>
          <span>Дата доставки:</span>
          <p-calendar formControlName='date_delivery' showTime='true' dateFormat='dd.mm.yy' appendTo='body'
                      hourFormat='24' [inputStyle]="{'height': '30px', 'width': '150px'}"></p-calendar>
        </div>
        <mat-checkbox *ngIf="auth.isRole(['SuperAdmin'])" class='payment-type-bank' [checked]='testMode'
                      (change)='this.testMode = $event.checked'>
          Тестовый заказ
        </mat-checkbox>
      </div>
      <div fxFlex='50%'>
        <mat-form-field class='comment'>
          <mat-label>Комментарий</mat-label>
          <textarea matInput formControlName='comment_text'></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class='bottom-panel'>
  <h2 class='order-summ'>
    <div><i class='material-icons inline-icon summ-icon'>
      functions
    </i>

      <mat-label>Сумма заказа: {{orderSumm}}</mat-label>
    </div>
  </h2>

  <div class='actions-panel'>
    <button class='mat-raised-button custom-button' (click)='close()'>Закрыть</button>
    <button class='mat-raised-button mat-primary custom-button' (click)='save()' [disabled]='!orderSumm'>Сохранить
    </button>
  </div>

</mat-dialog-actions>
