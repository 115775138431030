<div class="container">
    <div class="header">
      <h1>{{Title}}</h1>
      <span class="spacer"></span>
      <a mat-mini-fab color="accent" (click)="newItem()">
        <mat-icon aria-label="add">add</mat-icon>
      </a>
    </div>

    <div class="table-container mat-elevation-z2">
      <div class="table-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
      </div>
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Код </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Наименование </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name | maxChar:15 }} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef> Телефон </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.phone_number}} </mat-cell>
        </ng-container>
    <!--
        <ng-container matColumnDef="created">
          <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.created | date }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>
    -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Действия </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a mat-icon-button title="Просмотр" (click)="displayItem(element.id)">
          <mat-icon color="primary">preview</mat-icon>
        </a>

        <a mat-icon-button title="Редактировать" (click)="editItem(element.id)">
          <mat-icon color="primary">create</mat-icon>
        </a>
        <!--<a mat-icon-button title="Удалить" (click)="deleteItem(element.id)">
          <mat-icon color="primary">delete</mat-icon>
        </a>-->
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
          <mat-paginator showFirstLastButtons
                 [pageSizeOptions]="[3, 5, 10]"></mat-paginator>
    </div>
</div>