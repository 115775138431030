import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { Product } from 'src/app/models/product.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProduct, IApiProductsListResponse } from '../shared/interfaces';
const getProductsUrl:string = 'manager/products';
const createProductsUrl:string = 'manager/product/update';
const requestProductApiUrl:string = 'manager/product/{id}/info';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api: ApiV5Service) { }
  getProducts(): Observable<IApiProductsListResponse> {
    return this.api.getAllAsObject(getProductsUrl);
  }
  getProduct(id: number): Observable<IProduct> {
    return this.api.getEntity( requestProductApiUrl.replace("{id}",String(id)));
  }
  saveProduct(entity: IProduct): Observable<IProduct> {

    return this.api.create(createProductsUrl, entity)
  }
  
  CreateOrUpdate(product: IProduct[]):
    Observable<IProduct> {
    return this.api.create(createProductsUrl, product)
    /*  let headers =
              new Headers({'Content-Type':
                     'application/json'});
      let options =
              new RequestOptions(
                        {headers: headers});
      return this.http.post(this.url,
                            product, options)
        .map(this.extractData)
        .catch(this.handleError);
    }*/

  }

}