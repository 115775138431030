<h2 mat-dialog-title>{{description}}</h2>

<mat-dialog-content [formGroup]='form' style='overflow: hidden'>
  <div class='container'>
    <span style='color: red; margin-bottom: 10px'>{{tip}}</span>
  </div>
  <div class='container'>
    <div class='client-phone'>
      <i class='material-icons inline-icon'>call</i>
      <mat-form-field>
        <input matInput placeholder='Телефон' formControlName='phone_number' (change)='onChangePhone()' maxlength='10'>
      </mat-form-field>
      <button mat-flat-button color='accent' type='button' (click)='applyPhoneFilter()'
              [disabled]='isPhoneFilled()'>Поиск
      </button>
    </div>
    <div class='client-name'>
      <i class='material-icons inline-icon'>
        supervisor_account
      </i>
      <mat-form-field class='client-name-field'>
        <mat-label>Заказчик</mat-label>
        <input matInput [value]='client ? client.name : ""' [readonly]='isDisabledClientFields'
               (change)='onChangeClient($event)' />
      </mat-form-field>
    </div>
  </div>
  <div class='container'>
    <div class='client-car-no' fxFlex='50%'>
      <div>
        <mat-icon class='menu-item-icon'>directions_car</mat-icon>
      </div>
      <mat-form-field>
        <input matInput placeholder='Рег. Номер' formControlName='car_no' [readonly]='isDisabledFields' />
      </mat-form-field>
    </div>
    <div class='client-car-model' fxFlex='50%'>
      <div>
        <mat-icon class='menu-item-icon'>local_shipping</mat-icon>
      </div>
      <mat-form-field>
        <input matInput placeholder='Модель' formControlName='car_model' [readonly]='isDisabledFields' width='100%' />
      </mat-form-field>
    </div>
  </div>
  <div class='container'>
    <input class='form-control' placeholder='Адрес' ng-model='addressSuggestView'
           id='suggest_address' yandex-suggest-view [disabled]='isDisabledFields' />
  </div>
  <div class='fuel-container'>
    <mat-button-toggle-group>
      <mat-button-toggle *ngFor='let product of products' [value]='product' (click)='setFuel(product)'
                         [style]='{backgroundColor: product.mark_color, lineHeight: "20px"}'
                         [disabled]='isDisabledFields'>
        {{product.name}}<br />
        Цена: {{product.price}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class='container fuel'>
    <div class='fuel-block'>
      <div fxFlex='50%'>
        <mat-form-field class='volume-field'>
          <input matInput type='number' placeholder='Количество' formControlName='volume' (input)='setVolume($event)'
                 [readonly]='isDisabledFields || fullTank' (click)='$event.target.select()' />
        </mat-form-field>
        <mat-form-field class='volume-field'>
          <input matInput type='number' placeholder='Сумма заказа' formControlName='summ'
                 (input)='setOrderSum($event)'
                 [readonly]='isDisabledFields || fullTank' />
        </mat-form-field>
      </div>
      <mat-form-field class='comment' fxFlex='50%'>
        <mat-label>Комментарий</mat-label>
        <textarea matInput formControlName='comment_text'
                  [readonly]='isDisabledFields'></textarea>
      </mat-form-field>
    </div>
    <div class='fuel-block'>
      <div fxFlex='50%'>
        <mat-checkbox class='full-tank' [checked]='fullTank' (change)='setFullTank($event.checked)'
                      [disabled]='isDisabledFields'>Полный бак
        </mat-checkbox>
        <mat-checkbox class='call-me-before-mins' [checked]='callMeBeforeMins'
                      (change)='callMeBeforeMins = $event.checked' [disabled]='isDisabledFields'>
          Позвонить за <input class='form-control call-me-before-mins-value'
                              (change)='callMeBeforeMinsValue = $event.target.value'
                              [value]='callMeBeforeMinsValue' [disabled]='isDisabledFields || !callMeBeforeMins' />
          минут
        </mat-checkbox>
        <div class='date_delivery'>
          <span>Время выполнения заказа:</span>
          <p-calendar formControlName='date_delivery' timeOnly='true' fxFlex='30%' appendTo='body'
                      [inputStyle]="{'height': '30px'}" [disabled]='isDisabledFields'></p-calendar>
        </div>
      </div>
      <div fxFlex='50%'>
        <mat-form-field class='link'>
          <input matInput placeholder='Код приглашения' [value]='client ? (client.link || "") : ""'
                 [readonly]='isDisabledFields' />
        </mat-form-field>
        <mat-checkbox *ngIf="auth.isRole(['SuperAdmin'])" class='test_mode' [checked]='testMode'
                      (change)='this.testMode = $event.checked'>
          Тестовый заказ
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class='bottom-panel'>
  <div class='actions-panel'>
    <button class='mat-raised-button custom-button' (click)='close()'>Закрыть</button>
    <button class='mat-raised-button mat-primary custom-button' (click)='save()' [disabled]='isDisabledFields'>
      Сохранить
    </button>
  </div>
</mat-dialog-actions>
