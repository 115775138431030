import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPromo } from '../shared/interfaces';
import { ApiV5Service } from '../shared/services/apiV5.service';
export enum PromoType {
  Liters = 0,
  Summ = 1,
  Percent =2
} 
export const PromoTypeLabel = new Map<number, string>([
  [PromoType.Liters, 'Литры'],
  [PromoType.Percent, 'Процент'],
  [PromoType.Summ, 'Сумма'],
  
]);
//API 
const getGetAllItemsApiUrl='promotions/all';
const getGetItemApiUrl='promo/{id}/info';
const getSetItemApiUrl='promo';
@Injectable({
  providedIn: 'root'
})
export class PromoService {

  constructor(private apiService:ApiV5Service) { }
  
  public getAll()
  {
    return this.apiService.getAllAsObject(getGetAllItemsApiUrl);

  }
  getItem(id: number): Observable<IPromo> {
    return this.apiService.getEntity( getGetItemApiUrl.replace("{id}",String( id)));
  }
  saveItem(entity: IPromo): Observable<IPromo> {
    
    
    return this.apiService.create(getSetItemApiUrl, entity)
  }


}
