import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {ApiService} from '../../shared/services/api.service';
import {IOrders, ILocation} from '../../shared/interfaces';
import ymaps from 'ymaps';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  defaultLocation:ILocation;
  constructor(
    private settings: SettingsService
    ) { }
  ngOnInit() {
    
    this.defaultLocation = this.settings.getCurrentCity().default_location;
    ymaps
      //.load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU')
      .load(this.settings.getMapInitUrl())
      .then(maps => {
        const map = new maps.Map('map', {
          center: [this.defaultLocation.latitude,this.defaultLocation.longitude],
          zoom: 14,
          controls: [],
          clusterer: [{
            preset: 'islands#invertedVioletClusterIcons',
            hasBaloon: false
          }]
        });
      });
  }
}
