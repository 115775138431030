<h3 mat-dialog-title>{{ data.title }}</h3>
<mat-dialog-content>
  <div class="box">
    <div class="box-content"> <p> {{ data.text}}</p></div>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="onClick()"
  >
    Ok
  </button>
  
</mat-dialog-actions>
