<h3 mat-dialog-title>{{ data.title }}</h3>
<mat-dialog-content>
  <div class="box">
    <div class="box-content"> <p> {{ data.text}}</p></div>
<!--    <div class="box-img"><i class="material-icons md-48">-->
<!--      delete_forever-->
<!--    </i></div>-->
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    type="submit"
    mat-raised-button
    color="primary"
    (click)="onClick(1)"
  >
    Да
  </button>
  <button
    type="submit"
    mat-raised-button
    color="warn"
    (click)="onClick(0)"
  >
    Нет
  </button>
</mat-dialog-actions>
