import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { ChatUserListComponent } from '../../chat/chat-user-list/chat-user-list.component';

@Component({
  selector: 'app-chatroom-page',
  templateUrl: './chatroom-page.component.html',
  styleUrls: ['./chatroom-page.component.scss']
})

export class ChatroomPageComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChild('scroller') scrollFrame: ElementRef;
  @ViewChild(ChatUserListComponent ) child: ChatUserListComponent ;
  // @ViewChildren(ChatFeedComponent, { read:  ChatMessageComponent }) itemElements: QueryList<any>;

  private isNearBottom = true;
  private scrollContainer: any;
  listFilters: any[] = [{ Id: 1, Name: 'Сегодня' }, { Id: 3, Name: '3 дня' }, { Id: 10, Name: '10 дней' }];
  currentFilter: number;

  constructor(private chat: ChatService) {
    this.applyFilter(1);
  }

  applyFilter(value) {
    this.chat.setUsersListDeep(+value);
  }

  ngOnInit() {
    // autoscroll on if user changed
    this.chat.currentUser.subscribe(client => {
      if (client) {
        if (this.scrollContainer) {
          this.scrollContainer.scrollTop = this.scrollContainer.offsetHeight;
        }
      }
    });
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    // this.itemElements.changes.subscribe(_=>{this.onItemElementsChanged(); console.log(_);})
    // console.log(this.itemElements);
  }

  ngAfterViewChecked() {
    this.onItemElementsChanged();
  }


  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  private isUserNearBottom(): boolean {
    const threshold = 50;
    if (this.scrollContainer) {
      const position = this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
      const height = this.scrollContainer.scrollHeight;
      return position > height - threshold;
    } else {
      return true;
    }
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }
}
