import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginDialogComponent } from 'src/app/dialogs/login-dialog/login-dialog.component';
import { ChatService } from 'src/app/services/chat.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ICity } from 'src/app/shared/interfaces';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  cities: ICity[] = [];
  public city: number;
  // chatUsersTime: NodeJS.Timeout;
  // chatUsersSub: Subscription;
  // newMessagesInfo = { msgCount: 0, usrCount: 0 };

  constructor(
    public auth: AuthService,
    public settings: SettingsService,
    private router: Router,
    public dialog: MatDialog
    // private chat: ChatService
  ) {
  }

  ngOnInit() {

    this.setCities();
    // this.dataRefresh();
    // this.chatUsersTime = setInterval(() => {
    //   this.dataRefresh();
    // }, 15 * 1000);
  }

  ngOnChanges() {
    // this.feed = this.chat.getMessages()
    // this.dataRefresh();
  }

  // dataRefresh() {
  //   this.chatUsersSub = this.chat.getUsers().subscribe(users => {
  //     const usr = users;
  //     usr.forEach(element => {
  //       element.data = JSON.parse(element.info);
  //     });
  //     const msgCount = usr.reduce((acc, cur) => acc + cur.newMessagesCnt, 0);
  //     const usrCount = usr.filter(u => u.newMessagesCnt > 0).length; // reduce((acc,cur) => acc + Number(cur.newMessagesCnt));
  //     this.newMessagesInfo = { msgCount, usrCount };
  //   });
  // }

  openDialog() {
    if (!this.auth.isAuthenticated()) {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        data: 0
        // width: '800px'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {

        this.settings.loadAppSettings();

        this.setCities();
        setTimeout(() => {
          this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
            this.router.navigate(['/']));
        }, 500);
      });
    } else {
      this.auth.logout();

    }

  }

  ngOnDestroy(): void {
    // if (this.chatUsersSub) {
    //   this.chatUsersSub.unsubscribe();
    // }
    // clearInterval(this.chatUsersTime);
  }

  setCities() {
    if (this.auth.isAuthenticated) {
      this.cities = this.settings.getCities(this.auth.user_cities);
      this.city = this.auth.user_city;
    }
  }

  changeCity(value) {
    this.auth.user_city = value;
    window.location.reload();
    // this.city = value;
  }
}
