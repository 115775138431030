<div mat-dialog-title>
  Выберите водителя для заказа:
  <div *ngIf="auto_assign_booster" [ngStyle]="{'fontSize':'12px'}" >рекомендуемый заправщик {{ auto_assign_booster.name }}
    ({{auto_assign_booster.fuel_type}}) / {{ auto_assign_booster.login}}) в {{auto_assign_info.distance | number}} км,
    заказов в очереди: {{ auto_assign_booster.orders.length }}</div>
  <div *ngIf='isCallMeNow()' [ngStyle]="{'fontSize':'16px', 'color': 'red'}">Клиент просит согласовать время</div>
</div>
<div mat-dialog-content>
  <div *ngIf="drivers; else loading">
    <mat-list>
      <!--      <mat-list-item *ngFor="let driver of drivers">-->
      <!--        {{ driver.name }}-->
      <!--      </mat-list-item>-->
      <mat-action-list>
        <!--      <div *ngFor="let driver of drivers">-->
        <div mat-list-item *ngFor="let driver of drivers" fxLayout fxLayoutAlign="left center" [ngStyle]="{'background-color': auto_assign_booster && driver.id==auto_assign_booster.id? 'GreenYellow' :'white'}" >


            <button [mat-dialog-close]="driver.id" fxFlex> {{ driver.name }} ({{driver.fuel_type}}) / {{ driver.login}}

          </button>
          <button  mat-flat-button fxFlex="10" [ngStyle]="{'background':'border-box'}">
            <i class="material-icons">
              article
            </i>
          </button>
        </div>




        <!--      </div>-->

        <!--      <button mat-list-item (click)="submit()"> Save </button>-->
        <!--      <button mat-list-item (click)="onNoClick()"> Undo </button>-->
      </mat-action-list>
    </mat-list>



  </div>
</div>
<div mat-dialog-actions align="end">
  <button type="submit" mat-raised-button color="warn" (click)="onNoClick()">
    Отмена
  </button>

</div>
<ng-template #loading>
  <p class="text-center">загрузка ...</p>
</ng-template>
