import { Component, OnInit ,OnDestroy} from '@angular/core';
import { round } from 'lodash';
import { SettingsService } from '../services/settings.service';

@Component({
  selector: 'app-system-load-info',
  templateUrl: './system-load-info.component.html',
  styleUrls: ['./system-load-info.component.scss']
})
export class SystemLoadInfoComponent implements OnInit {
  info:any={systemLoad:0,systemLoadDescription:'Получение информации'};
  colors=['4CAF50','8BC34A','CDDC39','FFEB3B','FFC107','FF9800','FF5722','F44336','D32F2F','C62828','4E342E']
  timeSub: NodeJS.Timeout;
  constructor(private settings:SettingsService) { }

  ngOnInit(): void {
    this.refreshData();
    this.timeSub = setInterval(() => {
      this.refreshData();
    }, 5*60000);
  }
  OnDestroy(): void {
    
    this.timeSub =null;
  }
  refreshData(){
    this.settings.getSystemLoad().subscribe(data =>{
      this.info = data;
      
      this.info['color'] = this.colors[Math.round(data.systemLoad*10)];
      
      this.info['systemLoad'] = data.systemLoad*100;
     
    })
  }

}
