import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {
  IAssignOrder,
  IChangeStatus,
  IDrivers,
  IOrder,
  IOrders,
  ISelectRowOrders,
  IOrderLine,
  IRange,
  IBankPaymentInfo
} from '../../shared/interfaces';
import { API, APIDefinition, Columns, Config, DefaultConfig } from 'ngx-easy-table';
// import {ApiService} from '../shared/services/api.service';
import { ApiV5Service } from '../../shared/services/apiV5.service';
import { MatDialog } from '@angular/material';
import { WtDialogComponent } from '../../wt-dialog/wt-dialog.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { ApiService } from 'src/app/shared/services/api.service';

import { ExcelService } from 'src/app/shared/services/excel.service';
import { OrdersService, OrderStatus } from 'src/app/services/orders.service';

import { AuthService } from '../../shared/services/auth.service';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { replace } from 'lodash';
import { OrderDetailsComponent } from 'src/app/order-details/order-details.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { AssignDriverDialogComponent } from 'src/app/dialogs/assign-driver-dialog/assign-driver-dialog.component';
import { CompleteOrderDialogComponent } from 'src/app/dialogs/complete-order-dialog/complete-order-dialog.component';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { ExportBuhDialogComponent } from 'src/app/dialogs/export-buh-dialog/export-buh-dialog.component';
import { CreateOrderDialogComponent } from 'src/app/dialogs/create-order-dialog/create-order-dialog.component';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { Title } from '@angular/platform-browser';
import { CreateOrderBuyersDialogComponent } from '../../dialogs/create-order-buyers-dialog/create-order-buyers-dialog.component';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-order-page',
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.scss']
})
export class OrderPageComponent implements OnInit {


  constructor(private apiV5Service: ApiV5Service,
              private titleService: Title,
              private excelService: ExcelService,
              public dialog: MatDialog,
              public auth: AuthService,
              public ordersService: OrdersService,
              public datepipe: DatePipe,
              private notifierService: NotifierService,
              public settingsService: SettingsService) {
    this.notifier = notifierService;
  }

  public get orders(): IOrders[] {

    const ords = this.data
      .sort((val1, val2) => {
        return val1.order_status - val2.order_status;
      });
    return ords;

  }

  get isMobile(): boolean {
    return this.innerWidth <= 768;
  }

  public data: IOrders[] = [];
  public filteredData: IOrders[] = [];
  public configuration: Config;
  public innerWidth: number;
  public columns: Columns[];
  public toggledRows = new Set<number>();
  public selected = new Set<number>();
  public selectedStatus = new Set<ISelectRowOrders>();
  public fltOnlyFirms = false;
  public clientTypeFilter = '-1';
  isNightFlt = { status: 'crossed' };

  public statusOrder: string[] = [
    'Отменен сервисом', 'Отменен клиентом', 'Неизвестен', 'Ожидает оплаты', 'Новый', 'Назначен', 'В пути', 'Доставлен', 'Завершен'
  ];

  private readonly notifier: NotifierService;

  @ViewChild('table', { static: true }) table: APIDefinition;

  startDate = new Date();
  stopDate = new Date();

  @HostListener('window:resize', [])
  onResize(): void {
    this.checkView();
  }

  ngOnInit() {
    this.titleService.setTitle('Заказы');
    this.clientTypeFilter = '-1';
    this.checkView();

    this.refreshData();
  }

  private checkView(): void {
    this.innerWidth = window.innerWidth;
    if (this.isMobile) {
      this.columns = [
        { key: '', title: '', searchEnabled: false },
        { key: 'id', title: 'Номер заказа', searchEnabled: false },
        { key: 'updated_at', title: 'Дата' },
        { key: '', title: 'Action' }
      ];
    } else {
      this.columns = [
        { key: '', title: '', searchEnabled: false, width: '2%' },
        { key: 'id', title: 'Номер', orderBy: 'desc', width: '4%' },
        { key: 'updated_at', title: 'Дата', width: '7%' },
        { key: 'client_name', title: 'Имя', width: '7%' },
        { key: 'phone_number', title: 'Телефон', width: '7%' },
        { key: 'sellings', title: 'Состав', searchEnabled: false, width: '12%' },
        { key: 'car_model', title: 'Модель авто', width: '7%' },
        { key: 'car_no', title: 'Номер Авто', width: '5%' },
        { key: 'order_status', title: 'Статус заказа', searchEnabled: false, width: '5%' },
        { key: 'address', title: 'Адрес', width: '7%' },
        { key: 'comment', title: 'Комментарий', width: '8%' },
        { key: 'night_time', title: 'Ночная заправка', searchEnabled: false, width: '1%' },
        { key: 'driver.name', title: 'Заправщик', width: '3%' },
        { key: 'date_delivery_plan', title: 'Плановое время выполнения', width: '3%' },
        { key: 'date_delivery_fact', title: 'Фактическое время выполнения', width: '3%' }
      ];
      if (this.auth.isVisible('order', 'field_summ')) {
        this.columns.push({ key: 'order_summ', title: 'Сумма (руб.)', searchEnabled: false, width: '3%' });
      }
      if (this.auth.isVisible('order', 'field_payment_type')) {
        this.columns.push({ key: 'payment_type', title: 'Тип платежа', searchEnabled: false, width: '3%' });
      }
      this.columns.push({ key: '', title: 'Действия', searchEnabled: false, width: '9%' });
    }
  }

  onChange(row: any, event: any): void {
    const index = this.data.indexOf(row);
    if (event.checked) {
      this.selected.add(row.id);
    } else {
      this.selected.delete(row.id);
    }
  }

  onRowClickEvent($event: MouseEvent, index: number): void {
    $event.preventDefault();
    this.table.apiEvent({
      type: API.toggleRowIndex,
      value: index
    });
    if (this.toggledRows.has(index)) {
      this.toggledRows.delete(index);
    } else {
      this.toggledRows.add(index);
    }
  }

  openAssignDialog(id: number, mass: boolean): void {
    const order = this.data.find(o => o.id === id);
    const orderIsNight = order.night_time === 1;
    const hours = moment().hours();
    if (hours < 20 && orderIsNight) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: { title: 'Внимание !!! ', text: 'Заказ на ночную заправку. Вы уверены что хотите назначить водителя на заказ сейчас ?' },
        width: '300px'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
          this.assignDriver(id, mass);
        }
      });
    } else {
      this.assignDriver(id, mass);
    }
  }

  assignDriver(id: number, mass: boolean): void {
    const order = this.data.find(o => o.id === id);
    const dialogRef = this.dialog.open(AssignDriverDialogComponent, {
      data: { id, order },
      width: '800px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // @ts-ignore
        const dialogRefT = this.dialog.open(WtDialogComponent, {
          data: { time: '00:45', date: '', manager_comment: '' },

          width: '800px'
        });
        dialogRefT.disableClose = true;
        dialogRefT.afterClosed().subscribe(resultD => {
          const d: IAssignOrder = {
            order_id: id,
            driver_id: result,
            date_delivery_plan: resultD.date,
            waiting_time: resultD.time,
            manager_comment: resultD.manager_comment
          };
          this.ordersService.assignDriver(d).subscribe(response => {
            this.refreshData();
            this.selected.clear();
          });
        });
      }
    });
  }

  refreshData() {
    this.ordersService.getOrdersAll(this.startDate, this.stopDate).subscribe(data => {
      this.configuration = { ...DefaultConfig };
      this.configuration.isLoading = true;
      data.list.forEach(d => replace(replace(replace(replace(d.phone_number, ' ', ''), '-', ''), ')', ''), '(', ''));
      this.data = this.ordersService.filterOrdersForSuperAdmin(data.list);

      this.configuration.detailsTemplate = true;
      // this.configuration.paginationRangeEnabled = false;
      this.configuration.tableLayout.striped = true;
      this.configuration.isLoading = false;
      this.configuration.searchEnabled = true;
      this.configuration = { ...this.configuration };
      this.applyFilter();
    });

  }

  complete(id: number, mass: boolean) {
    const dialogRef = this.dialog.open(CompleteOrderDialogComponent, {
      data: { title: 'Завершение заказа', text: 'Завершить заказ?', value: 0 },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let d: IChangeStatus[] = [];
        if (!mass) {
          d = [{
            order_id: id,
            order_status_id: OrderStatus.Completed,
            fuel_rest: result.value
          }];
        } else {
          for (const idM of this.selected) {
            // console.log(idM);
            const statusOrder = this.data.filter(status => status.id === idM)[0].order_status;
            if (statusOrder === 3) {
              d.push({
                order_id: idM,
                order_status_id: 6
              });
            } else {
              this.notifier.notify('error', 'Заказ номер ' + idM + ' не может быть обработан так как имеет неподходящий статус');
            }
          }
        }
        for (const i of d) {

          this.ordersService.setOrderStatusWithData(i).subscribe(response => {
            this.refreshData();
            this.selected.clear();
          });
        }
      }
    });
  }

  set_payment(id: number, mass: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Изменение статуса заказа', text: 'Установить отметку об оплате ?' },
      width: '300px'
    });
    dialogRef.disableClose = false;

    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.ordersService.setOrderStatus(id, OrderStatus.WaitingForAssignment).subscribe(response => {
          this.refreshData();
          this.selected.clear();
        });
      }
    });


  }

  openOrderDetails(id: number) {
    const dialogRef = this.dialog.open(OrderDetailsComponent, {
      data: { title: 'Детали', orderId: id },
      width: '600px'
    });
    dialogRef.disableClose = false;

  }

  request_payment(id: number, mass: boolean) {
    this.ordersService.getPaymentInfoFromBank(id).subscribe(response => {
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: { title: 'Статус заказ в банке', text: response.orderStatusText },
        width: '300px'
      });
      dialogRef.disableClose = false;

      dialogRef.afterClosed().subscribe(result => {
        this.refreshData();
        this.selected.clear();
      });
    });
  }

  canceled(id: number, mass: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title: 'Отмена заказа', text: 'Отменить заказ?' },
      width: '300px'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        let d: IChangeStatus[] = [];
        if (!mass) {
          d = [{
            order_id: id,
            order_status_id: OrderStatus.CancelledByService,
            fuel_rest: 0
          }];
        } else {
          for (const idM of this.selected) {
            const statusActive = this.data.filter(status => status.id === idM)[0].order_active;
            if (statusActive === 1) {
              d.push({
                order_id: idM,
                order_status_id: 3
              });
            } else {
              this.notifier.notify('error', 'Заказ номер ' + idM + ' не может быть обработан так как имеет неподходящий статус');
            }
          }
        }
        for (const i of d) {
          this.ordersService.setOrderStatusWithData(i).subscribe(response => {
            this.refreshData();
            this.selected.clear();
          });
        }
      }
    });
  }

  exportToExcel(ver) {
    if (ver == 'common') {
      this.filteredData;
      this.excelService.exportAsExcelFile(this.filteredData, 'Заказы');
    } else {
      this.exportToExcelBuh();
    }
  }

  exportToExcelBuh() {


    const dialogRef = this.dialog.open(ExportBuhDialogComponent, {
      data: { title: 'Экспорт заказов в 1С', orders: this.data },
      width: '300px',
      height: '220px'
    });
    dialogRef.disableClose = false;

  }

  createOrder() {
    const dialogRef = this.dialog.open(CreateOrderDialogComponent, {
      data: { title: 'Новый заказ', orders: this.data },
      width: '700px',
      height: '615px'
    }).afterClosed().subscribe(() => this.refreshData());
    // dialogRef.disableClose = false;
  }

  createOrderBuyers() {
    const dialogRef = this.dialog.open(CreateOrderBuyersDialogComponent, {
      data: { title: 'Новый заказ', orders: this.data },
      width: '700px',
      height: '660px'
    }).afterClosed().subscribe(() => this.refreshData());
    // dialogRef.disableClose = false;
  }

  checkIsNightFlt(isNightFlt) {
    switch (isNightFlt.status) {
      case 'checked': {
        isNightFlt.status = 'unchecked';

        break;
      }
      case 'unchecked': {
        isNightFlt.status = 'crossed';
        break;
      }
      case 'crossed': {
        isNightFlt.status = 'checked';

        break;
      }
    }
    this.applyFilter();

  }

  applySort(dataI = this.data) {
    return dataI
      .sort((val1, val2) => {
        return val1.order_status - val2.order_status;
      });
  }

  applyFilter(dataI = this.data) {

    this.filteredData = this.applyIsNightFltToDS(dataI);
    if (this.clientTypeFilter != '-1') {
      this.filteredData = this.filteredData.filter(d => d.client_type == Number(this.clientTypeFilter));


    }
    this.filteredData = this.applySort(this.filteredData);
  }

  applyClientTypeFilter(value) {

    this.clientTypeFilter = value;
    this.applyFilter();
  }

  applyIsNightFltToDS(data: IOrders[]): IOrders[] {
    switch (this.isNightFlt.status) {
      case 'checked': {
        data = data.filter(x => x.night_time == 1);
        break;
      }
      case 'unchecked': {
        data = data.filter(x => x.night_time == 0);
        break;
      }
    }
    return data;
  }

}

