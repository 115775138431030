export interface IRole {
    id: string;
    title: string;
  }
export enum Role {
    User = 'User',
    Admin = 'Admin',
    SuperAdmin = 'SuperAdmin',
    ClientManager ='ClientManager',
    ChatSend = 'ChatSend', 
    FirmsOperator= 'FirmsOperator',
    Analitycs = 'Analitycs'
}
export namespace Role {
    export function toArray(): any[] {
        return Object.keys(Role).filter(key=>key!=='toArray' && key!=='SuperAdmin')
        .map(key => ({ id: Role[key], title: key }));
    }
}