import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../shared/services/api.service';
import { Observable, Subscription } from 'rxjs';
import {
  IOrder,
  IOrders,
  ILocation,
  IChangeStatus,
  IBankPaymentInfo,
  IAssignOrder,
  IBuhReestr,
  INewOrder,
  IRange,
  IApiListResponse, IChangeOrderAddress
} from '../shared/interfaces';
import { SettingsService } from './settings.service';
import { ApiV5Service } from '../shared/services/apiV5.service';
import * as moment from 'moment';
import { AuthService } from '../shared/services/auth.service';

export enum OrderStatus {
  CancelledByService = -2,
  CancelledByCustomer = -1,
  Unknown = 0,
  WaitingForPayment = 1,
  WaitingForAssignment = 2,
  Assigned = 3,
  OnWay = 4,
  Delivered = 5,
  Completed = 6,
  Pending = 100
}

//API
const setStatusApiUrl = 'manager/order/set/status';
const requestPaymentInfoApiUrl = 'manager/order/payment/refresh';
const requestOrderDetailsApiUrl = 'manager/order/details';
const requestOrderAssignDriverApiUrl = 'manager/order/assign';
const requestOrderChangeAddressApiUrl = 'manager/order/address';
const requestBuhReestrApiUrl = 'manager/buh/reestr';
const requestOrderCreateApiUrl = 'manager/order/create';
const requestOrdersAllApiUrl = 'manager/orders/all';
const requestOrdersFind = 'manager/find';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  getBankStatusName(status: any): any {
    var result = status;
    if (status == '3DS_CHECKING') {
      result = 'Ожидание подтверждения';
    } else if (status == 'CONFIRMED') {
      result = 'Подтвержден';
    } else if (status == 'REFUNDED') {
      result = 'Средства возвращены';
    }
    return result;
  }

  dataSub: Subscription;
  data: IOrders[];
  statusOrder = ['Отменен сервисом', 'Отменен клиентом', 'Неизвестен',
    'Ожидает оплаты', 'Новый', 'Назначен', 'В пути', 'Доставлен', 'Завершен'];
  statusOrderColor = ['#e57373 none repeat scroll 0% 0%', '#e57373 none repeat scroll 0% 0%', 'black none repeat scroll 0% 0%',
    'yellow none repeat scroll 0% 0%', 'rgb(255, 255, 255) none repeat scroll 0% 0%', '#a5d6a7 none repeat scroll 0% 0%',
    'rgba(75, 248, 149, 0.12) none repeat scroll 0% 0%', 'rgba(75, 248, 149, 0.12) none repeat scroll 0% 0%',
    'rgba(75, 248, 149, 0.12) none repeat scroll 0% 0%'];
  orderPaymentType: string[] = ['', 'Карта', 'Наличные', 'Банк'];
  defaultLocation: ILocation;

  constructor(private apiService: ApiV5Service, private settings: SettingsService, private auth: AuthService) {
    this.defaultLocation = this.settings.getCurrentCity().default_location;
  }

  colorByStatus(status: number): any {
    return this.statusOrderColor[status + 2];
  }

  getStatusOrder(status: number) {
    return this.statusOrder[status + 2];
  }

  filterOrdersForSuperAdmin(orders: IOrders[]) {
    if (!this.auth.isRole(['SuperAdmin'])) {
      orders = orders.filter(order => !JSON.parse(order.additional_options)?.testMode);
    }
    return orders;
  }

  setOrderStatus(id: number, status: OrderStatus) {
    let info = {
      order_status_id: status,
      order_id: id,
      fuel_rest: 0
    };
    return this.apiService.create(setStatusApiUrl, info);

  }

  createOrder(order: INewOrder) {
    return this.apiService.create(requestOrderCreateApiUrl, order);

  }

  changeOrderAddress(order: IOrder) {
    return this.apiService.create(requestOrderChangeAddressApiUrl, order);
  }

  setOrderStatusWithData(info: IChangeStatus) {

    return this.apiService.create(setStatusApiUrl, info);

  }


  async getPaymentInfoFromBankAsync(id: number) {
    const params = {
      orderId: id
    };

    const data = await this.apiService.getAllWParamsAsObject(requestPaymentInfoApiUrl, params).toPromise();
    return data;


  }

  getPaymentInfoFromBank(id: number) {
    const params = {
      orderId: id
    };


    return this.apiService.getAllWParamsAsObject(requestPaymentInfoApiUrl, params);


  }


  getOrderDetails(id: number) {
    const params = {
      orderId: id
    };

    return this.apiService.getAllWParamsAsObject(requestOrderDetailsApiUrl, params);
  }

  assignDriver(i: IAssignOrder) {
    return this.apiService.create(requestOrderAssignDriverApiUrl, i);
  }

  refreshOrders() {
    return this.apiService.getAll(requestOrdersFind).subscribe(data => {
      this.data = data.filter(dataK => dataK.order_active === 1);
      this.data.forEach(o => o.checkAddress = (o.lat == this.defaultLocation.latitude || o.lat == 55.0415) && (o.lon == this.defaultLocation.longitude || o.lon == 82.9346));
    });
  }

  getOrdersAll(startDate: Date, stopDate: Date): Observable<IApiListResponse<IOrders>> {
    const range: IRange = {
      start: moment(startDate).format('DD.MM.YYYY'),
      stop: moment(stopDate).format('DD.MM.YYYY')

    };

    return this.apiService.getAllWParamsAsObject(requestOrdersAllApiUrl, range);

  }

  getOrders(): IOrders[] {
    let result = [];
    this.apiService.getAll(requestOrdersFind).toPromise().then(data => {
      result = data.filter(dataK => dataK.order_active === 1);
      result.forEach(o => o.checkAddress = (o.lat == this.defaultLocation.latitude || o.lat == 55.0415) && (o.lon == this.defaultLocation.longitude || o.lon == 82.9346));
    });
    return result;
  }

  getOrdersFind() {
    return this.apiService.getAll(requestOrdersFind);
  }

  getReestrForBuh(date) {
    let params = {
      date: date
    };
    let result = [];
    return this.apiService.getAllWParamsAsObject(requestBuhReestrApiUrl, params).toPromise();
  }

  getColorByOrderStatus(order: IOrders) {
    if (order.order_status === 1) {
      return 'row-payment-order';
    }
    if (order.order_active === 1 && order.night_time === 1) {
      return 'row-night-order';
    }
    if (order.order_status === 2) {
      return 'row-new-order';
    }
    if (order.order_status === 3) {
      return 'row-assign-order';
    }
    if (order.order_status === -2) {
      return 'row-canceled-order';
    }
    if (order.order_status === 6) {
      return 'row-ok-order';
    }
    return '';
  }

  getTitleByOrderStatusKey(key: string) {
    switch (key) {
      case 'row-payment-order':
        return 'Ожидает оплаты';
      case 'row-night-order':
        return 'Ночной';
      case 'row-new-order':
        return 'Новый';
      case 'row-assign-order':
        return 'Назначен';
      case 'row-canceled-order':
        return 'Отменен сервисом';
      case 'row-ok-order':
        return 'Завершен';
      default:
        return '';
    }
  }
}

