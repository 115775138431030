<div *ngIf='form; else loading'>
  <div [formGroup]='form'>
    <mat-label>
      <h1>Настройки</h1>
    </mat-label>
    <mat-tab-group>
      <mat-tab formGroupName='contacts' novalidate label='Контакты'>
        <div fxFlex class='cards'>
          <mat-card fxFlex='50%'>
            <mat-form-field class='full-width'>
              <mat-label>Url</mat-label>
              <input matInput id='service_url' formControlName='service_url' placeholder='Введите адрес сайта'>
            </mat-form-field>
            <mat-form-field class='full-width'>
              <mat-label>Email</mat-label>
              <input matInput id='service_email' formControlName='service_email' placeholder='Введите Email'>
            </mat-form-field>
            <mat-form-field class='full-width'>
              <mat-label>Телефон</mat-label>
              <input matInput id='service_phone_number' formControlName='service_phone_number'
                     placeholder='Введите телефон'>
            </mat-form-field>
          </mat-card>
          <mat-card fxFlex='50%'>
            <mat-form-field class='full-width'>
              <mat-label>Телеграм</mat-label>
              <input matInput id='service_telegram' formControlName='service_telegram'
                     placeholder='Введите адрес сервиса'>
            </mat-form-field>
            <mat-form-field class='full-width'>
              <mat-label>WhatsApp</mat-label>
              <input matInput id='service_whatsapp' formControlName='service_whatsapp'
                     placeholder='Введите адрес сервиса'>
            </mat-form-field>
            <mat-form-field class='full-width'>
              <mat-label>Vkontakte</mat-label>
              <input matInput id='service_vk' formControlName='service_vk' placeholder='Введите адрес сервиса'>
            </mat-form-field>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab formGroupName='service' novalidate label='Сервис'>
        <div class='cards'>
          <mat-card fxFlex>
            <mat-card-content>
              <div class='service-dates'>
                <div class='service-dates_timepicker' fxFlex='30%'>
                  <div>
                    <mat-checkbox matInput id='is_work_day' formControlName='is_work_day'>
                      Выходной день
                    </mat-checkbox>
                  </div>
                  <mat-label>Начало рабочего дня</mat-label>
                  <ngx-timepicker-field matInput id='orders_handling_start_time'
                                        formControlName='orders_handling_start_time' [format]='24'
                                        [controlOnly]='true'>
                  </ngx-timepicker-field>
                  <mat-label>Окончание рабочего дня</mat-label>
                  <ngx-timepicker-field matInput id='orders_handling_end_time'
                                        formControlName='orders_handling_end_time' [format]='24' [controlOnly]='true'>
                  </ngx-timepicker-field>
                </div>
                <div class='service-dates_fields'>
                  <div class='service-dates_field'>
                    <span>Остановить сервис до:</span>
                    <p-calendar formControlName='service_stopped_to_date' showTime='true' dateFormat='dd.mm.yy'
                                appendTo='body'
                                hourFormat='24' [inputStyle]="{'height': '30px', 'width': '150px'}"></p-calendar>
                  </div>
                  <div class='service-dates_field'>
                    <mat-form-field class='full-width'>
                      <mat-label>Текст сообщения "Сервис остановлен..."</mat-label>
                      <textarea matInput id='service_stopped_message' formControlName='service_stopped_message'
                                placeholder='Сервис остановлен...'></textarea>
                    </mat-form-field>
                  </div>
                  <div class='service-dates_field'>
                    <span>Начало блокировки приема заказов:</span>
                    <p-calendar formControlName='start_blocking_orders' showTime='true' dateFormat='dd.mm.yy'
                                appendTo='body'
                                hourFormat='24' [inputStyle]="{'height': '30px', 'width': '150px'}"></p-calendar>
                  </div>
                  <div class='service-dates_field'>
                    <span>Окончание блокировки приема заказов:</span>
                    <p-calendar formControlName='end_blocking_orders' showTime='true' dateFormat='dd.mm.yy'
                                appendTo='body'
                                hourFormat='24' [inputStyle]="{'height': '30px', 'width': '150px'}"></p-calendar>
                  </div>
                  <div class='service-dates_field'>
                    <mat-checkbox matInput id='allow_night_orders' formControlName='allow_night_orders'>
                      Разрешить ночные заказы
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card fxFlex class='card service-messages'>
            <mat-card-title>
              Сервисные сообщения
            </mat-card-title>
            <mat-card-content>
              <mat-form-field class='full-width'>
                <mat-label>Текст сообщения "Заказ будет обработан завтра..."</mat-label>
                <textarea matInput id='order_will_be_handled_tomorrow'
                          formControlName='order_will_be_handled_tomorrow'
                          placeholder='Заказ будет обработан завтра'></textarea>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Текст сообщения "Ночная заправка..."</mat-label>
                <textarea matInput id='night_time_tankup_message' formControlName='night_time_tankup_message'
                          placeholder='Ночная заправка...' height='100px'></textarea>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab formGroupName='auto_assignment' novalidate label='Распределение заказов'>
        <div class='cards'>
          <mat-card fxFlex>
            <mat-card-subtitle>
              <mat-form-field appearance='fill'>
                <mat-label>Режим</mat-label>
                <mat-select placeholder='Режим' matInput id='status' formControlName='status'>
                  <mat-option *ngFor='let item of autoAssignData | enumToArray' [value]='item.key'>
                    {{item.value}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-card-subtitle>
            <mat-card-content>
              <mat-form-field class='full-width'>
                <mat-label>Радиус</mat-label>
                <input matInput id='baseRadius' formControlName='baseRadius' placeholder='Введите радиус'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Шаг радиуса</mat-label>
                <input matInput id='radiusStep' formControlName='radiusStep'
                       placeholder='Введите шаг увеличения радиуса'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Мин. количество заказов бустера</mat-label>
                <input matInput id='boosterOrdersCountPrefer' formControlName='boosterOrdersCountPrefer'
                       matTooltip='Предпочтительное количество заказов бустера'
                       placeholder='Введите количество заказов бустера'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Макс. радиус</mat-label>
                <input matInput id='maxRadius' formControlName='maxRadius'
                       placeholder='Введите максимальный радиус'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Макс. количество заказов бустера</mat-label>
                <input matInput id='boosterOrdersCountSkip' formControlName='boosterOrdersCountSkip'
                       placeholder='Введите макс. количество заказов бустера'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Радиус остановки распределения</mat-label>
                <input matInput id='stopRadius' formControlName='stopRadius'
                       placeholder='Введите радиус остановки распределения'>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>

      <mat-tab formGroupName='sys_load' novalidate label='Расчет загрузки сервиса'>
        <div class='cards'>
          <mat-card fxFlex formGroupName='weights'>
            <mat-card-subtitle>
            </mat-card-subtitle>
            <mat-card-content>
              <mat-form-field class='full-width'>
                <mat-label>Boosters</mat-label>
                <input matInput id='boosters' formControlName='boosters' placeholder='Boosters'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Delay</mat-label>
                <input matInput id='delay' formControlName='delay' placeholder='Delay'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>Orders</mat-label>
                <input matInput id='orders' formControlName='orders' matTooltip='Orders'
                       placeholder='Orders'>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
          <mat-card formGroupName='defaults'>
            <mat-card-subtitle>
            </mat-card-subtitle>
            <mat-card-content>
              <mat-form-field class='full-width'>
                <mat-label>default_delay</mat-label>
                <input matInput id='defaultDelay' formControlName='defaultDelay'
                       placeholder='defaultDelay'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>orders_limit_per_booster</mat-label>
                <input matInput id='ordersLimitPerBooster' formControlName='ordersLimitPerBooster'
                       placeholder='ordersLimitPerBooster'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>avg_orders_per_hour</mat-label>
                <input matInput id='avgOrdersPerHour' formControlName='avgOrdersPerHour'
                       matTooltip='avgOrdersPerHour' placeholder='avgOrdersPerHour'>
              </mat-form-field>
              <mat-form-field class='full-width'>
                <mat-label>radius_around_coord</mat-label>
                <input matInput id='radiusAroundCoord' formControlName='radiusAroundCoord'
                       matTooltip='radiusAroundCoord' placeholder='radiusAroundCoord'>
              </mat-form-field>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab novalidate label='Заказы' style="display: none;">
        <div fxFlex class='cards'>
          <mat-card fxFlex='50%' style='height: 300px;'>
            <mat-card-title>
              Цвет статуса заказа
            </mat-card-title>
            <mat-card-content>
              <div *ngFor='let color of orderColors' class='status_color'>
                <span>{{service.getTitleByOrderStatusKey(color.key)}}</span>
                <input
                  [colorPicker]='color.value'
                  (colorPickerChange)='color.value = $event'
                  [style.background]='color.value'
                  style='width: 70px' />
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div class='save-button'>
      <button type='submit' mat-raised-button color='primary' [disabled]='form.invalid' (click)='submit()'>
        Сохранить
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <p class='text-center'>загрузка ...</p>
</ng-template>
