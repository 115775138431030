<!--
<div>
  <span class="example-fill-remaining-space"></span>
  <span><button mat-icon-button (click)="snav_o.toggle()">
      <mat-icon>list</mat-icon>
    </button></span>
    
  <span *ngIf="selected.size > 0" style="margin-bottom: 5px">
    <span>Выбранно: <b>{{selected.size}}</b></span>
    <button mat-flat-button matTooltip="Массово Назначить" style="margin-left: 5px;
                    background-image: linear-gradient(213deg, rgb(255, 189, 0) 0%, rgb(255, 191, 48) 100%);"
      [disabled]="selected.size===0" (click)="openAssignDialog(0, true)">
      <i class="material-icons">
        time_to_leave
      </i>
      Назначить
    </button>

  </span>
</div>
-->
<!--*ngIf="!isMobile; else mobileSidebar"

<mat-sidenav-container  class="sidenav-container" hasBackdrop="false">
    <mat-sidenav class="mat-drawer-side" #snav_o mode="over" position="start" [opened]="!isMobile">
      <mat-nav-list  style="display: flex;
                          flex-wrap: wrap;
                          position: relative;
                          width: 540px;
                          box-sizing: border-box;
                          padding: 15px 13px;" [ngStyle]="{'width': getWidth()+'px'}">

-->
<div *ngIf='dataAll.length>0' fxLayout='row' fxLayoutAlign='center center' fxLayoutGap='5px'>

  <mat-button-toggle-group fxFlex fxLayout='row wrap' fxLayoutAlign='left' fxLayoutGap='5px'
                           #group='matButtonToggleGroup' class='status-filter' appearance='legacy' multiple='true'
                           [value]='currentStatusFilter' (change)='applyOrderStatusFilter(group.value)'>
    <mat-button-toggle *ngFor='let ordFlt of ordersByStatus' [value]='ordFlt?.statusId'
                       [style.background]='ordFlt?.color'>{{ ordFlt?.statusName }}({{ ordFlt?.count }})
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-button-toggle-group #group2='matButtonToggleGroup' name='fltClientType' appearance='legacy'
                           [value]='clientTypeFilter' (change)='applyClientTypeFilter(group2.value)'>
    <mat-button-toggle value='-1'>Все</mat-button-toggle>
    <mat-button-toggle value='0'>Физ. лица</mat-button-toggle>
    <mat-button-toggle value='1'>Юр. лица</mat-button-toggle>
  </mat-button-toggle-group>

  <div fxLayout='column' fxFlex='1 1 80px' fxLayoutGap='5px'>
    <div fxFlex='1 1 30px' fxLayoutAlign='left center ' class='night-time-flt'>
      <input type='checkbox' [indeterminate]="isNightFlt?.status === 'crossed'"
             [ngModel]="isNightFlt?.status === 'checked'" (ngModelChange)='checkIsNightFlt(isNightFlt)'> Ночная заправка
    </div>
    <button mat-raised-button color='primary' (click)='snav_o.toggle()' *ngIf='isMobile'>
      <mat-icon *ngIf='snav_o.opened'>location_on</mat-icon>
      <mat-icon *ngIf='!snav_o.opened'>location_off</mat-icon>
    </button>
  </div>
</div>


<mat-sidenav-container hasBackdrop='false'>

  <mat-sidenav [ngClass]="{'nav-sidenav-desktop':!isMobile}" class='mat-drawer-side' #snav_o mode='push'
               [opened]='showOrdersPanel'>

    <mat-nav-list [ngClass]="{'nav-list-desktop-view':!isMobile}">

      <ngx-table fxLayout='column' [configuration]='configuration' [data]='dataFiltered' [columns]='columns'
                 (event)='eventEmitted($event)' class='orders-list_fixed-headers'>

        <ng-template let-row let-index='index'>
          <td class='order-card'>
            <!--            *ngFor="let order of dataAll"-->
            <div class='all-orders' [ngStyle]="{ 'backgroundColor': settings.getColorByOrderStatus(row)}">
              <div fxLayout='column' fxLayoutGap='5px'>
                <div fxLayout='row' style='font-size: smaller;' fxLayoutAlign='space-between' fxLayoutGap='10px'>
                  <span fxFlex='10%' [ngClass]="{'low-headers' : row.order_status !== 3 ,
                              'low-headers-open' : row.order_status === 3 }">{{row.id}} </span>
                  <span fxFlex><b>Статус</b>: {{ getStatusOrder(row.order_status) }}</span>
                  <span fxFlex='28%'>{{row.fuel_name}} ( {{row.liters}} л.)</span>
                  <span fxFlex='22%' fxLayoutAlign='end' style='font-size: x-small;' [ngClass]="{'low-headers' : row.order_status !== 3 ,
                  'low-headers-open' : row.order_status === 3 }">{{row.created_at}} </span>

                </div>
                <div *ngIf='row.night_time' fxLayoutAlign='center' class='night_tankup'> НОЧНАЯ ЗАПРАВКА</div>
                <span fxFelx class='check_address' *ngIf='row.checkAddress===true'>Уточните адрес</span>

                <div fxLayout='row' fxLayoutAlign='center center'>
                  <app-ymaps-adres fxFlex='1 1 fill' matTooltip='{{row.address}}' [id]='row.id' [lat]='row.lat'
                                   [lon]='row.lon'></app-ymaps-adres>


                  <button fxFlex='0 0 30px' style='height:30px;' fxLayoutAlign='center center'
                          *ngIf="auth.isVisible('order','assign')" mat-flat-button [disabled]='row.order_status >= 4'
                          (click)='changeAddress(row.lat, row.lon, row.id)'>
                    <mat-icon matTooltip='Изменить адрес'>
                      gps_fixed
                    </mat-icon>
                  </button>

                </div>
              </div>

              <div #orderCard fxLayout='row' style='width: 100%;margin-top: 5px;'>

                <div fxFlex>
                  <div>{{row.car_model}} № {{row.car_no}}
                    <div *ngIf='row.car_color'>({{row.car_color}})</div>
                  </div>
                  <p>{{row.phone_number}} ({{row.client_name}} )</p>
                  <div>
                    <div *ngIf='row.driver?.id; else noDriver'><b>Заправщик</b> : {{driverInfo(row.driver?.id)}}</div>

                    <div *ngIf='row.date_delivery; else timeNull' class='card_item'><b>Плановое время</b>:
                      {{ row.date_delivery }}</div>
                  </div>

                  <div class='card_item'>
                    <div class='text-ellipsis' matTooltip='{{row.comment}}'>
                      {{row.comment | slice:0:50}}
                    </div>
                  </div>
                </div>

                <div fxFlex='0 0 25%'>


                  <div fxLayout='column' fxLayoutAlign='center end'>
                    <button *ngIf="auth.isVisible('order','assign')" class='order-action main-btn' mat-flat-button
                            [disabled]='row.order_active === 0' (click)='openAssignDialog(row.id,false)'>
                      <mat-icon *ngIf='row.order_status<3' class='material-icons' matTooltip='Назначить'>
                        time_to_leave
                      </mat-icon>
                      <mat-icon *ngIf='row.order_status>2' class='material-icons' matTooltip='Переназначить'>
                        repeat
                      </mat-icon>
                      <span *ngIf='!isMobile; then assign'></span>
                      <ng-template #assign>
                        <span *ngIf='row.order_status<3; else reassign'>Назначить</span>
                        <ng-template #reassign>
                          <span>Переназначить</span>
                        </ng-template>
                      </ng-template>
                    </button>
                    <button fxFlex *ngIf="auth.isVisible('order','complete')" class='order-action' mat-flat-button
                            [disabled]='row.order_active ===0' (click)='complete(row.id)'>
                      <mat-icon class='material-icons' matTooltip='Завершить'>
                        beenhere
                      </mat-icon>
                      <span *ngIf='!isMobile'>Завершить</span>
                    </button>
                    <button fxFlex *ngIf="auth.isVisible('order','cancel')" class='order-action' mat-flat-button
                            [disabled]='row.order_active === 0 && row.order_status!=1' (click)='canceled(row.id)'>
                      <mat-icon class='material-icons' matTooltip='Отменить'>
                        format_color_reset
                      </mat-icon>
                      <span *ngIf='!isMobile'>Отменить</span>
                    </button>
                    <!--<mat-checkbox [disabled]="row.order_status!==2 && row.order_status!==3"
                    (change)="onChangeCheck(row,$event)">Выбрать</mat-checkbox>-->
                  </div>
                  <p> Доп товары:</p>

                  <div *ngFor="let prod of row.sellings | filter : true : 'is_optional' ">
                    {{prod?.name}} {{prod?.amount}} {{prod?.measure}}
                  </div>
                </div>

              </div>

              <!--          <mat-card-actions>-->

              <!--          </mat-card-actions>-->
            </div>
          </td>
        </ng-template>
      </ngx-table>
    </mat-nav-list>

  </mat-sidenav>
  <mat-sidenav-content class='child-container'>
    <div *ngIf='dataDriver; else loading'>
      <div id='map'>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #noDriver>
  <b>Не назначен</b>
</ng-template>

<ng-template #timeNull>
  <br>
  <b>Плановое время: нет </b>
</ng-template>

<ng-template #loading>
  <p>загрузка данных</p>
</ng-template>

<ng-template #noOrders>
  <span>Нет заказов</span>
</ng-template>


