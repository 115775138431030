import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';

import { ClientsService } from 'src/app/services/clients.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';
import { IBooster } from 'src/app/shared/interfaces';
import { BoostersService } from 'src/app/services/boosters.service';

@Component({
  selector: 'app-booster-dialog',
  templateUrl: './booster-dialog.component.html',
  styleUrls: ['./booster-dialog.component.scss']
})
export class BoosterDialogComponent extends DialogEntityBaseComponent<IBooster> implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: BoostersService

  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }
  public getSubmitRoute(): string {
    return '/boosters';
  }
  public getCancelRoute(): string {
    return '/boosters';
  }
  protected getData(id: number) {
    return this.service.getBooster(id)
  }

  protected saveData(Entity: IBooster) {
    return this.service.saveBooster(Entity)
  }
 
  protected newEntity(){
    var item = <IBooster> super.newEntity();
    
    item.name ='';
    item.is_active =false;
    
    return item;
  }
  protected _generateUserForm(data: IBooster): void {
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      is_active: [data.is_active],
      
    });
    super._generateUserForm(data);
    
  }
  protected _composeChanges(){
    
     let result = super._composeChanges();
     if (result){
       this.Entity = this.entityForm.value;
     }
     return result;
  }
  protected _setCheckBoxState() {

    if (this.readonly) {
      
      this.entityForm.get('name').disable();
      this.entityForm.get('is_active').disable();
      
    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('is_active').enable();
    }

  }

}
