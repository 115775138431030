import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainLayoutComponent} from './main/main-layout/main-layout.component';
import {HomePageComponent} from './pages/home-page/home-page.component';

import {OrderPageComponent} from './pages/order-page/order-page.component';
import {DriverPageComponent} from './pages/driver-page/driver-page.component';
import {SettingPageComponent} from './pages/setting-page/setting-page.component';
import {ProductComponent} from './product/product.component';
import {MapjsPageComponent} from './pages/mapjs-page/mapjs-page.component';
import {ChatroomPageComponent } from './pages/chatroom-page/chatroom-page.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { AppComponent } from './app.component';
import { AuthGuardService } from './services/auth.guard.service';
import { Role } from './models/role';

import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ClientsPageComponent } from './pages/clients-page/clients-page.component';
import { FirmDialogComponent } from './dialogs/firm-dialog/firm-dialog.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { ProductDialogComponent } from './dialogs/product-dialog/product-dialog.component';
import { BoosterDialogComponent } from './dialogs/booster-dialog/booster-dialog.component';
import { AccountDialogComponent } from './dialogs/account-dialog/account-dialog.component';
import { BoostersPageComponent } from './pages/boosters-page/boosters-page.component';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { ReportDialogComponent } from './dialogs/report-dialog/report-dialog.component';
import { ReportPageComponent } from './pages/report-page/report-page.component';
import { PromoPageComponent } from './pages/promo-page/promo-page.component';
import { PromoDialogComponent } from './dialogs/promo-dialog/promo-dialog.component';
import { ReportUniversalComponent } from './report-universal/report-universal.component';
import { BuyersPageComponent } from './pages/buyers-page/buyers-page.component';
import { BuyersDialogComponent } from './dialogs/buyers-dialog/buyers-dialog.component';
import { ModelsPageComponent } from './pages/models-page/models-page.component'
import { ModelDialogComponent } from './dialogs/model-dialog/model-dialog.component'

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent,  children: [
      {path: '', redirectTo: '/', pathMatch: 'full'},
      {path: '', component: HomePageComponent, data:{title: 'Главная'}},
      {path: 'map', component: MapjsPageComponent, data:{title: 'Карта'}},
      {path: 'orders', component: OrderPageComponent, data:{title: 'Заказы'}},
      {path: 'boosters', component: BoostersPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Бустеры'} },
      {path: 'models', component: ModelsPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Модели'} },
      {path: 'accounts', component: AccountsPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Пользователи'} },
      {path: 'settings', component: SettingPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin],title: 'Настройки'} },
      {path: 'products', component: ProductsPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Продукты'} },
      {path: 'reports', component: ReportPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin],title: 'Отчеты'} },
      {path: 'chats', component: ChatroomPageComponent, data:{title: 'Чат'}},
      //{path: 'statistics', component: StatisticsPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin]} },
      {path: 'statistics', component: ReportUniversalComponent, canActivate:[AuthGuardService],data:{roles:[Role.Analitycs],title: 'Статистика'} },
      
      {path: 'clients', component: ClientsPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.ClientManager],title: 'Юр. лица'} },
      {path: 'buyers', component: BuyersPageComponent, data:{title: 'Физ. лица'}},
      {path: 'promocodes', component: PromoPageComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin],title: 'Промокоды'} },
      {path: 'productDetail/:id', component: ProductDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Продукт'} },
      {path: 'firmDetail/:id', component: FirmDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.ClientManager],title: 'Клиент'} },
      {path: 'boosterDetail/:id', component: BoosterDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Бустер'} },
      {path: 'modelsDetail/:id', component: ModelDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Модель'} },
      {path: 'accountDetail/:id', component: AccountDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.Admin],title: 'Пользователь'} },
      {path: 'reportDetail/:id', component: ReportDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin],title: 'Отчет'} },
      {path: 'promoDetail/:id', component: PromoDialogComponent, canActivate:[AuthGuardService],data:{roles:[Role.SuperAdmin],title: 'Промо'} },
      {path: 'buyerDetail/:id', component: BuyersDialogComponent, data:{title: 'Клиент'}},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
