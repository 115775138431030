import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { Observable } from 'rxjs';
import { IBooster } from '../shared/interfaces';
//API 
const getGetAllItemsApiUrl='manager/driver/all';
const getGetItemApiUrl='booster/{id}/info';
const getSetItemApiUrl='booster';
const getGetAllBoostersApiUrl='boosters';
const getAutoAssignedBoosterApiUrl = "manager/order/{id}/assign/auto/info";
@Injectable({
  providedIn: 'root'
})
export class BoostersService {

  constructor(private apiService:ApiV5Service) { }
  
  public getAll()
  {
    return this.apiService.getAllAsObject(getGetAllItemsApiUrl);

  }
  public getAllBoosters()
  {
    return this.apiService.getAllAsObject(getGetAllBoostersApiUrl);

  }


  getAutoAssignedBooster(id: number) {
    return this.apiService.getEntity( getAutoAssignedBoosterApiUrl.replace("{id}",String( id)));
  }
  getBooster(id: number): Observable<IBooster> {
    return this.apiService.getEntity( getGetItemApiUrl.replace("{id}",String( id)));
  }
  saveBooster(entity: IBooster): Observable<IBooster> {
    
    
    return this.apiService.create(getSetItemApiUrl, entity)
  }


}
