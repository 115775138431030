import { Injectable } from '@angular/core';
import {HttpClient, HttpParams,HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiV5Service {

  constructor(
    private http: HttpClient,
    private auth:AuthService
    ) { }
  appendSessionParams(params:HttpParams){
    let city = this.auth.user_city;
    if (city)
      params = params.append('city',city.toString());
    return params;
    
  }
  setSessionParamsToBody(body:any){
    if (!body) body = {}
    let city = this.auth.user_city
    if (city)
      body.city=city;
    
  }
  getAll(apiUrl: string, param?: string) {
    var params = new HttpParams({fromString: param});
    params = this.appendSessionParams(params);
    
    return this.http.get(`${environment.apiUrl}/api/v5/${apiUrl}/`, { params: params })
      .pipe(
        map((response: {[key: string]: any}) => {
          return Object.keys(response)
            .map(key => ({
              ...response[key]
            }));
        }));
  }
  getAllAsObject(apiUrl: string, param?: string) {
      let params = new HttpParams({fromString: param});
      params= this.appendSessionParams(params);
    return this.http.get(`${environment.apiUrl}/api/v5/${apiUrl}/`, { params: params })
    .pipe(map((data: any) => {
      return {
        ...data
      };
    }));
     
  }
  getAllWParamsAsObject(apiUrl: string, param?: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    this.setSessionParamsToBody(param);
    return this.http.post(`${environment.apiUrl}/api/v5/${apiUrl}/`, param)
    .pipe(map((data: any) => {
      return {
        ...data
      };
    }));
     
  }

  getAllWParams(apiUrl: string, param?: any) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = param
    this.setSessionParamsToBody(params);
    //const params = new HttpParams().set("requestData", encodeURIComponent(JSON.stringify(param)));

    //const options = { header: headers, params:params };
    return this.http.post(`${environment.apiUrl}/api/v5/${apiUrl}/`, params)
      .pipe(
        map((response: {[key: string]: any}) => {
          return Object.keys(response)
            .map(key => ({
              ...response[key]
            }));
        }));
  }
  create(apiUrl: string, data: any):
    Observable<any> {
      this.setSessionParamsToBody(data);
    return this.http.post(`${environment.apiUrl}/api/v5/${apiUrl}/`, data);
  }
  save(apiUrl: string, data: any):
  Observable<any> {
    this.setSessionParamsToBody(data);
  return this.http.put(`${environment.apiUrl}/api/v5/${apiUrl}/`, data);
}
  remove(apiUrl: string, id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/v5/${apiUrl}/${id}/`);
  }
  getById(apiUrl: string, id: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/v5/${apiUrl}`)
      .pipe(map((data: any) => {
        return {
          ...data, id
        };
      }));
  }
  getEntity(apiUrl: string): Observable<any> {
    //var params =  new HttpParams();
    //this.setSessionParams(params);
    return this.http.get<any>(`${environment.apiUrl}/api/v5/${apiUrl}/`)
      .pipe(map((data: any) => {
        return {
          ...data
        };
      }));
  }

}
