import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'ui-message-angular';
import { BaseListPageComponent } from 'src/app/base/base-list-page/base-list-page.component';
import { IReport } from 'src/app/shared/interfaces';
import { ReportsService } from 'src/app/services/report.service';
import { SpinnerComponent } from 'src/app/base/spinner/spinner.component';

@Component({
  selector: 'app-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent extends BaseListPageComponent<IReport> implements OnInit {
  displayedColumns = ['id', 'is_active', 'name', 'category', 'type', 'version', 'author', 'actions'];


  DetailRoute = 'reportDetail';

  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    private service: ReportsService,
    protected router: Router,
    protected messageService: MessageService,
    
  ) {
    super(
      dialog,
      settings,
      auth,
      router,
      messageService,
    
    );
    this.Title = 'Отчеты';
  }

  protected getData() {
    return this.service.getReports();
  }
}
