import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IClientInfo, INewOrder, IProduct, IVehicle } from 'src/app/shared/interfaces';
import { ProductService } from 'src/app/services/product.service';
import { ClientsService } from 'src/app/services/clients.service';
import { OrdersService } from 'src/app/services/orders.service';
import { SettingsService } from 'src/app/services/settings.service';
import { BoostersService } from 'src/app/services/boosters.service';
import { round } from 'lodash';
import { NotifierService } from 'angular-notifier';
import ymaps from 'ymaps';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-create-order-dialog',
  templateUrl: './create-order-buyers-dialog.component.html',
  styleUrls: ['./create-order-buyers-dialog.component.scss']
})

export class CreateOrderBuyersDialogComponent implements OnInit {
  private readonly notifier: NotifierService;
  tip: string;
  isDisabledFields = true;
  isDisabledClientFields = true;
  callMeBeforeMins = false;
  callMeBeforeMinsValue = 0;
  fullTank = false;
  form: FormGroup;
  description: string;
  fuel: IProduct;
  client: any;
  vehicle: IVehicle;
  testMode = true;

  order: INewOrder = {
    vehicle: null,
    lat: 0,
    lng: 0,
    comment: null,
    night_time: null,
    address: null,
    contact_number: null,
    sellings: [{
      id: null,
      price: null,
      amount: null,
      summ: 0.0,
      paymentAmount: 0.0,
      paymentSum: 0.0,
      discountAmount: 0.0,
      discountSumm: 0.0,
      balanceAmount: 0.0,
      balanceSum: 0.0,
      rebateAmount: 0.0,
      rebateSum: 0.0,
      rebatePercent: 0.0
    }],
    additional_options: {
      fullTank: false,
      callMeBeforeMins: 0
    },
    date_delivery: null
  };
  bounds: [[54.58890871451359, 81.68754410607335], [55.35438409561197, 84.32426285607332]];
  clients: IClientInfo[];
  products: IProduct[];
  cars: IVehicle[];
  addressSuggestView: any;
  maps: ymaps;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private productsService: ProductService,
    private settingsService: SettingsService,
    private clientsService: ClientsService,
    private ordersService: OrdersService,
    private boostersService: BoostersService,
    private notifierService: NotifierService,
    private dialogRef: MatDialogRef<CreateOrderBuyersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
    this.notifier = notifierService;
    this.description = 'Новый заказ';
    // this.description = data.description;
  }

  ngOnInit() {
    this.form = this.fb.group({
      volume: [this.order.sellings[0].amount, ''],
      summ: [this.order.sellings[0].summ, ''],
      client: [this.client, ''],
      car_no: [this.order.car_no, ''],
      car_model: [this.order.car_model, ''],
      phone_number: [this.order.contact_number, ''],
      address: [this.order.address, ''],
      date_delivery: [this.order.date_delivery, new Date()],

      //      car: [this.vehicle, ''],

      comment_text: [this.order.comment, '']

    });
    ymaps
      // .load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU')
      .load(this.settingsService.getMapInitUrl())
      .then(maps => {
        this.maps = maps;

        const city = 'город' + this.settingsService.getCurrentCity().name;
        this.addressSuggestView = new maps.SuggestView('suggest_address', {
            provider: {
              suggest: ((request, options) => maps.suggest(city + ', ' + request))
            }
            // boundedBy: this.bounds
          }
        );
      });

    this.productsService.getProducts().subscribe(d => {
      this.products = d.products.filter(i => i.is_optional === false && i.is_active === true);
    });
    // this.clientsService.getFirms().subscribe(f => {
    //   this.clients = f.list;
    //
    // });

  }

  save() {
    const ord: INewOrder = this.order;
    const address = this.addressSuggestView.state._data.request;
    ord.address = address;
    ord.comment = this.form.value.comment_text;
    ord.contact_number = this.form.value.phone_number;
    ord.car_no = this.form.value.car_no;
    ord.car_model = this.form.value.car_model;
    ord.additional_options = {
      testMode: this.auth.isRole(['SuperAdmin']) ? this.testMode : false,
      fullTank: this.fullTank,
      callMeBeforeMins: this.callMeBeforeMins ? this.callMeBeforeMinsValue : 0
    };
    ord.payment_info = {
      paymentStatus: 1,
      paymentAuthStatus: 'success',
      paymentType: 'Cash',
      paymentId: null,
      paymentErrorCode: null,
      paymentMessage: null,
      paymentAppleTransactionId: null,
      paymentApplePaymentData: null,
      paymentLog: null
    };
    ord.date_delivery = this.form.value.date_delivery;

    if (this.client.id) {
      ord.clientId = this.client.id;
    } else {
      ord.clientId = -1;
      ord.sellings[0].rebatePercent = 5.0;
      ord.sellings[0].paymentAmount = ord.sellings[0].amount * 0.95;
      ord.sellings[0].paymentSumm = ord.sellings[0].paymentAmount * ord.sellings[0].price;
      ord.sellings[0].rebateAmount = ord.sellings[0].amount * 0.05;
      ord.sellings[0].rebateSum = ord.sellings[0].rebateAmount * ord.sellings[0].price;
    }
    ord.clientName = this.client.name;
    const geocoder = new this.maps.geocode(address).then(res => {
        if (res.metaData.geocoder.found > 0) {
          const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
          ord.lat = coordinates[0];
          ord.lng = coordinates[1];

        } else {
          const city = this.settingsService.getCurrentCity();
          if (city) {
            ord.lat = city.default_location.longitude;
            ord.lng = city.default_location.latitude;
          }
        }

        console.log(ord);
        this.ordersService.createOrder(ord).subscribe(response => {
            if (response.code < 0) {
              this.notifier.notify('error', response.message);
            } else {
              this.notifier.notify('success', 'Заказ создан');

              this.dialogRef.close(this.form.value);
            }
          }, error => {
            this.notifier.notify('error', 'Ошибка создания заказ (см. логи для подробностей');
            // console.log(error);
          }
        );

      },
      function(err) {
        this.notifier.notify('error', 'Ошибка создания заказ ' + err);
      }
    );

  }

  close() {
    this.dialogRef.close();
  }

  isPhoneFilled() {
    const phoneNumber = this.form.get('phone_number').value;
    return phoneNumber && phoneNumber.length !== 10;
  }

  public onChangeClient(e) {
    const name = e.target.value;
    this.client = {
      ...this.client,
      name
    };
    this.isDisabledFields = !name;
    this.tip = '';
  }

  public onChangePhone() {
    this.isDisabledFields = true;
    this.isDisabledClientFields = true;
    if (this.client) {
      this.tip = 'Изменен номер телефона. Выполните поиск';
    }
  }

  public applyPhoneFilter() {
    const phoneNumber = this.form.get('phone_number').value;
    let filteredClients = [];
    this.clientsService.getClients(phoneNumber).subscribe(f => {
      filteredClients = f.list;
      if (filteredClients.length) {
        this.client = filteredClients[0];
        this.isDisabledFields = false;
        this.isDisabledClientFields = true;
        this.tip = '';
        this.clientsService.getCars(this.client.id).subscribe(c => {
          this.cars = c.list;
          if (this.cars.length === 1) {
            this.form.patchValue({
              car_model: this.cars[0].model,
              car_no: this.cars[0].reg_number
            });
          }
        });

      } else {
        this.resetForm();
        this.isDisabledFields = true;
        this.isDisabledClientFields = false;
        this.tip = 'Заказчик не найден. Проверьте правильность номера телефона либо введите данные заказчика вручную';
      }
    });
  }

  resetForm() {
    // Очистка всех полей формы кроме номера телефона
    this.client = null;
    ymaps
      // .load('https://api-maps.yandex.ru/2.1/?apikey=ac649a94-c0c8-4337-a46f-32617991bbe4&lang=ru_RU')
      .load(this.settingsService.getMapInitUrl())
      .then(maps => {
        this.maps = maps;

        const city = 'город' + this.settingsService.getCurrentCity().name;
        this.addressSuggestView = new maps.SuggestView('suggest_address', {
            provider: {
              suggest: ((request, options) => maps.suggest(''))
            }
            // boundedBy: this.bounds
          }
        );
      });
    this.fuel = null;
    this.order.sellings[0].price = null;
    this.form.patchValue({
      volume: '',
      summ: '',
      client: '',
      car_no: '',
      car_model: '',
      address: '',
      comment_text: ''
    });
  }

  setFuel(product) {
    if (product) {
      this.order.sellings[0].id = product.id;
      this.order.sellings[0].price = product.prices[0].price;
      this.calcVolume();
    }
  }

  setFullTank(checked) {
    this.fullTank = checked;
    this.order.sellings[0].summ = 3000;
    this.form.patchValue({ summ: 3000 });
    this.calcVolume();
  }

  setVolume(event) {
    if (event.target.value) {
      this.order.sellings[0].amount = event.target.value;
      this.order.sellings[0].paymentAmount = event.target.value;
      this.calcOrderSumm();
    }
  }

  setOrderSum(event) {
    if (event.target.value) {
      this.order.sellings[0].summ = event.target.value;
      this.order.sellings[0].paymentSum = event.target.value;
      this.calcVolume();
    }
  }

  setVehicle(event) {
    if (event.value) {
      this.order.vehicle = event.value.id;
    }
  }

  calcOrderSumm() {
    const orderSumm = round(this.form.get('volume').value * this.order.sellings[0].price, 2);
    this.form.patchValue({ summ: orderSumm });
    this.order.sellings[0].summ = orderSumm;
    this.order.sellings[0].paymentSum = orderSumm;
  }

  calcVolume() {
    const orderVolume = round(this.form.get('summ').value / this.order.sellings[0].price, 2);
    this.form.patchValue({ volume: orderVolume });
    this.order.sellings[0].amount = orderVolume;
    this.order.sellings[0].paymentAmount = orderVolume;

  }
}



