import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promo-dialog',
  templateUrl: './promo-dialog.component.html',
  styleUrls: ['./promo-dialog.component.scss']
})
export class PromoDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
