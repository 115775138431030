import { Injectable } from '@angular/core';
import { ApiV5Service } from '../shared/services/apiV5.service';
import { Observable } from 'rxjs';
import { IAccount } from '../shared/interfaces';
export enum UserType {
  Manager = 1,
  Driver = 2,
  
  
} 
export const UserTypeLabel = new Map<number, string>([
  [UserType.Manager, 'Менеджер'],
  [UserType.Driver, 'Водитель'],
  
]);
//API 
const getGetAllItemsApiUrl='accounts/all';
const getGetItemApiUrl='accounts/{id}/info';
const getSetItemApiUrl='account';
const SetPasswordApiUrl='account/password';
@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private apiService:ApiV5Service) { }
  public SetPassword(account:IAccount, password: string){
    var item = {
      id: account.id,
      login: account.login,
      password: password
    };
    
    return this.apiService.create(SetPasswordApiUrl,item);
  }
  public getAll()
  {
    return this.apiService.getAllAsObject(getGetAllItemsApiUrl);

  }
  
  getAccount(id: number): Observable<IAccount> {
    return this.apiService.getEntity( getGetItemApiUrl.replace("{id}",String( id)));
  }
  saveAccount(entity: IAccount): Observable<IAccount> {
    
    
    return this.apiService.create(getSetItemApiUrl, entity)
  }

}
