import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatTableDataSource, MatDialog, MatPaginator, MatSort, Sort } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { Message, MessageService,messageType } from 'ui-message-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-base-list-page',
  templateUrl: './base-list-page.component.html',
  styleUrls: ['./base-list-page.component.scss']
})
export class BaseListPageComponent<T> implements OnInit {
  Title: string;
  allowAdd: boolean = true;
  clientFilter: boolean = true;
  filterValue: string='';
  DetailRoute: string = '/';
  dataSource: MatTableDataSource<T>;
  public showDeletionConfirmation = false;
  public toBeDeleted: string;
  
  get displayDeletionConfirmation() {return this.showDeletionConfirmation ? 'block' : 'none'; }
  pageSize:number;
  @ViewChild(MatPaginator, { static: true }) _paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) _matSort: MatSort;
  @ViewChild(SpinnerComponent, { static: true }) _spinner: SpinnerComponent;
  
  protected paginator():MatPaginator {
    return this._paginator;
  }
  protected mstSort():MatSort {
    return this._matSort;
  }
  protected spinner():SpinnerComponent {
    return this._spinner;
  }

  constructor(
    protected dialog: MatDialog,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected router: Router,
    protected messageService: MessageService,
    

  ) { }

  public applyFilter(value: string='') {
    if (!value) 
      value = this.filterValue;
    value = value.trim().toLowerCase();
    if (this.dataSource)
      this.dataSource.filter = value;
    if (!this.clientFilter)
       this.refresh();
  }

  protected getData(filter: string='') {
    return null;
  }

  ngOnInit(): void {
    this.pageSize = this.settings.journalPageSize(this.constructor.name);
    this._paginator.pageSize = this.pageSize;
    this.refresh();

  }
  protected getFilter():string{
   let filter = this.filterValue;
   return filter;
  }
  protected getDataSource(data) {
    return new MatTableDataSource<T>(data.list);
  }
  protected showSpinner(){
    if (this._spinner) {
      this._spinner.show();
    }
  }
  protected hideSpinner(){
    if (this._spinner) {
      this._spinner.hide();
    }
  }

  protected refresh() {
    
    let data = this.getData(this.getFilter());
    if (data)
      this.showSpinner();
      data.subscribe(d => {
        this.dataSource = this.getDataSource(d);
        this.dataSource.sort = this._matSort;

        const sortState = this.getDataSort();
        if (sortState){
          this._matSort.active = sortState.active;
          this._matSort.direction = sortState.direction;
          this._matSort.sortChange.emit(sortState);
        }
        //this.dataSource.sort = this.sort()
        this.dataSource.paginator = this.paginator();
        this.hideSpinner();
      });

  }
  protected getDataSort():Sort{
    
    return null;

  }
  newItem(): void {
    this.router.navigate([this.DetailRoute, '/', { action: 'new', route: this.DetailRoute }]);
  }
  editItem(id: string): void {
    this.router.navigate([this.DetailRoute, id, { action: 'change', route: this.DetailRoute }]);
  }
  displayItem(id: string): void {
    this.router.navigate([this.DetailRoute, id, { action: 'display', route: this.DetailRoute }]);
  }


  deleteItem(id: string): void {
    this.toBeDeleted = id;
    this.showDeletionConfirmation = true;
  }

  cancelDeletion(): void {
    this.showDeletionConfirmation = false;
  }
  protected deleteData(id: string) {
    return null;
  }
  confirmDeletion(): void {
    let serviceActionPoint = this.deleteData(this.toBeDeleted);
    if (!serviceActionPoint) {
      
      return;
    }
    serviceActionPoint.subscribe(errorMsg => {
      this.showDeletionConfirmation = false;
      if (errorMsg) {
        const messages = <Message[]>errorMsg;
        messages.forEach(msg => this.messageService.add(msg));
      } else {
        this.refresh();
      }
    });
  }

  
}
