
  <mat-toolbar fxLayout="row" fxLayoutGap="20px" color="primary" fxLayoutAlign="left center" role="toolbar">
    <!--      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>-->
    <div fxFlex="1 1 auto" fxLayoutAlign="left center">
      <img class="main-logo" src="assets/img/logo_no_bckgnd_white.png" alt="">
      
    <h1 class="app-name" [routerLink]="['/']">
      </h1>
      <mat-select fxFlex ="1 1 170px" *ngIf="cities.length>1" placeholder="Город" matInput  [(ngModel)]="city" (selectionChange)="changeCity($event.value)">
        <mat-option *ngFor="let item of cities" [value]="item.id">
          {{item.name}}
        </mat-option>
      </mat-select>
    </div>
<!--    <div fxFlex = "1 1 auto" fxLayoutAlign="left center" *ngIf="newMessagesInfo.msgCount>0" class="newMessage">-->
<!--      <h1>новых сообщений {{newMessagesInfo.msgCount}} от {{newMessagesInfo.usrCount}} пользователей</h1>-->
<!--    </div>-->
    <span class="fill-remaining-space"></span>
    <div *ngIf="auth.isAuthenticated()" class="user-name">
      <h1>{{auth.user_name}}</h1>
    </div>

    <button mat-mini-fab color="primary" (click)="openDialog()">
      <i *ngIf="auth.isAuthenticated()==false" class="material-icons href-cursor">person</i>
      <i *ngIf="auth.isAuthenticated()" class="material-icons href-cursor">exit_to_app</i>
    </button>

  </mat-toolbar>
