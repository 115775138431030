import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatUser } from '../../models/chat-user.model';
import { ChatService } from '../../services/chat.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-chat-user-list',
  templateUrl: './chat-user-list.component.html',
  styleUrls: ['./chat-user-list.component.scss']
})
export class ChatUserListComponent implements OnInit, OnDestroy {
  users: ChatUser[] = [];
  usersFiltered: ChatUser[] = [];
  currentUser: ChatUser;
  timeSub;
  dataSub: Subscription;
  filterValue = '';

  constructor(private chat: ChatService) {
  }

  dataRefresh() {
    this.dataSub = this.chat.getUsers().subscribe(users => {
      this.users = users;
      this.users.forEach(element => {
        element.data = JSON.parse(element.info);
      });
      this.users = this.users.sort((a, b) => b.newMessagesCnt - a.newMessagesCnt || (moment(b.lastMessage, 'DD.MM.YYYY HH:mm:ss').diff(moment(a.lastMessage, 'DD.MM.YYYY HH:mm:ss'))));
      if ((this.users) && (this.users.length > 0) && (!this.chat.getCurrentUser())) {
        this.onItemClick(this.users[0]);
      }
      this.filterUsers(this.filterValue);
    });
  }

  ngOnInit() {
    this.dataRefresh();
    this.timeSub = setInterval(() => {
      this.dataRefresh();
    }, 15 * 1000);
    this.chat.currentUsersListHistoryDeep.subscribe(val => {
      if (val) {
        this.dataRefresh();
      }
    });
  }

  ngOnChanges() {
    // this.feed = this.chat.getMessages()
    this.dataRefresh();
  }

  onItemClick(user: ChatUser) {
    this.currentUser = user;
    this.chat.setSelectedUser(user);
  }


  ngOnDestroy(): void {
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    clearInterval(this.timeSub);
  }

  filterUsers(value: string) {
    this.filterValue = value;
    this.usersFiltered = this.users.filter(user => {
      const name = user.data.name.toUpperCase();
      const email = user.data.email.toUpperCase();
      const phone = user.data.phone_number.toUpperCase();
      const val = value.toUpperCase();
      return name.includes(val) || email.includes(val) || phone.includes(val);
    });
  }
}


