import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IDialogData, IOrders, IOrderLine, IOrder, IBankPaymentInfo, IBuhReestr } from '../../shared/interfaces';
import { ExcelService } from '../../shared/services/excel.service';
import { OrdersService } from '../../services/orders.service';

import { ProductService } from '../../services/product.service';
import * as moment from 'moment-timezone';
import { async } from '@angular/core/testing';
import { formatDate } from '@angular/common';
import { BankingService } from 'src/app/services/banking.service';

@Component({
  selector: 'app-export-buh-dialog',
  templateUrl: './export-buh-dialog.component.html',
  styleUrls: ['./export-buh-dialog.component.scss']
})
export class ExportBuhDialogComponent implements OnInit {
  progress = 0;
  reestrUpdate = false;
  orders: IOrders[];
  progressMode = "indeterminate";
  constructor(
    public dialogRef: MatDialogRef<ExportBuhDialogComponent>,
    private excelService: ExcelService,
    private ordersService: OrdersService,
    private bankingService: BankingService,
    private productsService: ProductService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.orders = this.data.orders;
    this.data.reestrDate = new Date();
  }

  // tslint:disable-next-line:variable-name
  onClick() {
    this.dialogRef.close();
  }
  onRefreshReestrClick() {
    this.reestrUpdate = true;
    this.progress = 1;
    this.bankingService.refreshReestr().toPromise().then(d => {this.reestrUpdate = false;this.progress = 0; console.log("Done"); })
  }
  /*onRefreshClick() {
    this.orders.forEach(order => { order.payment_bank_info ? order.paymentBankData = JSON.parse(order.payment_bank_info) as IBankPaymentInfo : order.paymentBankData = null; })
    //get orders to update payment data
    this.orders.forEach(r => {
      if (r.paymentBankData) {
        if (moment.duration(moment().utc().diff(moment(r.paymentBankData.UpdatedUTC, 'DD.MM.YYYY HH:mm:ss'))).days() != 10) {
          r.paymentBankData = undefined;
        }
      }
    })
    this.progress = 0;
    let progressStep = 100 / this.orders.length;
    const refreshData = async (data: IOrders[]) => {
      const getData = (data: IOrders[]) => new Promise(() => {
        data.filter(o => !o.paymentBankData).forEach(async order => {
          order.paymentBankData = await this.ordersService.getPaymentInfoFromBankAsync(order.id).then((data: IBankPaymentInfo) => {
            this.progress = this.progress + progressStep;
            return data;
          })
          console.log(order.paymentBankData);
        }
        )
      }

      );

      let res = await getData(data);

      return res;
      //await queryData();
    };
    refreshData(this.orders)
  }*/
  onExportClick() {

    var exportData: any[] = [];
    let exportDate = this.data.reestrDate;
    exportDate = exportDate.setDate(exportDate.getDate() + 1)
    this.ordersService.getReestrForBuh(formatDate(exportDate, 'dd.MM.yyyy', 'en-US')).then(d => {
      (d.reestr as IBuhReestr[])
        .forEach(s => {

          exportData.push
            (
              {
                "Заказ": s.order,
                "ТоварКод1С": s.code_1c,
                "Товар": s.name,
                "Количество": Number(s.amount),
                "Цена": Number(s.price),
                "Сумма": Number(s.summ),
                "Дата": moment(s.oper_date).tz("Asia/Novosibirsk").format('DD.MM.YYYY HH:mm:ss'),
                "ДатаПродажи": moment(s.debit_oper_date).tz("Asia/Novosibirsk").format('DD.MM.YYYY HH:mm:ss'),
                //"СтатусВБанке": o.paymentBankData != undefined ? this.ordersService.getBankStatusName(o.paymentBankData.Status) : ""
              }
            )

        });
      exportData = exportData.sort((val1, val2) => { return (moment(val1.Дата, 'DD.MM.YYYY HH:mm:ss').diff(moment(val2.Дата, 'DD.MM.YYYY HH:mm:ss'))) });
      this.excelService.exportAsExcelFile(exportData, 'ЗаказыБух');
      this.dialogRef.close(0);

    });


    /*
        this.orders.filter(r => (r.order_status >= 2 && r.paymentBankData == undefined) ||
          (r.paymentBankData != undefined && r.paymentBankData.Status == 'CONFIRMED') ||
          r.old_order == 1)
          .forEach(o => {
            let sellings = o.sellings as IOrderLine[];
            sellings.forEach(s => {
              exportData.push
                (
                  {
                    "Заказ": o.id,
                    "ТоварКод1С": s.code_1c,
                    "Товар": s.name,
                    "Количество": s.amount,
                    "Цена": s.price,
                    "Сумма": s.summ,
                    "Дата": moment(o.created_at, 'DD.MM.YYYY HH:mm:ss').tz("Asia/Novosibirsk").tz("Europe/Moscow").format('DD.MM.YYYY HH:mm:ss'),
                    "СтатусВБанке": o.paymentBankData != undefined ? this.ordersService.getBankStatusName(o.paymentBankData.Status) : ""
                  }
                )
    
            });
          });
        this.progress = 100;
    */
  }


}
