import { Component, OnInit, Input, Inject } from '@angular/core';
import { OrdersService } from '../services/orders.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import { AuthService } from '../shared/services/auth.service';
import { BoostersService } from '../services/boosters.service';
import { SettingsService } from '../services/settings.service';
import { OrderBaseComponent } from '../base/order-base/order-base.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent extends OrderBaseComponent implements OnInit {
  images: any[] = [];
  rest : number =0;
  internalComment: string="";
  report: any[] =[];

  constructor(
     settings:SettingsService,
     private service: OrdersService,
    public auth: AuthService,
     orders: OrdersService,
     boosters: BoostersService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<OrderDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super(settings,      
      auth,
      orders,
      boosters,
      dialog
    );
  }

  ngOnInit(): void {
    this.service.getOrderDetails(this.data.orderId).subscribe(data => {
      this.images = data.images;
      this.rest = data.report.fuel_rest.toFixed(2);
      this.internalComment = data.report.internal_comment;
      this.report = data.report.driver_report;


      /*data.images.forEach((element: string) => {
        this.images.push(atob(element));
      });
      */
    })
  }

}
