import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogEntityBaseComponent } from 'src/app/base/dialog-entity-base/dialog-entity-base.component';


import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageService } from 'ui-message-angular';


import { IAccount } from 'src/app/shared/interfaces';
import { AccountsService, UserType, UserTypeLabel } from 'src/app/services/accounts.service';
import { atLeastOneCheckboxCheckedValidator } from 'src/app/validators/atLeastOneCheckboxCheckedValidator';
import { IRole, Role } from 'src/app/models/role';


@Component({
  selector: 'app-account-dialog',
  templateUrl: './account-dialog.component.html',
  styleUrls: ['./account-dialog.component.scss']
})
export class AccountDialogComponent extends DialogEntityBaseComponent<IAccount> implements OnInit {

  allRoles: IRole[] = Role.toArray();
  userTypes= UserType;
  constructor(
    protected fb: FormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected settings: SettingsService,
    protected auth: AuthService,
    protected dialogService: DialogService,
    protected messageService: MessageService,
    private service: AccountsService

  ) {
    super(
      fb,
      dialog,
      router,
      route,
      settings,
      auth,
      dialogService,
      messageService
    );
  }
  public getSubmitRoute(): string {
    return '/accounts';
  }
  public getCancelRoute(): string {
    return '/accounts';
  }
  protected getData(id: number) {
    return this.service.getAccount(id)
  }

  protected saveData(Entity: IAccount) {

    return this.service.saveAccount(Entity)
  }
  protected _dataFromResponse(data) {
    return data;
  }
  protected newEntity() {
    var item = <IAccount>super.newEntity();
    item.name = '';
    item.email = '';
    item.login = '';
    item.user_type = UserType.Manager;
    item.is_active = true;
    item.roles = '[]';
    item.password = null;

    return item;
  }
  get f() {
    return this.entityForm && this.entityForm.controls;
  }
  get rolesFormGroup(): FormGroup {
    return this.f && <FormGroup>this.f.roles
  }
  buildRolesFormGroup(roles: IRole[], selectedRolesIds: string[] = []): FormGroup {
    let group = this.fb.group({}, {
     // validators: atLeastOneCheckboxCheckedValidator()
    });
    roles.forEach(role => {
      let isSelected = selectedRolesIds.some(id => id === role.id);
      group.addControl(role.id, this.fb.control(isSelected));
    })
    return group;
  }
  get rolesFormGroupSelectedIds(): string[] {
    let ids: string[] = [];
    for (var key in this.rolesFormGroup.controls) {
      if (this.rolesFormGroup.controls[key].value) {
        ids.push(key);
      }
    }
    return ids;
  }

  protected _generateUserForm(data: IAccount): void {


    let roles = JSON.parse(data.roles);
    this.entityForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required]],
      email: [data.email],
      login: [data.login],
      user_type: [data.user_type],
      password: [data.password],
      is_active: [data.is_active],
    });
    this.entityForm.addControl("roles", this.buildRolesFormGroup(this.allRoles, roles));
    super._generateUserForm(data);


  }
  protected recreateForm(data: IAccount) {
    this.entityForm.removeControl('roles');
    let roles = JSON.parse(data.roles);
    this.entityForm.addControl("roles", this.buildRolesFormGroup(this.allRoles, roles));
  }
  protected _composeChanges() {

    let result = super._composeChanges();
    if (result) {
      this.Entity = this.entityForm.value;
    }
    this.Entity.roles = JSON.stringify(this.rolesFormGroupSelectedIds);
    //this.Entity.user_type = UserType.Manager;
    return result;
  }
  protected _setCheckBoxState() {

    if (this.readonly) {

      this.entityForm.get('name').disable();
      this.entityForm.get('email').disable();
      this.entityForm.get('login').disable();
      this.entityForm.get('is_active').disable();
      this.entityForm.get('roles').disable();
      this.entityForm.get('password').disable();
      this.entityForm.get('user_type').disable();
      
    } else {
      this.entityForm.get('name').enable();
      this.entityForm.get('email').enable();
      this.entityForm.get('login').enable();
      this.entityForm.get('is_active').enable();
      this.entityForm.get('roles').enable();
      this.entityForm.get('password').enable();
      this.entityForm.get('user_type').enable();
    }

  }

}

