<div *ngIf='entityForm' [formGroup]='entityForm' (ngSubmit)='onSave(null)' autocomplete='off'>
  <div class='container-dlg'>
    <mat-card>
      <div class='top-bar'>
        <mat-card-title>
          <div class='top-bar-title'>
            {{isNewMode ? 'Новый контрагент' : readonly ? 'Карточка контрагента ' : 'Редактирование контрагента '}}

            <button class='btn btn-lg btn-outline-primary mr-2' type='button' title='Edit/Display'
                    id='editDisplay' (click)='switchEditDisplay()'>
              <span *ngIf='readonly' class='fas fa-edit'> Редактирование</span>
              <span *ngIf='!readonly' class='fas fa-glasses'> Просмотр</span>
            </button>
          </div>
        </mat-card-title>
      </div>
      <div class='form-content' fxLayout='column'>
        <div fxLayout='row' fxLayoutWrap fxLayoutGap='0.5%' fxFlex='75%'>
          <mat-form-field fxFlex='80%'>
            <mat-label>Наименование</mat-label>
            <input matInput id='name' formControlName='name' placeholder='Наименование'>
          </mat-form-field>
          <mat-form-field fxFlex>
            <mat-label>Пол</mat-label>
            <input matInput id='gender' formControlName='gender' placeholder='Пол'>
          </mat-form-field>
        </div>
        <div class='additional-panel' fxLayout='column' fxLayoutGap='10px'>
          <div fxFlex='50%'>
            <mat-form-field fxFlex='50%'>
              <mat-label>Телефон</mat-label>
              <input matInput id='phone_number' formControlName='phone_number' placeholder='Телефон'>
            </mat-form-field>
            <mat-form-field fxFlex='50%'>
              <mat-label>Email</mat-label>
              <input matInput id='email' formControlName='email' placeholder='Email'>
            </mat-form-field>
          </div>
          <div fxFlex='50%'>
            <mat-form-field fxFlex='50%'>
              <mat-label>Баланс</mat-label>
              <input matInput id='balance' formControlName='balance' placeholder='Баланс'>
            </mat-form-field>
            <mat-form-field fxFlex='50%'>
              <mat-label>Ссылка</mat-label>
              <input matInput id='link' formControlName='link' placeholder='Ссылка'>
            </mat-form-field>
          </div>
          <div fxFlex="50%">
            <input type="checkbox" id="allow_cash" value="allow_cash" formControlName="allow_cash" />
            <label for="allow_cash" class="form-check-label">Оплата наличными</label>
          </div>
        </div>
      </div>
      <div class='row-buttons'>
        <span *ngIf='!isNewMode' class='id-field'>Код: {{id}}</span>
        <span class='spacer'></span>
        <a [routerLink]='getSubmitRoute()' class='form-btn' mat-button>Назад</a>
        <button type='submit' class='form-btn' mat-raised-button color='accent' (click)='onSave(null)'
                [disabled]='readonly'>Сохранить
        </button>
      </div>
    </mat-card>
  </div>
</div>
