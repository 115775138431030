<div *ngIf="entityForm" [formGroup]="entityForm" (ngSubmit)="onSave(null)" autocomplete="off">

    <div class="container-dlg">
        <mat-card>
            <div class="top-bar">
                <mat-card-title>
                    <div class="top-bar-title">
                        {{isNewMode? 'Новый бустер' : readonly? 'Карточка бустера ' : 'Редактирование бустера '}}

                        <button class="btn btn-lg btn-outline-primary mr-2" type="button" title="Edit/Display"
                            id="editDisplay" (click)="switchEditDisplay()">
                            <span *ngIf="readonly" class="fas fa-edit"> Редактирование</span>
                            <span *ngIf="!readonly" class="fas fa-glasses"> Просмотр</span>
                        </button>
                    </div>
                </mat-card-title>
            </div>
            <div class="form-content" fxLayout="row">
                <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center"
                    fxFlex="75%">

                    <mat-form-field class="full-width">
                        <mat-label>Рег. Номер</mat-label>
                        <input matInput id="name" formControlName="name" placeholder="Рег. Номер">
                    </mat-form-field>


                </div>
                <div class="additional-panel" fxLayout="column" fxLayoutGap="10px">
                    <div>
                        <input type="checkbox" id="is_active" value="is_active" formControlName="is_active" />
                        <label for="is_active" class="form-check-label">Разрешен</label>
                    </div>
                </div>
            </div>


            <div class="row-buttons">


                <span *ngIf="!isNewMode" class="id-field">Код: {{id}}</span>


                <span class="spacer"></span>
                <a [routerLink]="getSubmitRoute()" class="form-btn" mat-button>Назад</a>
                <button type="submit" class="form-btn" mat-raised-button color="accent" (click)="onSave(null)"
                    [disabled]="readonly">Сохранить</button>
            </div>

        </mat-card>
    </div>
</div>