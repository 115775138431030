<div class="container">
    <div class="table-container mat-elevation-z2">
      <div class="table-header">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
      </div>
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
        </ng-container>
    
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.name | maxChar:15 }} </mat-cell>
        </ng-container>
    

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <a mat-icon-button title="Просмотр" (click)="displayItem(element.id)">
              <mat-icon color="primary">preview</mat-icon>
            </a>
  
            <a mat-icon-button title="Редактировать" (click)="editItem(element.id)">
              <mat-icon color="primary">create</mat-icon>
            </a>
            <a mat-icon-button title="Удалить" (click)="deleteItem(element.id)">
              <mat-icon color="primary">delete</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
    
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
</div>